// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { IConsultarStatusDareState, Types } from './types';

export interface IRequestStatusDare {
	numeroRg: string;
}

export function consultarStatusDareRequest(
	token: string,
	payload: IRequestStatusDare,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_STATUS_DARE_REQUEST,
		token,
		payload,
	};
}

export function consultarStatusDareSuccess(
	status: number,
	data: IConsultarStatusDareState[] | null,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_STATUS_DARE_SUCCESS,
		payload: {
			status,
			data,
		},
	};
}

export function consultarStatusDareFailure(
	status: number,
	data: null,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_STATUS_DARE_FAILURE,
		payload: {
			status,
			data,
		},
	};
}

export function consultarStatusDareClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_STATUS_DARE_CLEAR,
		payload: null,
	};
}
