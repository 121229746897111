import React, {
	Suspense,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';

import { Skeleton } from 'antd';
// import { useHistory } from 'react-router-dom';

// CONTEXT
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { clearNotificationsValidate } from 'store/modules/app/notifications/actions';
import { changeUrlClear } from 'store/modules/api/eCpf/changeUrl/actions';
import { logOffRequest } from 'store/modules/api/sgu/loginUnico/actions';
import {
	registraAcoesUsoClear,
	registraAcoesUsoRequest,
} from 'store/modules/user/acoesUso/actions';

// ROUTING
import { Switch, useLocation, useHistory } from 'react-router-dom';

// UTILS
import { redirect } from 'pages/MenuOrgaos/form';
import {
	menuPaths,
	verifyRecoverServiceWasClicked,
} from 'utils/functions/actionsUserValidations/verifyPaths';

// COMPONENTS
import { ROUTE_IIRGD_REIMPRESSAO_PROTOCOLO } from 'pages/iirgd/routes/paths';
import Route from './Route';

// ROUTES
import SysRoutes from './routes';
import ministerioPublicoRoutes from '../pages/ministerioPublico/routes';
import PrefeiturasRoutes from '../pages/Prefeitura/routes';
import DetranCnhRoutes from '../pages/DetranCnh/routes';
// import DetranCrvRoutes from '../pages/DetranCrv/routes';
import EducacaoRoutes from '../pages/EducacaoUpdate/routes';
import IirgdRoutes from '../pages/iirgd/routes';
import SedsRoutes from '../pages/seds/routes';
import CdhuRoutes from '../pages/cdhu/routes';
import PoupatempoRoutes from '../pages/Poupatempo/routes';
import BolsaPovoRoutes from '../pages/BolsaPovo/routes';
import ProcouRoutes from '../pages/procon/routes';
import {
	ROUTE_BOLSA_POVO,
	ROUTE_DETRAN_CNH,
	ROUTE_DETRAN_CRV,
	ROUTE_IIRGD,
	ROUTE_MENU_ORGAOS,
	ROUTE_POUPATEMPO,
	ROUTE_PREFEITURA,
	ROUTE_PROCON,
	ROUTE_SECRETARIA_EDUCACAO,
	ROUTE_SEDS,
	ROUTE_SGU,
} from './paths';

const Routes: React.FC = () => {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const { token, logOut, tokenData } = useContext<IAuthContext>(AuthContext);

	const [routes, setRoutes] = useState<any[]>([]);

	const [previousUrl, setPreviosUrl] = useState<string>('');
	const [currentUrl, setCurrentUrl] = useState<string>('');

	const { changeUrl } = useSelector(
		(state: ApplicationState) => state.api.eCpf,
	);

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { acoesUso } = useSelector((state: ApplicationState) => state.user);

	const verifyIsSameUrlAndLoggedMainframe = useCallback(() => {
		// VERIFICA SE A URL UTILIZADA É A MESMA DO ORGAO LOGADO,
		// CASO NÃO SEJA APLICA NOVAMENTE A URL DO ORGAO.
		// CASO ENTRE VIA URL EM ALGUM ORGAO SEM ESTAR LOGADO
		// VOLTA PARA O MENU ORGAOS.

		if (
			location.pathname?.toLowerCase()?.includes(ROUTE_DETRAN_CNH) ||
			location.pathname?.toLowerCase()?.includes(ROUTE_IIRGD) ||
			location.pathname?.toLowerCase()?.includes(ROUTE_DETRAN_CRV) ||
			location.pathname?.toLowerCase()?.includes(ROUTE_SECRETARIA_EDUCACAO) ||
			location.pathname?.toLowerCase()?.includes(ROUTE_SEDS) ||
			location.pathname?.toLowerCase()?.includes(ROUTE_POUPATEMPO) ||
			location.pathname?.toLowerCase()?.includes(ROUTE_PREFEITURA) ||
			location.pathname?.toLowerCase()?.includes(ROUTE_SGU) ||
			location.pathname?.toLowerCase()?.includes(ROUTE_BOLSA_POVO) ||
			location.pathname?.toLowerCase()?.includes(ROUTE_PROCON)
		) {
			const urlLoggedMainframe = redirect(login.user.idOrgao);

			if (
				urlLoggedMainframe !== '' &&
				!location.pathname?.toLowerCase()?.includes(urlLoggedMainframe)
			) {
				history.push(urlLoggedMainframe);
			} else if (urlLoggedMainframe === '') {
				history.push(ROUTE_MENU_ORGAOS);
			}
		}
	}, [history, location.pathname, login.user.idOrgao]);

	useEffect(() => {
		setRoutes(
			SysRoutes.concat(
				IirgdRoutes,
				PoupatempoRoutes,
				ministerioPublicoRoutes,
				PrefeiturasRoutes,
				SedsRoutes,
				// DetranCrvRoutes,
				DetranCnhRoutes,
				CdhuRoutes,
				EducacaoRoutes,
				BolsaPovoRoutes,
				ProcouRoutes,
			),
		);
	}, []);

	useEffect(() => {
		if (!changeUrl.data && window.location.pathname.includes('/detran-cnh')) {
			history.push('/');
		}
	}, [changeUrl.data, history]);

	useEffect(() => {
		setPreviosUrl(currentUrl);
		setCurrentUrl(location.pathname);
	}, [location, currentUrl]);

	useEffect(() => {
		if (currentUrl !== previousUrl) clearNotificationsValidate();
		verifyIsSameUrlAndLoggedMainframe();
	}, [previousUrl, currentUrl, verifyIsSameUrlAndLoggedMainframe]);

	useEffect(() => {
		if (currentUrl !== previousUrl) {
			// Verifica se passou do menu orgão para outra tela sem acessar o menu do serviço.
			if (previousUrl === '' && !menuPaths.includes(currentUrl)) {
				history.push(previousUrl);
			}

			if (acoesUso.data) {
				const firstPathSelected = acoesUso.data.clickedOptionPathList[0];
				const dataPathRecoverService =
					acoesUso.data.clickedOptionHasRecoveryService;

				const isMenuService = firstPathSelected === currentUrl;
				const isMenuOrgao = currentUrl === ROUTE_MENU_ORGAOS;
				const isRecuperarAtendimento = currentUrl.includes(
					'/recuperar-atendimento',
				);

				// Limpar o store do user caso volte para o menu do serviço selecionado inicialmente.
				if (
					(isMenuService || isMenuOrgao) &&
					previousUrl !== ROUTE_MENU_ORGAOS
				) {
					dispatch(registraAcoesUsoClear());

					if (!isMenuOrgao) {
						dispatch(
							registraAcoesUsoRequest({
								clickedOptionPath: currentUrl,
							}),
						);
					}
				}

				// Verifica se seguiu o fluxo correto das telas passando pelo recuperar atendimento.
				if (
					!isRecuperarAtendimento &&
					!isMenuOrgao &&
					firstPathSelected &&
					!verifyRecoverServiceWasClicked(
						acoesUso.data.clickedOptionPathList,
						dataPathRecoverService,
						currentUrl,
					) &&
					currentUrl !== ROUTE_IIRGD_REIMPRESSAO_PROTOCOLO
				) {
					history.push(firstPathSelected);
				}
			}
		}
	}, [previousUrl, currentUrl, acoesUso.data, dispatch, history]);

	const handleLogout = useCallback(async () => {
		dispatch(changeUrlClear());
		dispatch(logOffRequest());
		history.push('/');
		if (tokenData) {
			logOut();
		}
	}, [dispatch, history, logOut, tokenData]);

	useEffect(() => {
		if (!token && location.pathname !== '/') {
			// eslint-disable-next-line no-alert
			alert('Token inválido, por favor refaça o login.');
			handleLogout();
		}
	}, [handleLogout, location.pathname, token]);

	return (
		<>
			{routes && (
				<Suspense fallback={<Skeleton paragraph={{ rows: 1 }} />}>
					<Switch>
						{routes.map(
							(
								{ path, breadcrumb, Component, isAuthenticated, exact },
								idx,
							) => {
								return (
									<Route
										key={String(idx)}
										path={path}
										breadcrumb={breadcrumb}
										component={Component}
										isAuthenticated={isAuthenticated}
										exact={exact}
									/>
								);
							},
						)}
					</Switch>
				</Suspense>
			)}
		</>
	);
};

export default Routes;
