import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IConsultarStatusDareState } from './types';

export const INITIAL_STATE: IConsultarStatusDareState = {
	status: 0,
	data: null,
	tipo: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): IConsultarStatusDareState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CONSULTAR_STATUS_DARE_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.CONSULTAR_STATUS_DARE_FAILURE: {
				draft.status = action.payload.status === 204 ? 204 : 400;
				draft.data = INITIAL_STATE.data;
				break;
			}

			case Types.CONSULTAR_STATUS_DARE_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
