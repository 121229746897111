export enum Types {
	CONSULTAR_STATUS_DARE_REQUEST = '@dare/CONSULTAR_STATUS_DARE_REQUEST',
	CONSULTAR_STATUS_DARE_SUCCESS = '@dare/CONSULTAR_STATUS_DARE_SUCCESS',
	CONSULTAR_STATUS_DARE_FAILURE = '@dare/CONSULTAR_STATUS_DARE_FAILURE',
	CONSULTAR_STATUS_DARE_CLEAR = '@dare/CONSULTAR_STATUS_DARE_CLEAR',
}

export interface IConsultarStatusDareState {
	status: number;
	data: IConsultaStatusDare | null;
	tipo: 'cpf' | 'dare' | 'rg' | null;
}

export interface IRequestWithHeader {
	body: IConsultaStatusDare;
	headers: any;
}

export interface IDareRG {
	id: string;
	razaoSocialGuiaMae: string;
	orgao: number;
	dataGeracao: string;
	dataVencimento: string;
	loginUsuarioEmissor: number;
	codigoGuiaMae: number;
	identificacaoEmissor: number;
	identificacaoContribuinte: number;
	cpf: string;
	nomeCidadao: string;
	idCidadao: string;
	enderecoContribuinte: string;
	emailTo: string;
	telefoneDdd: string;
	telefoneNum: string;
	numeroControleGuiaMae: number;
	numeroSequencialGuiaMae: number;
	codigoBarraGuiaMae: string;
	codigoBarraGuiaMaeCalc: string;
	valorTotalReceita: number;
	valorTotalJurosMora: number;
	valorTotalMulta: number;
	valorTotalAcrescimoFinanceiro: number;
	valorTotalHonorariosAdvocaticios: number;
	valorTotal: number;
	idAtendimento: string;
	dataAgendamento: string;
	numProtocolo: string;
	agendamentoLocal: string;
	agendamentoEndereco: string;
	emissaoAutomatica: string;
	origem: number;
	status: number;
	statusDescricao: string;
	dataInclusao: string;
	dataAlteracao: string;
	ipUsuario: string;
	taxasRes: [
		{
			id: string;
			razaoSocial: string;
			dataInclusao: string;
			codigoEnvio: number;
			sequenciaOrdenacao: number;
			dataNascimento: string;
			identificador: string;
			identificadorTipo: number;
			identificacaoEmissor: number;
			codigoReceita: number;
			referencia: string;
			identificadorRegistro: number;
			codigoGuiaFilhote: number;
			numeroControleGuiaFilhote: string;
			codigoBarraGuiaFilhote: string;
			valorReceita: number;
			valorJurosMora: number;
			valorMulta: number;
			valorAcrescimoFinanceiro: number;
			valorHonorariosAdvocaticios: number;
			valorTotal: number;
			postoCodigo: number;
			servicoTipo: number;
			servicoDescricao: string;
			telefoneDdd: string;
			telefoneNum: string;
			status: number;
			statusDescricao: string;
			principal: number;
			situacaoGuia: string;
			postoDescricao: string;
			dataQueima: string;
			dataAlteracao: string;
			indicadorImpressao: string;
			envioPelosCorreios: boolean;
			statusCode: number;
			dataVinculoRg: string;
			statusCili: string;
			statusCiliDescricao: string;
		},
	];
	pagamento: {
		id: string;
		codigoBanco: number;
		codigoAgencia: string;
		codigoBarras: string;
		codigoIdentificacaoEmissor: number;
		codigoTipoServico: number;
		descricaoServico: string;
		dataArrecadacao: string;
		dataRecebimento: string;
		dataVencimento: string;
		formaArrecadacao: number;
		formaPagamento: number;
		identificadorRegistro: number;
		numeroCpf: string;
		numeroAutenticacao: string;
		numeroControle: number;
		numeroControleGuia: string;
		tipoDocumento: string;
		numeroReferencia: number;
		sequencialArquivo: number;
		sequencialRegistro: number;
		tipoArquivoOrigem: number;
		tipoReceita: number;
		tipoTransacao: number;
		valorAcrescimoFinanceiro: number;
		valorHonorariosAdvocaticios: number;
		valorJurosMora: number;
		valorRecebido: number;
		valorReceita: number;
		valorTotal: number;
		ativo: string;
		direcaoOrdenacao: number;
		excluido: string;
		numeroPagina: number;
		tamanhoPagina: number;
		origem: string;
		codigoIdentificadorEmissor: number;
		servicoCodigo: number;
		servicoDescricao: string;
		cpf: number;
		origemDescricao: string;
		cili: string;
		reprocessamentoFila: string;
		statusCode: number;
		reservado1: string;
	};
	imprimirDare: boolean;
	boletoRes: {
		id: string;
		dataInclusao: string;
		dataAlteracao: string;
		boleto: string;
		boletoBase64: string[];
		statusCode: number;
		mensagem: string;
	};
	cili: boolean;
	dataVinculoRg: string;
	statusCode: number;
}

export interface IDareCili {
	id: string;
	dataCriacao: string;
	cpf: string;
	tipoDocumento: string;
	documento: string;
	tipo: string;
	texto: string;
	usuario: string;
}

export interface IConsultaStatusDare {
	dareRg?: IDareRG[];
	retornoCili?: IDareCili[];
}
