import { ReducerActionRotaSP } from 'store/modules/types';
import { ConsultarUsuarioRequest, Types, ConsultarUsuario } from './types';

export function consultarUsuarioRequest(
	token: string,
	payload: ConsultarUsuarioRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_USUARIO_REQUEST,
		token,
		payload,
	};
}
export function consultarUsuarioSuccess(
	payload: ConsultarUsuario,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_USUARIO_SUCCESS,
		payload,
	};
}
export function consultarUsuarioFailure(payload: []): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_USUARIO_FAILURE,
		payload,
	};
}
export function consultarUsuarioClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_USUARIO_CLEAR,
		payload: null,
	};
}
