import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_IIRGD_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, IRequestWithHeader } from './types';

// ACTIONS
import {
	consultarStatusDareSuccess,
	consultarStatusDareFailure,
	consultarStatusDareClear,
} from './actions';

function* consultarStatusDareRequest(request: ReducerActionRotaSP) {
	const { token, payload }: { token?: string; payload: IRequestWithHeader } =
		request;

	const body: any = { ...payload };

	const statusAllowed = [200, 204];

	yield put(consultarStatusDareClear());

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_IIRGD_API,
		`dare/status`,
		body,
		undefined,
		undefined,
		undefined,
		undefined,
		statusAllowed,
	);

	if (response.status === 200 || response.status === 204) {
		yield put(consultarStatusDareSuccess(response.status, response.data));
	} else {
		yield put(consultarStatusDareFailure(204, null));
	}
}

export default all([
	takeLatest(Types.CONSULTAR_STATUS_DARE_REQUEST, consultarStatusDareRequest),
]);
