import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, IConsultarEstatisticaRequest } from './types';
import {
	consultarEstatisticaClear,
	consultarEstatisticaFailure,
	consultarEstatisticaSuccess,
} from './actions';

function* consultarEstatistica(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: IConsultarEstatisticaRequest } = request;

	yield put(consultarEstatisticaClear());

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_SGU_API,
		`estatisticas`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultarEstatisticaSuccess(response.status, response.data));
	} else {
		yield put(consultarEstatisticaFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTAR_ESTATISTICA_REQUEST, consultarEstatistica),
]);
