import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES

import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_IIRGD_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, IRequestWithHeader } from './types';

// ACTIONS
import {
	consultarStatusDareMainframeSuccess,
	consultarStatusDareMainframeFailure,
	consultarStatusDareMainframeClear,
} from './actions';

function* consultarStatusDareMainframeRequest(request: ReducerActionRotaSP) {
	const { token, payload }: { token?: string; payload: IRequestWithHeader } =
		request;

	const body: any = { ...payload };

	delete body.usuario;

	const headers = { usuario: (payload as any)?.usuario };

	yield put(consultarStatusDareMainframeClear());
	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_IIRGD_API,
		`dare/status-mainframe`,
		body,
		undefined,
		undefined,
		undefined,
		headers,
	);

	if (response.status === 200) {
		yield put(
			consultarStatusDareMainframeSuccess(response.status, response.data),
		);
	} else {
		yield put(consultarStatusDareMainframeFailure(204, null));
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_STATUS_DARE_MAINFRAME_REQUEST,
		consultarStatusDareMainframeRequest,
	),
]);
