import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, AtualizarTiposRequest } from './types';

export function atualizarTiposRequest(
	token: string,
	data: AtualizarTiposRequest,
): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_TIPOS,
		token,
		payload: data,
	};
}
export function atualizarTiposSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_TIPOS_SUCCESS,
		payload,
	};
}
export function atualizarTiposFailure(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_TIPOS_FAILURE,
		payload: null,
	};
}
export function atualizarTiposClear(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_TIPOS_CLEAR,
		payload: null,
	};
}
