import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, CadastrarUsuarioRequest } from './types';

export function cadastrarUsuarioRequest(
	token: string,
	data: CadastrarUsuarioRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_USUARIO_REQUEST,
		token,
		payload: data,
	};
}
export function cadastrarUsuarioSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_USUARIO_SUCCESS,
		payload,
	};
}
export function cadastrarUsuarioFailure(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_USUARIO_FAILURE,
		payload: null,
	};
}
export function cadastrarUsuarioClear(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_USUARIO_CLEAR,
		payload: null,
	};
}
