/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { Col, Row } from 'antd';
import { FaTrashAlt, FaPencilAlt } from 'react-icons/fa';
import { useDispatch } from 'react-redux';

import { clearNotifications } from 'store/modules/app/notifications/actions';

import getValidationsErrors from 'utils/getValidationsErrors';

// COMPONENTS
import SimpleTable from 'components/Common/Table';
import Section from 'components/Common/Section';
import Button from 'components/Common/Button';
import FormBox from 'components/Common/Form/FormBox';
import ModalTelefone from './components/pages/ModalTelefone';

// FORM
import { IFichaAluno, schemaTelefone } from '../form';

// STYLE
import { ContainerFichaCadastral, ButtonBox } from '../styled';

interface ITelefone {
	id?: string;
	ddd: string;
	numero: string;
	tipo: number;
	descricaoTipo: string | number;
	complemento: string;
	sms: string;
}

interface Props {
	formik: FormikProps<IFichaAluno>;
	initialValues: IFichaAluno;
}

const Telefones: React.FC<Props> = ({ formik, initialValues }) => {
	const dispatch = useDispatch();

	const [telefones, setTelefones] = useState<ITelefone[]>([
		...formik.values.telefones,
	]);
	const [telefoneSelecionado, setTelefoneSelecionado] = useState<any>({});
	const [telefoneIndex, setTelefoneIndex] = useState<null | number>(null);
	const [modalTelefone, setModalTelefone] = useState(false);

	useEffect(() => {
		if (telefones.length >= 0) {
			formik.setFieldValue('telefones', telefones);
		}
	}, [telefones, formik.values.telefones]);

	useEffect(() => {
		if (!modalTelefone) {
			setTelefoneIndex(null);
		}
	}, [modalTelefone]);

	const handlenNewChangeTelefone = (
		telefoneRecord: ITelefone,
		index: number,
	) => {
		const telefone = {
			id: telefoneRecord.id,
			ddd: telefoneRecord.ddd,
			numero: telefoneRecord.numero,
			tipo: telefoneRecord.tipo,
			descricaoTipo: telefoneRecord.descricaoTipo,
			complemento: telefoneRecord.complemento,
			sms: telefoneRecord.sms,
		};
		setTelefoneSelecionado(telefone);
		setTelefoneIndex(index);
		setModalTelefone(true);
	};

	const handleDelete = useCallback(
		async index => {
			const newList = formik.values.telefones;
			newList.splice(index, 1);
			setTelefones([...newList]);
		},
		[formik],
	);

	const handleSubmit = useCallback(
		(values: IFichaAluno, { resetForm }) => {
			if (telefoneIndex === null) {
				setTelefones(oldTelefones => [...oldTelefones, values.telefone]);
			} else {
				setTelefones(oldTelefones => {
					const extractTelefones = [...oldTelefones];
					extractTelefones[telefoneIndex] = values.telefone;

					return [...extractTelefones];
				});
			}

			resetForm({ values: { ...initialValues } });
			setModalTelefone(false);
		},
		[telefoneIndex],
	);

	const headers = [
		{
			title: 'Tipo Telefone',
			key: 'Tipo Telefone',
			dataIndex: 'descricaoTipo',
			width: '20%',
		},
		{
			title: 'DDD',
			key: 'DDD',
			dataIndex: 'ddd',
			width: '10%',
		},
		{
			title: 'Número',
			key: 'Número',
			dataIndex: 'numero',
			width: '15%',
		},
		{
			title: 'Complemento',
			key: 'Complemento',
			dataIndex: 'complemento',
			width: '35%',
		},
		{
			title: 'Deseja Receber SMS',
			key: 'Deseja Receber SMS',
			dataIndex: 'sms',
			width: '10%',
		},
		{
			title: 'Alterar',
			key: 'Alterar',
			dataIndex: 'type',
			width: '5%',
			render: (text: any, record: any, index: number) => {
				return (
					<ButtonBox>
						<FaPencilAlt
							size={15}
							onClick={() => {
								handlenNewChangeTelefone(record, index);
							}}
						/>
					</ButtonBox>
				);
			},
		},
		{
			title: 'Excluir',
			key: 'Excluir',
			dataIndex: 'type',
			width: '5%',
			render: (text: any, record: any, index: any) => {
				return (
					<>
						<FaTrashAlt size={15} onClick={() => handleDelete(index)} />
					</>
				);
			},
		},
	];

	const handleAddButton = () => {
		setModalTelefone(true);
		dispatch(clearNotifications());
	};

	return (
		<ContainerFichaCadastral>
			<FormBox title="Telefones">
				<Button className="addButton" onClick={() => handleAddButton()}>
					+ Adicionar
				</Button>
				<Section title="Telefones" size="sm">
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<SimpleTable headers={headers} body={formik.values.telefones} />
						</Col>
					</Row>
					{/* <Row gutter={[0, 10]}>
						<Col span={24}>
							<ul>
								{formik.values.telefones.map(item => {
									return (
										<li key={uuidV4()}>
											<span>{item.ddd}</span>
											<span>{item.numero}</span>
											<span>{item.descricaoTipo}</span>
										</li>
									);
								})}
							</ul>
						</Col>
					</Row> */}
				</Section>
			</FormBox>

			<Formik
				enableReinitialize
				validateOnChange={false}
				validateOnBlur={false}
				initialValues={initialValues}
				validate={values => getValidationsErrors(values, schemaTelefone)}
				onSubmit={handleSubmit}
			>
				{formikTelefone => {
					return (
						<>
							<ModalTelefone
								formik={formikTelefone}
								modalTelefone={modalTelefone}
								setModalTelefone={setModalTelefone}
								setTelefones={setTelefones}
								telefoneSelecionado={telefoneSelecionado}
								setTelefoneSelecionado={setTelefoneSelecionado}
							/>
						</>
					);
				}}
			</Formik>
		</ContainerFichaCadastral>
	);
};

export default Telefones;
