export enum Types {
	LISTAR_RESPONSAVEIS_REQUEST = '@EDUCACAO/LISTAR_RESPONSAVEIS_REQUEST',
	LISTAR_RESPONSAVEIS_SUCCESS = '@EDUCACAO/LISTAR_RESPONSAVEIS_SUCCESS',
	LISTAR_RESPONSAVEIS_FAILURE = '@EDUCACAO/LISTAR_RESPONSAVEIS_FAILURE',
	LISTAR_RESPONSAVEIS_CLEAN = '@EDUCACAO/LISTAR_RESPONSAVEIS_CLEAN',
}

export interface ListarResponsaveis {
	status: number;
	data: ConsultaFichaResponsavel | null;
	consultaData: ConsultarResponsaveisRequest | null;
}

export interface ConsultaFichaResponsavel {
	idProcesso: number;
	mensagem: string;
	mensagemErro: string;
	responsaveis: Responsavel[];
	messages?: string[];
	trace?: string;
	status?: number;
}

export interface Responsavel {
	cpf: string;
	nome: string;
	tipoOrigem: string;
	rnm: string;
	nrRG: string;
	digitoRG: string;
	ufRG: string;
	codResponsabilidade: string;
	responsabilidade: string;
	login: string;
	codSexo: string;
	codEstadoCivil: string;
	emailResponsavel: string;
	dataNascimento: string;
	cidadeNascimento: string;
	ufNascimento: string;
	nomePaisNascimento: string;
	codPaisNascimento: string;
	enderecoResidencial: EnderecoResponsavel;
	telefones: TelefoneResponsavel[];
}

export interface EnderecoResponsavel {
	areaLogradouro: string;
	logradouro: string;
	numero: string;
	bairro: string;
	nomeCidade: string;
	ufCidade: string;
	latitude: string;
	longitude: string;
	cep: string;
	complemento: string;
}
export interface TelefoneResponsavel {
	dddNumero: string;
	numero: string;
	tipoTelefone: string;
	descTipoTelefone: string;
	complemento: string;
}

export interface ConsultarResponsaveisRequest {
	numeroRg?: string;
	siglaUfRg?: string;
	numeroCpf?: string;
	numeroRA: string;
	digitoRA: string;
	siglaUFRa: string;
}
