import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, CadastrarTiposRequest } from './types';

export function cadastrarTiposRequest(
	token: string,
	data: CadastrarTiposRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_TIPOS,
		token,
		payload: data,
	};
}
export function cadastrarTiposSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_TIPOS_SUCCESS,
		payload,
	};
}
export function cadastrarTiposFailure(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_TIPOS_FAILURE,
		payload: null,
	};
}
export function cadastrarTiposClear(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_TIPOS_CLEAR,
		payload: null,
	};
}
