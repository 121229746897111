import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, AtualizarUsuarioRequest } from './types';

export function atualizarUsuarioRequest(
	token: string,
	data: AtualizarUsuarioRequest,
): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_USUARIO_REQUEST,
		token,
		payload: data,
	};
}
export function atualizarUsuarioSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_USUARIO_SUCCESS,
		payload,
	};
}
export function atualizarUsuarioFailure(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_USUARIO_FAILURE,
		payload: null,
	};
}
export function atualizarUsuarioClear(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_USUARIO_CLEAR,
		payload: null,
	};
}
