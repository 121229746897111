export enum Types {
	CADASTRAR_USUARIO_REQUEST = '@SGU/CADASTRAR_USUARIO_REQUEST',
	CADASTRAR_USUARIO_SUCCESS = '@SGU/CADASTRAR_USUARIO_SUCCESS',
	CADASTRAR_USUARIO_FAILURE = '@SGU/CADASTRAR_USUARIO_FAILURE',
	CADASTRAR_USUARIO_CLEAR = '@SGU/CADASTRAR_USUARIO_CLEAR',
}

export interface CadastrarUsuario {
	status: number;
	data: null | CadastrarUsuarioResponse;
}

export interface CadastrarUsuarioResponse {
	id: number;
	nome: string;
	email: string;
	endereco: string;
	cpf: string;
	rg: string;
	digitoRg: string;
	ufRg: string;
	isPossuiUsuarioMainframe: number;
	dddCelular: number | null;
	telCelular: string;
	dddComercial: number | null;
	telComercial: string;
	dddResidencial: number | null;
	telResidencial: string;
	localidadesUsuario: {
		dataCadastro: string;
		idLocalidadeUsuario: number;
		idUsuarioCadastro: number;
		localidade: {
			id: number;
			codigo: number;
			nome: string;
			tipo: string;
			orgao: {
				id: 0;
				descricao: string;
				flHabilitado: boolean;
			};
			usuariosMainframe: {
				idUsuarioMainframe: string;
				dataCadastro: string;
			};
		};
	}[];
	perfil: {
		acessaSgu: boolean;
		id: number;
		status: {
			id: number;
			descricao: string;
		};
		descricao: string;
	};
	status: {
		id: number;
		descricao: string;
	};
}

interface UsuarioData {
	status: string;
	statusMensagem: string;
}

export interface CadastrarUsuarioRequest {
	nome: string;
	email: string;
	endereco: string;
	cpf: string;
	rg: string;
	digitoRg: string;
	ufRg: string | null;
	dddCelular: number | null;
	telCelular: string;
	dddComercial: number | null;
	telComercial: string;
	dddResidencial: number | null;
	telResidencial: string;
	perfil: number;
	status: number;
	localidades: number[] | null;
	id?: number | string;
}
