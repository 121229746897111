import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, CadastrarTiposRequest } from './types';

// ACTIONS
import { cadastrarTiposSuccess, cadastrarTiposFailure } from './actions';

function* cadastrarTipos(request: ReducerActionRotaSP) {
	const { token, payload }: { token?: string; payload: CadastrarTiposRequest } =
		request;

	const body = { ...payload };

	delete body.codigo;

	const response: ApiResponse = yield call(
		postApi,
		token || '',
		PATH_SGU_API,
		`tipos-localidade`,
		body,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarTiposSuccess(response));
	} else {
		yield put(cadastrarTiposFailure());
	}
}

export default all([takeLatest(Types.CADASTRAR_TIPOS, cadastrarTipos)]);
