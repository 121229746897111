export enum Types {
	CADASTRAR_MUNICIPIO_IBGE_REQUEST = '@SGU/CADASTRAR_MUNICIPIO_IBGE_REQUEST',
	CADASTRAR_MUNICIPIO_IBGE_SUCCESS = '@SGU/CADASTRAR_MUNICIPIO_IBGE_SUCCESS',
	CADASTRAR_MUNICIPIO_IBGE_FAILURE = '@SGU/CADASTRAR_MUNICIPIO_IBGE_FAILURE',
	CADASTRAR_MUNICIPIO_IBGE_CLEAR = '@SGU/CADASTRAR_MUNICIPIO_IBGE_CLEAR',
}

export interface CadastrarMunicipioIbge {
	status: number;
	data: null | MunicipioIbgeData;
}

interface MunicipioIbgeData {
	idNaturalidade: number | string;
	idIbge: number;
	uf: string | number;
	descricao: string;
}

export interface CadastrarMunicipioIbgeRequest {
	idNaturalidade: number | string;
	idIbge: number;
	uf: string | number;
	descricao: string;
}
