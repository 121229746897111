import React, { ChangeEvent, useEffect } from 'react';

import { FormikProps, Field } from 'formik';
import { Row, Col } from 'antd';

// REDUX
import { useDispatch } from 'react-redux';
import data from 'store/modules/api/iirgd/enum';

// COMPONENTS
import Section from 'components/Common/Section';
import FormBox from 'components/Common/Form/FormBox';
// import Radio from 'components/Common/Form/Radio';
import InputMask from 'components/Common/Form/Input/InputMask/index';
import Select from 'components/Common/Form/Select';

// UTILS
import hasError from 'utils/getFormErrors';

// FORM
import { calcularIdade } from 'utils/genericFunctions';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import CheckBox from 'components/Common/Form/Checkbox';
import ValidData from 'components/Common/Form/Input/ValidData';
import { IFormCadastroRg } from '../../../form';

// COMPONENTS
import IdentidadeProfissional from './IdentidadeProfissional';

// STYLED
// import { TitleSolicitarCPF } from './styled';

interface Props {
	formik: FormikProps<IFormCadastroRg>;
}

const DadosAdicionaisRG: React.FC<Props> = ({ formik }) => {
	const { setFieldValue, errors } = formik;
	const dispatch = useDispatch();

	useEffect(() => {
		if (
			errors.certidaoMilitar &&
			calcularIdade(formik.values.dataNascimento) < 17 &&
			!formik.values.certidaoMilitar?.length
		) {
			dispatch(clearNotifications());
		}
	}, [
		dispatch,
		errors.certidaoMilitar,
		formik,
		formik.values.certidaoMilitar,
		formik.values.dataNascimento,
	]);

	useEffect(() => {
		if (
			errors.cnh &&
			calcularIdade(formik.values.dataNascimento) < 18 &&
			!formik.values.cnh?.length
		) {
			dispatch(clearNotifications());
		}
	}, [dispatch, errors.cnh, formik.values.cnh, formik.values.dataNascimento]);

	useEffect(() => {
		if (
			errors.tituloEleitor &&
			calcularIdade(formik.values.dataNascimento) < 17 &&
			!formik.values.tituloEleitor?.length
		) {
			dispatch(clearNotifications());
		}
	}, [
		dispatch,
		errors.tituloEleitor,
		formik.values.tituloEleitor,
		formik.values.dataNascimento,
	]);

	return (
		<>
			<FormBox title="Dados adicionais para constar na Carteira de Identidade">
				{/* {!disabled2Via &&
					!(
						cadastroRg?.form.principal?.inscricaoCpf === 'true' &&
						cadastroRg?.form?.principal?.cpf
					) && (
						<Row justify="center" gutter={[0, 20]}>
							<Col>
								<TitleSolicitarCPF>
									Deseja solicitar a inscrição no CPF?
								</TitleSolicitarCPF>
							</Col>
							<Col>
								<Field
									as={Radio}
									name="inscricaoCpf"
									options={data.simNaoString}
									onChange={(e: ChangeEvent<HTMLInputElement>) => {
										setFieldValue('inscricaoCpf', e.target.value);
										if (e.target.value === 'true') {
											setFieldValue('cpf', '');
										}
									}}
									error={!!formik.errors.inscricaoCpf}
								/>
							</Col>
						</Row>
					)}
				*/}

				<Row gutter={[0, 10]}>
					<Col span={12}>
						<Field
							as={InputMask}
							title="Número PIS/NIS"
							subtitle="(Apenas números)"
							name="pis"
							mask="99999999999"
							maskChar=""
							size={54.5}
							error={!!formik.errors.pis}
						/>
					</Col>

					<Col span={12}>
						<Field
							as={InputMask}
							title="Número PASEP"
							subtitle="(Apenas números)"
							name="pasep"
							maskChar=""
							mask="9999999999-9"
							size={57}
							error={!!formik.errors.pasep}
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={9}>
						<Field
							as={InputMask}
							title="Título de Eleitor"
							name="tituloEleitor"
							mask="999999999999"
							maskChar=""
							size={88}
							error={!!formik.errors.tituloEleitor}
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={24} />
				</Row>

				<Row gutter={[0, 30]}>
					<Col span={24}>
						<Section title="CTPS" size="sm">
							<Row>
								<Col span={8}>
									<Field
										as={InputMask}
										title="Número"
										titleSize="sm"
										name="ctpsNum"
										maskChar=""
										mask="99999999999999"
										required={
											formik.values.ctpsNum ||
											formik.values.ctpsSerie ||
											formik.values.ctpsSerieUf
										}
										error={hasError(errors, 'ctps') || !!formik.errors.ctpsNum}
									/>
								</Col>

								<Col span={7}>
									<Field
										as={InputMask}
										title="Série"
										titleSize="sm"
										name="ctpsSerie"
										mask="9999"
										maskChar=""
										required={
											formik.values.ctpsNum ||
											formik.values.ctpsSerie ||
											formik.values.ctpsSerieUf
										}
										error={
											hasError(errors, 'ctps') || !!formik.errors.ctpsSerie
										}
									/>
								</Col>

								<Col span={9}>
									<Field
										as={Select}
										title="UF"
										titleSize="sm"
										name="ctpsSerieUf"
										options={data.uf}
										onChange={(v: string) => setFieldValue('ctpsSerieUf', v)}
										required={
											formik.values.ctpsNum ||
											formik.values.ctpsSerie ||
											formik.values.ctpsSerieUf
										}
										error={
											hasError(errors, 'ctps') || !!formik.errors.ctpsSerieUf
										}
									/>
								</Col>
							</Row>
						</Section>
					</Col>
				</Row>

				<Row gutter={[0, 30]}>
					<Col span={24}>
						<Section title="CNH" size="sm">
							<Row gutter={[0, 10]}>
								<Col span={8}>
									<Field
										as={InputMask}
										title="CNH"
										name="cnh"
										mask="99999999999"
										maskChar=""
										error={!!formik.errors.cnh}
										titleSize="sm"
									/>
								</Col>
								<Col span={7}>
									<Field
										as={Select}
										titleSize="sm"
										title="Categoria"
										name="categoriaCnh"
										titleAlign="end"
										options={data.tipoCnh}
										onChange={(v: string) => {
											formik.setFieldValue('categoriaCnh', v);
										}}
										error={!!formik.errors.categoriaCnh}
									/>
								</Col>
								<Col span={9}>
									<Field
										as={ValidData}
										title="Data de Validade"
										subtitle="(DD/MM/AAAA)"
										name="dataValidadeCNH"
										mask="99/99/9999"
										titleAlign="end"
										error={hasError(formik.errors, 'dataValidadeCNH')}
										formik={formik}
										onChange={(v: string) =>
											formik.setFieldValue('dataValidadeCNH', v)
										}
										// disabled={formik.values.dni !== '10977'}
									/>
								</Col>
							</Row>
						</Section>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={24} />
				</Row>

				<Row gutter={[0, 30]}>
					<Col span={12}>
						<Field
							as={InputMask}
							title="Cert. Militar"
							name="certidaoMilitar"
							mask="999999999999999"
							maskChar=""
							error={!!formik.errors.certidaoMilitar}
						/>
					</Col>

					<Col span={12}>
						<Field
							as={InputMask}
							title="CNS"
							name="nascSaude"
							mask="999999999999999"
							maskChar=""
							error={!!formik.errors.nascSaude}
							// disabled={formik.values.dni === '10977'}
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Field
							as={CheckBox}
							disabled={calcularIdade(formik.values.dataNascimento) < 18}
							name="doador"
							checked={
								calcularIdade(formik.values.dataNascimento) >= 18
									? formik.values.doador
									: false
							}
							subtitle="Doador de Órgãos e Tecidos (Permitido apenas para maiores de 18 anos)"
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								const { checked } = e.target;
								formik.setFieldValue('doador', checked);
							}}
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={24}>
						<IdentidadeProfissional formik={formik} />
					</Col>
				</Row>
			</FormBox>

			<Row gutter={[0, 10]}>
				<Col span={24} />
			</Row>
		</>
	);
};

export default DadosAdicionaisRG;
