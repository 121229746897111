import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, CadastrarUsuarioRequest } from './types';

// ACTIONS
import { cadastrarUsuarioSuccess, cadastrarUsuarioFailure } from './actions';

function* cadastrarUsuario(request: ReducerActionRotaSP) {
	const {
		payload,
		token,
	}: { token?: string; payload: CadastrarUsuarioRequest } = request;

	const body = { ...payload };

	// delete body.id;

	const response: ApiResponse = yield call(
		postApi,
		token || '',
		PATH_SGU_API,
		`usuarios`,
		body,
		{
			idUsuarioAutenticado: payload.id,
		},
	);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarUsuarioSuccess(response));
	} else {
		yield put(cadastrarUsuarioFailure());
	}
}

export default all([
	takeLatest(Types.CADASTRAR_USUARIO_REQUEST, cadastrarUsuario),
]);
