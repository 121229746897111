import React, { useCallback, useEffect, useState } from 'react';
import { Field, FormikProps, Form } from 'formik';
import { Col, Row, Modal } from 'antd';

// COMPONENTS
import Button from 'components/Common/Button';
import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask';
import Section from 'components/Common/Section';
import Select from 'components/Common/Form/Select';
import Error from 'components/Common/Notifications/FormError/index';

import dataEnum from 'store/modules/api/educacaoUpdate/enum';

// UTILS
import hasError from 'utils/getFormErrors';

// STORE
import {
	clearNotificationsValidate,
	showInside,
} from 'store/modules/app/notifications/actions';

// FORM
import { ApplicationState } from 'store';
import { useSelector } from 'react-redux';
import { EnumDadosBasicos } from 'store/modules/api/educacaoUpdate/combos/dadosBasicos/types';
import { ITelefone } from '../../form';

interface IProps {
	formik: FormikProps<any>;
	modalTelefone: boolean;
	setModalTelefone: (v: boolean) => void;
	telefoneSelecionado: ITelefone | null;
	setTelefoneSelecionado: (v: object) => void;
}

const ModalTelefone: React.FC<IProps> = ({
	formik,
	modalTelefone = false,
	setModalTelefone = () => {},
	telefoneSelecionado = null,
	setTelefoneSelecionado = () => {},
}) => {
	const { notifications } = useSelector((state: ApplicationState) => state.app);
	const { dadosBasicos } = useSelector(
		(state: ApplicationState) => state.api.educacaoUpdate.combos,
	);

	const [tipoCelular, setTipoCelular] = useState<boolean>(false);
	const [maskDigitoTelefone, setMaskDigitoTelefone] = useState('');
	const [typesPhone, setTypesPhone] = useState<any[]>([]);
	const { setFieldValue } = formik;

	const resetTelefone = useCallback(() => {
		setFieldValue('tipo', '');
		setFieldValue('tipoTelefone', '');
		setFieldValue('ddd', '');
		setFieldValue('numero', '');
		setFieldValue('complemento', '');
		setFieldValue('sms', '');
		setTipoCelular(false);
	}, [setFieldValue]);

	useEffect(() => {
		if (tipoCelular) {
			setMaskDigitoTelefone('99999-9999');
		} else {
			setMaskDigitoTelefone('9999-9999');
			setFieldValue('sms', '');
		}
	}, [formik.values, tipoCelular, setFieldValue]);

	const handleChangeTipo = (v: string) => {
		const [result] =
			dadosBasicos.data?.tiposTelefone.filter(nome => {
				if (nome.codigo === v) {
					return nome;
				}
				return null;
			}) || [];
		formik.setFieldValue('tipoTelefone', result?.descricao.toString());
		formik.setFieldValue('tipo', result?.codigo);
	};

	const handleChangeSMS = (v: any) => {
		const [result] = dataEnum.sms.filter(nome => {
			if (nome.value === v) {
				return nome?.label;
			}
			return null;
		});
		formik.setFieldValue('sms', result?.label);
	};

	const convertObjectPropsName = (object: EnumDadosBasicos[]) => {
		if (object && object.length) {
			const newObject: any[] = [];
			object.map((item: EnumDadosBasicos) => {
				newObject.push({
					label: item.descricao,
					value: item.codigo,
				});
				return null;
			});
			return newObject;
		}
		return [];
	};

	useEffect(() => {
		if (modalTelefone) {
			showInside(true);
		} else {
			showInside(false);
		}
	}, [modalTelefone]);

	useEffect(() => {
		if (telefoneSelecionado) {
			setFieldValue('numero', telefoneSelecionado.numero);
			setFieldValue('tipo', telefoneSelecionado.tipo);
			setFieldValue('tipoTelefone', telefoneSelecionado.tipoTelefone);
			setFieldValue('ddd', telefoneSelecionado.ddd);
			setFieldValue('complemento', telefoneSelecionado.complemento);
			setFieldValue('sms', telefoneSelecionado.sms);
			setTipoCelular(
				!!(
					telefoneSelecionado.tipo === '3' || telefoneSelecionado.tipo === '5'
				),
			);
		}
	}, [setFieldValue, telefoneSelecionado]);

	useEffect(() => {
		if (dadosBasicos.data?.tiposTelefone) {
			const result = convertObjectPropsName(dadosBasicos.data.tiposTelefone);
			setTypesPhone(result);
		}
	}, [dadosBasicos.data]);

	return (
		<Modal
			title=""
			visible={modalTelefone}
			onCancel={() => {
				resetTelefone();
				setTelefoneSelecionado({});
				clearNotificationsValidate();
				setModalTelefone(false);
			}}
			width={880}
			footer={null}
		>
			{notifications.errors &&
				notifications.errors.length > 0 &&
				notifications.showInside && <Error errors={notifications.errors} />}

			<Section title="Adicionar Telefones">
				<Form autoComplete="off">
					<Row gutter={[0, 10]}>
						<Col span={12}>
							<Field
								as={Select}
								title="Tipo Telefone"
								titleSize="md"
								name="tipo"
								options={typesPhone}
								onChange={(v: string) => {
									handleChangeTipo(v);
									v === '3' || v === '5'
										? setTipoCelular(true)
										: setTipoCelular(false);
								}}
								error={hasError(formik.errors, 'tipoTelefone')}
								required
							/>
						</Col>
						<Col span={12}>
							<Field
								as={Select}
								title="Aceita SMS"
								titleSize="md"
								name="sms"
								disabled={
									formik.values.tipo !== '3' && formik.values.tipo !== '5'
								}
								options={dataEnum.sms}
								onChange={(v: string) => handleChangeSMS(v)}
								error={hasError(formik.errors, 'sms')}
							/>
						</Col>
					</Row>
					<Row gutter={[0, 10]}>
						<Col span={5}>
							<Field
								as={InputMask}
								titleSize="md"
								title="DDD"
								mask="99"
								name="ddd"
								error={hasError(formik.errors, 'ddd')}
								required
							/>
						</Col>

						<Col span={10}>
							<Field
								as={InputMask}
								title="Número"
								name="numero"
								mask={maskDigitoTelefone}
								error={hasError(formik.errors, 'numero')}
								required
							/>
						</Col>

						<Col span={9}>
							<Field
								as={Input}
								title="Complemento"
								name="complemento"
								maxLength={30}
								onChange={(e: React.FormEvent<HTMLInputElement>) => {
									formik.setFieldValue(
										'complemento',
										e.currentTarget.value.toUpperCase() || '',
									);
								}}
							/>
						</Col>
					</Row>
					<Button type="submit">
						{telefoneSelecionado?.numero ? 'Salvar' : '+ Adicionar'}
					</Button>
				</Form>
			</Section>
		</Modal>
	);
};

export default ModalTelefone;
