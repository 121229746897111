import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types } from './types';

// ACTIONS
import { consultarTiposSuccess, consultarTiposFailure } from './actions';

function* consultarTipos(request: ReducerActionRotaSP) {
	const { token }: { token?: string; payload: null } = request;
	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_SGU_API,
		`tipos-localidade`,
	);

	if (response.status === 200) {
		yield put(consultarTiposSuccess(response));
	} else {
		yield put(consultarTiposFailure());
	}
}

export default all([takeLatest(Types.CONSULTAR_TIPOS, consultarTipos)]);
