export function formatStringNumber(value: string): number {
	return Number(value.replace('.', '').replace(',', '.'));
}

export function convertIntToDouble(value: number): string {
	return `${value}.0`;
}

export function random(max: number): string {
	return Math.floor(Math.random() * max + 1).toString();
}

export function formatCurrency(value: number | string): string {
	if (!value) return '';

	if (typeof value === 'string') {
		const newValue = formatStringNumber(value);

		return newValue.toLocaleString('pt-br', {
			style: 'currency',
			currency: 'BRL',
		});
	}

	return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}

export function completarCPF(cpf: string | number): string {
	const cpfStr = String(cpf).trim();

	if (cpfStr.length >= 11) {
		return cpfStr;
	}

	const cpfCompleto = cpfStr.padStart(11, '0');
	return cpfCompleto;
}
