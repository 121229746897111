import { combineReducers } from 'redux';

import consultarNaturalidade from './consultarNaturalidade/reducer';
import consultarNaturalidades from './consultarNaturalidades/reducer';
import atualizarNaturalidade from './atualizarNaturalidade/reducer';
import cadastrarNaturalidade from './cadastrarNaturalidade/reducer';
import excluirNaturalidade from './excluirNaturalidade/reducer';

export default combineReducers({
	consultarNaturalidade,
	consultarNaturalidades,
	atualizarNaturalidade,
	cadastrarNaturalidade,
	excluirNaturalidade,
});
