import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, getApi } from 'services/rotasp/api';
import { ReducerActionRotaSP } from 'store/modules/types';
import { PATH_SGU_DOMINIOS_API } from '../../../../../../../services/rotasp/path';
import { ConsultarNaturalidadeRequest, Types } from './types';
import {
	consultarNaturalidadeSuccess,
	consultarNaturalidadeFailure,
} from './actions';

// UTILS

function* consultarNaturalidade(request: ReducerActionRotaSP) {
	const {
		token = '',
		payload,
	}: { token?: string; payload: ConsultarNaturalidadeRequest } = request;

	const response: ApiResponse = yield call(
		getApi,
		token,
		PATH_SGU_DOMINIOS_API,
		`naturalidades`,
		payload,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(consultarNaturalidadeSuccess(response));
	} else {
		yield put(consultarNaturalidadeFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTAR_NATURALIDADE_REQUEST, consultarNaturalidade),
]);
