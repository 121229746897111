import { ReducerActionRotaSP } from 'store/modules/types';
import { ConsultarUsuariosRequest, Types } from './types';

export function consultarUsuariosRequest(
	token: string,
	payload: ConsultarUsuariosRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_USUARIOS_REQUEST,
		token,
		payload,
	};
}
export function consultarUsuariosSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_USUARIOS_SUCCESS,
		payload,
	};
}
export function consultarUsuariosFailure(payload: []): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_USUARIOS_FAILURE,
		payload,
	};
}
export function consultarUsuariosClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_USUARIOS_CLEAR,
		payload: null,
	};
}
