import React, { useContext, useEffect } from 'react';

// ROUTES
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTE_IIRGD } from 'routes/paths';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { setBreadcrumb } from 'store/modules/app/breadcrumb/actions';

// COMPONENTS
import { gerarDareClear } from 'store/modules/api/gerarDare/actions';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import { consultarProcessoRequest } from 'store/modules/api/iirgd/integrador/consultarProcesso/actions';
import { FormPrimeiraViaMenor } from './Formularios/PrimeiraVia/Menor';
import { FormPrimeiraViaMaior } from './Formularios/PrimeiraVia/Maior';
import { FormSegundaVia } from './Formularios/SegundaVia';

const InclusaoObservacoes: React.FC = () => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();

	const { cadastroRg } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	const { primeiraVia, segundaVia } = useSelector(
		(state: ApplicationState) => state.api.iirgd.cadastroRg.tipo,
	);

	const { maior } = useSelector(
		(state: ApplicationState) => state.api.iirgd.cadastroRg.info,
	);

	const { token } = useContext<IAuthContext>(AuthContext);

	useEffect(() => {
		setTimeout(() => {
			if (location.pathname === '/iirgd/rg-fic-digital/inclusao-observacoes') {
				if (primeiraVia && maior) {
					dispatch(
						setBreadcrumb(
							[
								{ name: 'IIRGD', route: '/iirgd' },
								{ name: 'Observações - (1ª Via \u005C Maior)' },
							],
							true,
						),
					);
				} else if (primeiraVia && !maior) {
					dispatch(
						setBreadcrumb(
							[
								{ name: 'IIRGD', route: '/iirgd' },
								{ name: 'Observações - (1ª Via \u005C Menor)' },
							],
							true,
						),
					);
				} else if (segundaVia && maior) {
					dispatch(
						setBreadcrumb(
							[
								{ name: 'IIRGD', route: '/iirgd' },
								{ name: 'Observações - (2ª Via \u005C Maior)' },
							],
							true,
						),
					);
				} else if (segundaVia && !maior) {
					dispatch(
						setBreadcrumb(
							[
								{ name: 'IIRGD', route: '/iirgd' },
								{ name: 'Observações - (2ª Via \u005C Menor)' },
							],
							true,
						),
					);
				} else {
					dispatch(
						setBreadcrumb(
							[
								{ name: 'Página Inicial', route: '/menu-orgaos' },
								{ name: 'IIRGD', route: '/iirgd' },
								{ name: 'Inclusão de Observações' },
							],
							true,
						),
					);
				}
			}
		}, 100);
	}, [dispatch, location.pathname, maior, primeiraVia, segundaVia]);

	useEffect(() => {
		if (!cadastroRg.form.principal) {
			history.push(ROUTE_IIRGD);
		}
	}, [cadastroRg.form.principal, history]);

	useEffect(() => {
		dispatch(gerarDareClear());
		const cpfRg = cadastroRg?.form.principal?.cpf
			?.replace('-', '')
			.replaceAll('.', '');

		if (cadastroRg.form.principal?.dni === '10977') {
			dispatch(
				consultarProcessoRequest(token, {
					cpfCidadao: cpfRg || '',
				}),
			);
		}
	}, [cadastroRg, dispatch, token]);

	return (
		<>
			{cadastroRg.tipo.primeiraVia && (
				<>{!cadastroRg.info.maior && <FormPrimeiraViaMenor />}</>
			)}

			{cadastroRg.tipo.primeiraVia && (
				<>{cadastroRg.info.maior && <FormPrimeiraViaMaior />}</>
			)}

			{cadastroRg.tipo.segundaVia && <FormSegundaVia />}
		</>
	);
};

export default InclusaoObservacoes;
