import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, IConsultarRelatorioMensalDareRequest } from './types';
import {
	consultarRelatorioMensalDareClear,
	consultarRelatorioMensalDareFailure,
	consultarRelatorioMensalDareSuccess,
} from './actions';

function* consultarRelatorioMensalDare(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: IConsultarRelatorioMensalDareRequest } =
		request;

	yield put(consultarRelatorioMensalDareClear());

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_SGU_API,
		`estatisticas/relatorio-dare`,
		{
			dataInicial: payload.dataInicial,
			dataFinal: payload.dataFinal,
		},
	);

	if (response.status === 200) {
		yield put(
			consultarRelatorioMensalDareSuccess(response.status, response.data),
		);
	} else {
		yield put(consultarRelatorioMensalDareFailure());
	}
}

export default all([
	takeLatest(
		Types.CONSULTAR_RELATORIO_MENSAL_DARE_REQUEST,
		consultarRelatorioMensalDare,
	),
]);
