import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Field, FormikProps } from 'formik';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

// REDUX
import { ApplicationState } from 'store';
import { Responsavel } from 'store/modules/api/educacaoUpdate/responsavel/cadastroResponsavel/types';
import { comboMunicipiosRequest } from 'store/modules/api/educacaoUpdate/fichaAluno/comboMunicipios/actions';
import { comboPaisesRequest } from 'store/modules/api/educacaoUpdate/fichaAluno/comboPaises/actions';
import { EnumDadosBasicos } from 'store/modules/api/educacaoUpdate/combos/dadosBasicos/types';
import { EnumUF } from 'store/modules/api/educacaoUpdate/combos/uf/types';

// COMPONENTS
import FormBox from 'components/Common/Form/FormBox';
import Input from 'components/Common/Form/Input/Input';
import Select from 'components/Common/Form/Select';
import InputMask from 'components/Common/Form/Input/InputMask';
import ValidDataInput from 'components/Common/Form/Input/ValidData';

// CONTEXT
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// UTILS
import {
	onlyAlfaNumerico,
	onlyNumbersLettersSpace,
} from 'utils/genericFunctions';
import hasError from 'utils/getFormErrors';

import { ContainerFichaCadastral } from './styled';

interface Props {
	formik: FormikProps<Responsavel>;
	setCpf?: any;
}

const DadosPessoais: React.FC<Props> = ({ formik, setCpf }) => {
	const dispatch = useDispatch();
	const { token } = useContext<IAuthContext>(AuthContext);

	const [dataMunicipios, setDataMunicipios] = useState<
		Array<{ value: string; label: string }>
	>([]);
	const [dataPaises, setDataPaises] = useState<
		Array<{ value: string; label: string }>
	>([]);

	const { municipios } = useSelector(
		(state: ApplicationState) =>
			state.api.educacaoUpdate.fichaAluno.comboMunicipios.data,
	);

	const { paises } = useSelector(
		(state: ApplicationState) =>
			state.api.educacaoUpdate.fichaAluno.comboPaises.data,
	);

	const { cadastrarResponsavel } = useSelector(
		(state: ApplicationState) => state.api.educacaoUpdate.responsavel,
	);

	const { dadosBasicos, comboUf } = useSelector(
		(state: ApplicationState) => state.api.educacaoUpdate.combos,
	);

	useEffect(() => {
		if (formik.values.ufNascimento !== '') {
			dispatch(
				comboMunicipiosRequest(token, { uf: formik.values.ufNascimento }),
			);
		}
	}, [dispatch, formik.values.ufNascimento, token]);

	useEffect(() => {
		if (paises.length < 1) {
			dispatch(comboPaisesRequest(token));
		}
	}, [dispatch, paises, token]);

	useEffect(() => {
		const listaMunicipios = municipios?.map(item => {
			return {
				value: item.codigo,
				label: item.descricao,
			};
		});
		setDataMunicipios(listaMunicipios);
	}, [municipios]);

	useEffect(() => {
		const listaPaises: Array<{ value: string; label: string }> = [];
		paises?.map((item: { codigo: string; descricao: string }) => {
			return listaPaises.push({
				value: item.codigo,
				label: item.descricao,
			});
		});
		setDataPaises(listaPaises);
	}, [paises]);

	const handleCPF = (value: string) => {
		formik.setFieldValue('cpf', value);
		setCpf(value);
	};

	const handleUfNascimento = (value: string) => {
		if (value === formik.values.ufNascimento) return;
		formik.setFieldValue('ufNascimento', value);
	};

	const handleFields = (values: string) => {
		if (values === '1') {
			if (formik.values.rnm) formik.setFieldValue('rnm', '');
			if (formik.values.nomePaisNascimento)
				formik.setFieldValue('nomePaisNascimento', '');
		} else if (values === '2') {
			if (formik.values.nrRG) formik.setFieldValue('nrRG', '');
			if (formik.values.digitoRG) formik.setFieldValue('digitoRG', '');
			if (formik.values.ufRG) formik.setFieldValue('ufRG', '');
			if (formik.values.ufNascimento) formik.setFieldValue('ufNascimento', '');
			if (formik.values.cidadeNascimento)
				formik.setFieldValue('cidadeNascimento', '');
			if (formik.values.rnm) formik.setFieldValue('rnm', '');
			if (formik.values.nomePaisNascimento)
				formik.setFieldValue('nomePaisNascimento', '');
			if (formik.values.codPaisNascimento)
				formik.setFieldValue('codPaisNascimento', '');
		} else if (values === '3') {
			if (formik.values.rnm) formik.setFieldValue('rnm', '');
			if (formik.values.nomePaisNascimento)
				formik.setFieldValue('nomePaisNascimento', '');
			if (formik.values.ufNascimento) formik.setFieldValue('ufNascimento', '');
			if (formik.values.cidadeNascimento)
				formik.setFieldValue('cidadeNascimento', '');
		}
	};

	const handleNascionalidade = (values: string) => {
		handleFields(values);
		if (values === '2') {
			formik.setFieldValue('nrRG', '');
			formik.setFieldValue('digitoRG', '');
			formik.setFieldValue('ufRG', '');
			formik.setFieldValue('ufNascimento', '');
			formik.setFieldValue('cidadeNascimento', '');
		} else if (values === '1' || values === '3') {
			formik.setFieldValue('rnm', '');
			formik.setFieldValue('codPaisNascimento', '');
			formik.setFieldValue('nomePaisNascimento', '');
		}
		formik.setFieldValue('tipoOrigem', values);
	};

	useEffect(() => {
		if (
			cadastrarResponsavel.data?.responsavel &&
			cadastrarResponsavel.status === 999 &&
			cadastrarResponsavel.data.responsavel.tipoOrigem !== '1'
		) {
			handleNascionalidade(cadastrarResponsavel.data.responsavel.tipoOrigem);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cadastrarResponsavel]);

	const handleChangePais = (v: any) => {
		let codigoPais = '';
		const [result] = paises.filter((nome: { codigo: any; descricao: any }) => {
			if (nome.codigo === v) {
				codigoPais = nome.codigo;
				return nome.descricao;
			}
			return null;
		});
		formik.setFieldValue('codPaisNascimento', codigoPais?.toString() || '');
		formik.setFieldValue('nomePaisNascimento', result?.descricao || '');
	};

	const getEditedDadosBasicosOptionList = useCallback(
		(
			objectPropNameToEdit:
				| 'sexos'
				| 'estadosCivis'
				| 'nacionalidades'
				| 'responsabilidades',
		): { label: string; value: string }[] => {
			if (
				dadosBasicos.data &&
				objectPropNameToEdit &&
				dadosBasicos.data[objectPropNameToEdit]
			) {
				// Remove number e enum diferente do padrao.
				const {
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					processoId,
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					deficiencias,
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					provasAmpliadas,
					...dadosBasicosEditedData
				} = dadosBasicos.data;

				return (
					dadosBasicosEditedData[
						objectPropNameToEdit as keyof typeof dadosBasicosEditedData
					].map((opt: EnumDadosBasicos) => {
						return { label: opt.descricao, value: opt.codigo.toString() };
					}) || []
				);
			}

			return [];
		},
		[dadosBasicos.data],
	);

	const getEditedUfsOptionList = useCallback((): {
		label: string;
		value: string;
	}[] => {
		if (comboUf.data && comboUf.data.ufs.length > 0) {
			const { ufs } = comboUf.data;

			return (
				ufs.map((opt: EnumUF) => {
					return { label: opt.descricao, value: opt.codigo };
				}) || []
			);
		}

		return [];
	}, [comboUf.data]);

	return (
		<ContainerFichaCadastral>
			<FormBox title="Dados Pessoais do Responsável">
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Field
							as={Input}
							title="Nome"
							name="nome"
							maxLength={60}
							onChange={(e: React.FormEvent<HTMLInputElement>) => {
								formik.setFieldValue(
									'nome',
									onlyNumbersLettersSpace(e.currentTarget.value),
								);
							}}
							error={hasError(formik.errors, 'nome')}
							required
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={10}>
						<Field
							as={Select}
							title="Nacionalidade"
							name="tipoOrigem"
							options={getEditedDadosBasicosOptionList('nacionalidades')}
							onChange={(v: string) => handleNascionalidade(v)}
							error={hasError(formik.errors, 'tipoOrigem')}
							overflow
							required
						/>
					</Col>
					<Col span={6}>
						<Field
							as={Select}
							title="Sexo"
							titleSize={50}
							name="codSexo"
							options={getEditedDadosBasicosOptionList('sexos')}
							onChange={(v: string) => formik.setFieldValue('codSexo', v)}
							error={hasError(formik.errors, 'codSexo')}
							required
						/>
					</Col>
					<Col span={1} />
					<Col span={7}>
						<Field
							title="Data de Nascimento"
							name="dataNascimento"
							type="text"
							mask="99/99/9999"
							titleSize={140}
							as={ValidDataInput}
							error={hasError(formik.errors, 'dataNascimento')}
							formik={formik}
							onChange={(v: string) =>
								formik.setFieldValue('dataNascimento', v)
							}
							required
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={8}>
						<Field
							title="CPF"
							name="cpf"
							mask="999.999.999-99"
							as={InputMask}
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								handleCPF(e.currentTarget.value)
							}
							error={hasError(formik.errors, 'cpf')}
							required
						/>
					</Col>
					<Col span={6}>
						<Field
							as={InputMask}
							title="RG"
							titleSize={45}
							size={100}
							name="nrRG"
							mask="************"
							maskChar={null}
							error={hasError(formik.errors, 'nrRG')}
							disabled={formik.values.tipoOrigem === '2'}
							required={formik.values.tipoOrigem !== '2'}
						/>
					</Col>
					<Col span={3}>
						<Field
							as={InputMask}
							title="Dígito RG"
							name="digitoRG"
							titleSize={65}
							mask="**"
							size={100}
							maskChar={null}
							error={hasError(formik.errors, 'digitoRG')}
							disabled={formik.values.tipoOrigem === '2'}
						/>
					</Col>
					<Col span={7}>
						<Field
							as={Select}
							titleSize={50}
							title="UF RG"
							name="ufRG"
							options={getEditedUfsOptionList()}
							onChange={(v: string) => {
								formik.setFieldValue('ufRG', v);
							}}
							error={hasError(formik.errors, 'ufRG')}
							disabled={formik.values.tipoOrigem === '2'}
							required={formik.values.tipoOrigem !== '2'}
							overflow
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={12}>
						<Field
							as={Select}
							title="País de Origem"
							name="nomePaisNascimento"
							options={dataPaises}
							onChange={(v: string) => handleChangePais(v)}
							error={hasError(formik.errors, 'nomePaisNascimento')}
							overflow
							disabled={formik.values.tipoOrigem !== '2'}
							required={formik.values.tipoOrigem === '2'}
						/>
					</Col>
					<Col span={12}>
						<Field
							as={Input}
							title="RNM"
							name="rnm"
							maxLength={8}
							value={onlyAlfaNumerico(formik.values.rnm)}
							error={!!formik.errors.rnm}
							disabled={formik.values.tipoOrigem !== '2'}
							required={formik.values.tipoOrigem === '2'}
						/>
					</Col>
				</Row>
				<Row gutter={[0, 10]}>
					<Col span={12}>
						<Field
							as={Select}
							title="Parentesco"
							name="codResponsabilidade"
							options={getEditedDadosBasicosOptionList('responsabilidades')}
							onChange={(v: string) =>
								formik.setFieldValue('codResponsabilidade', v)
							}
							error={hasError(formik.errors, 'codResponsabilidade')}
							required
						/>
					</Col>
					<Col span={12}>
						<Field
							as={Select}
							title="Estado Civil"
							name="codEstadoCivil"
							options={getEditedDadosBasicosOptionList('estadosCivis')}
							onChange={(v: string) =>
								formik.setFieldValue('codEstadoCivil', v)
							}
							error={hasError(formik.errors, 'codEstadoCivil')}
							required
						/>
					</Col>
				</Row>
				<Row gutter={[0, 10]}>
					<Col span={12}>
						<Field
							as={Select}
							title="UF Nascimento"
							name="ufNascimento"
							options={getEditedUfsOptionList()}
							onChange={(v: string) => handleUfNascimento(v)}
							error={hasError(formik.errors, 'ufNascimento')}
							disabled={
								formik.values.tipoOrigem === '2' ||
								formik.values.tipoOrigem === '3'
							}
							defaultFirstOptionText={
								formik.values.tipoOrigem === '1'
									? '- SELECIONE O UF DE NASCIMENTO -'
									: ''
							}
							required={formik.values.tipoOrigem === '1'}
							overflow
						/>
					</Col>
					<Col span={12}>
						<Field
							as={Select}
							title="Cidade de Nascimento"
							name="cidadeNascimento"
							options={dataMunicipios}
							asteriskDistance={40}
							onChange={(v: string) =>
								formik.setFieldValue('cidadeNascimento', v)
							}
							error={hasError(formik.errors, 'cidadeNascimento')}
							disabled={
								formik.values.tipoOrigem === '2' ||
								formik.values.ufNascimento === '' ||
								formik.values.ufNascimento === '3'
							}
							required={formik.values.tipoOrigem === '1'}
							defaultFirstOptionText={
								formik.values.ufNascimento === '' &&
								formik.values.tipoOrigem === '1'
									? '- SELECIONE O UF DE NASCIMENTO -'
									: '- SELECIONE A CIDADE -'
							}
						/>
					</Col>
				</Row>
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Field
							as={Input}
							title="E-mail"
							name="emailResponsavel"
							error={hasError(formik.errors, 'emailResponsavel')}
							required
						/>
					</Col>
				</Row>
			</FormBox>
		</ContainerFichaCadastral>
	);
};

export default DadosPessoais;
