/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Field, FormikProps } from 'formik';
import { Checkbox, Col, RadioChangeEvent, Row, message } from 'antd';

import CheckBox from 'components/Common/Form/Checkbox';

import hasError from 'utils/getFormErrors';
import FormBox from 'components/Common/Form/FormBox';
import Section from 'components/Common/Section';
import Select from 'components/Common/Form/Select';

import dataEnum from 'store/modules/api/educacaoUpdate/enum';

import ValidDataInput from 'components/Common/Form/Input/ValidData';
import Radio from 'components/Common/Form/Radio';
import { IFichaAluno } from '../form';

import { CheckboxApoioEscolar, Content } from './styled';

interface Props {
	formik: FormikProps<IFichaAluno>;
}

const dataNecessidadeEspeciais = [
	{ key: '1', codigo: 1, description: 'Múltipla' },
	{ key: '2', codigo: 2, description: 'Cegueira' },
	{ key: '3', codigo: 3, description: 'Baixa Visão' },
	{ key: '4', codigo: 4, description: 'Surdez Severa ou Profunda' },
	{ key: '5', codigo: 5, description: 'Surdez Leve ou Moderada' },
	{ key: '6', codigo: 6, description: 'Surdocegueira' },
	{ key: '7', codigo: 7, description: 'Física – Paralisia Cerebral' },
	{ key: '8', codigo: 8, description: 'Física – Cadeirante' },
	{ key: '9', codigo: 9, description: 'Física – Outros' },
	{ key: '10', codigo: 10, description: 'Síndrome de Down' },
	{ key: '11', codigo: 11, description: 'Intelectual' },
	{ key: '20', codigo: 20, description: 'Autista Infantil' },
	{ key: '21', codigo: 21, description: 'Síndrome De Asperger' },
	{ key: '22', codigo: 22, description: 'Síndrome De Rett' },
	{
		key: '23',
		codigo: 23,
		description: 'Transtorno Desintegrativo da Infância',
	},
	{ key: '30', codigo: 30, description: 'Altas Habilidades / Superdotação' },
];

const dataProfissionalApoioEscolar = [
	{
		key: '1',
		codigo: 1,
		nameFormik: 'deficiencia.profissionalApoioEscolarVO.atividadeDiaria',
		description: 'Atividade Diária',
	},
	{
		key: '3',
		codigo: 3,
		nameFormik: 'deficiencia.profissionalApoioEscolarVO.apoioHigiene',
		description: 'Apoio Higiene',
	},
	{
		key: '4',
		codigo: 4,
		nameFormik: 'deficiencia.profissionalApoioEscolarVO.apoioLocomocao',
		description: 'Apoio Locomoção',
	},
	{
		key: '5',
		codigo: 5,
		nameFormik: 'deficiencia.profissionalApoioEscolarVO.apoioAlimentacao',
		description: 'Apoio Alimentação',
	},
	{
		key: '6',
		codigo: 6,
		nameFormik: 'deficiencia.profissionalApoioEscolarVO.apoioBanheiro',
		description: 'Apoio Banheiro',
	},
	{
		key: '9',
		codigo: 9,
		nameFormik: 'deficiencia.profissionalApoioEscolarVO.apoioMedicamento',
		description: 'Apoio Medicamento',
	},
	{
		key: '2',
		codigo: 2,
		nameFormik: 'deficiencia.profissionalApoioEscolarVO.atividadeEscolar',
		description: 'Atividade Escolar',
	},
];

const dataRecursosAvancados = [
	{
		key: '1',
		codigo: 1,
		nameFormik: 'recursoAvaliacao.auxilioLeitor',
		description: 'Auxílio Leitor',
	},
	{
		key: '2',
		codigo: 2,
		nameFormik: 'recursoAvaliacao.auxilioTranscricao',
		description: 'Auxílio Para Transcrição',
	},
	{
		key: '3',
		codigo: 3,
		nameFormik: 'recursoAvaliacao.guiaInterprete',
		description: 'Guia Intérprete',
	},
	{
		key: '4',
		codigo: 4,
		nameFormik: 'recursoAvaliacao.interpreteLibras',
		description: 'Intérprete Libras',
	},
	{
		key: '5',
		codigo: 5,
		nameFormik: 'recursoAvaliacao.leituraLabial',
		description: 'Leitura Labial',
	},
	{
		key: '6',
		codigo: 6,
		nameFormik: 'recursoAvaliacao.provaAmpliada',
		description: 'Prova Ampliada',
	},
	{
		key: '7',
		codigo: 7,
		nameFormik: 'recursoAvaliacao.nenhum',
		description: 'Nenhum',
	},
	{
		key: '9',
		codigo: 9,
		nameFormik: 'recursoAvaliacao.provaBraile',
		description: 'Prova em Braile',
	},
	{
		key: '12',
		codigo: 12,
		nameFormik: 'recursoAvaliacao.cdAudioDefVisual',
		description: 'CD com áudio para deficiente visual',
	},
	{
		key: '13',
		codigo: 13,
		nameFormik: 'recursoAvaliacao.provaLinguaPortuguesa',
		description: 'Prova de Língua Portuguesa como segunda língua',
	},
	{
		key: '14',
		codigo: 14,
		nameFormik: 'recursoAvaliacao.provaVideoLibras',
		description: 'Prova em Vídeo em Libras',
	},
];

const enumNivelSuporte = [
	{
		value: '1',
		label: 'Nível 1',
		tootilpText:
			'O estudante é capaz de realizar a maioria das atividades de forma autônoma, mas ocasionalmente necessita de apoio para amplificar sua comunicação, participação e integração nas diversas demandas de cotidiano em ambiente escolar. Esse apoio pode incluir assistência no uso de tecnologia assistiva, suporte em interações sociais ou adaptações para atender a expectativas mais elevadas. Pode haver a necessidade de auxílio na configuração de dispositivos ou na obtenção de materiais didáticos em formatos acessíveis, como vídeos com legendas, textos com contraste ajustado, ou mudanças na rotina para desenvolver habilidades sociais. O estudante se comunica de diversas maneiras, utilizando fala, expressões faciais, movimentos corporais e gestos, conseguindo expressar-se de forma clara, relatar fatos de maneira coerente e responder a perguntas logicamente. Este estudante apresenta baixíssimo nível de desafio com relação à aderência de normas de convivência, interage bem com outras pessoas, demonstra iniciativa e afeição, participa de eventos sociais e sente-se, na grande maioria do tempo, confortável fora de casa. Em relação aos estudantes que apresentem algum(s) dos comportamentos ou características acima, somado comportamentos e/ou altas habilidades (ou superdotação), considera-se também as particularidades relacionadas à aprendizagem, principalmente em relação ao ritmo e complexidade (sendo muito comum a aprendizagem rápida e sem necessidade de repetição), para avaliação e direcionamento de suporte, voltado à oferta de acessibilidade e enriquecimento curricular.',
	},
	{
		value: '2',
		label: 'Nível 2',
		tootilpText:
			'O estudante necessita de suporte substancial, por apresentar dificuldades que impactam a execução de atividades, podendo apresentar desafios de comunicação e sociabilização. Alguns estudantes podem precisar de apoio socioemocional para lidar com situações desafiadoras que comprometam seus níveis de conforto, inclusive sensoriais. Neste contexto, é muito comum que o estudante necessite de ajustes contínuos de rotina, bem como de currículo acessível e material adaptado para execução de atividades e para comunicar-se. Este estudante também poderá requerer apoio para utilização de sistemas digitais e para participar, compreender e realizar atividades em geral. O vocabulário deste estudante apresenta limitações, portanto, ele apresenta dificuldade para compreender e narrar histórias, enfrenta problemas com conceitos temporais e espaciais, demonstrando desinteresse e certa desorganização na execução de suas atividades e zelo pelos materiais.',
	},
	{
		value: '3',
		label: 'Nível 3',
		tootilpText:
			'O estudante enfrenta déficits severos que afetam profundamente todas as áreas da vida, requerendo apoio muito substancial e contínuo. Ele enfrenta grandes desafios para lidar com mudanças e demandas do ambiente, necessitando de suporte, como ajuda pessoal para participar de atividades escolares, tecnologia assistiva avançada e suporte adaptado para garantir sua participação nas propostas individuais e em grupo, considerando inclusive maior demanda por segurança e garantia de bem-estar no ambiente escolar. Este estudante apresenta corriqueiramente, dificuldades para demonstrar preferências ou fazer escolhas, enfrentando desafios diversos para seguir rotinas e resolver questões, tanto em contextos em que ele já está inserido, quanto em novos ambientes. Ele não identifica situações de perigo com facilidade, dificilmente compreende normas de segurança e pode também não possui autonomia para cuidar de seu próprio bem-estar.',
	},
];

const Deficiencia: React.FC<Props> = ({ formik }) => {
	const [
		selectedResourcesAlunoPossuiNecessidade,
		setSelectedResourcesAlunoPossuiNecessidade,
	] = useState<string[]>([]);
	const [
		selectedResourcesRecursoAvaliacao,
		setSelectedResourcesRecursoAvaliacao,
	] = useState<string[]>([]);
	const [
		selectedResourcesProfissionalApoio,
		setSelectedResourcesProfissionalApoio,
	] = useState<string[]>([]);
	const [codigoNivelSuporte, setCodigoNivelSuporte] = useState<string>('1');

	useEffect(() => {
		const selectedCodes = formik.values.listaNecessidadesEspeciais.map(
			item => item.codigoNecessidadeEspecial,
		);
		setSelectedResourcesAlunoPossuiNecessidade(selectedCodes);
	}, []);

	useEffect(() => {
		const selectedCodes: string[] = [];

		if (formik.values.deficiencia?.profissionalApoioEscolarVO) {
			const data = formik.values.deficiencia.profissionalApoioEscolarVO;

			dataProfissionalApoioEscolar.map(item => {
				const elementKey = item.nameFormik.split('.').pop() || '';

				if (data[elementKey as keyof typeof data] === true) {
					selectedCodes.push(item.codigo.toString());
				}
			});
		}

		setSelectedResourcesProfissionalApoio(selectedCodes);
	}, [formik.values.deficiencia.profissionalApoioEscolarVO]);

	useEffect(() => {
		const selectedCodes: string[] = [];

		if (formik.values.recursoAvaliacao) {
			const data = formik.values.recursoAvaliacao;

			dataRecursosAvancados.map(item => {
				const elementKey = item.nameFormik.split('.').pop() || '';

				if (data[elementKey as keyof typeof data] === true) {
					selectedCodes.push(item.codigo.toString());
				}
			});
		}

		setSelectedResourcesRecursoAvaliacao(selectedCodes);
	}, [formik.values.recursoAvaliacao]);

	const validateResourceConflictsNecessidades = (resources: string[]) => {
		if (resources.includes('1')) {
			return;
		}
		const conflicts = [
			{
				codes: ['20', '21', '22', '23'],
				message: 'É permitido selecionar apenas um tipo de transtorno.',
			},
		];

		if (resources.length > 1 && !resources.includes('1')) {
			message.warning(
				'Ao selecionar mais de uma necessidade especial, selecione a opção "Múltipla".',
			);
		}

		conflicts.forEach(conflict => {
			const selectedConflictResources = conflict.codes.filter(code =>
				resources.includes(code),
			);
			if (selectedConflictResources.length > 1) {
				message.error(conflict.message);
				setSelectedResourcesAlunoPossuiNecessidade(prev =>
					prev.filter(code => !conflict.codes.includes(code)),
				);
			}
		});
	};

	const validateResourceConflictsAvaliacao = (resources: string[]) => {
		const conflicts = [
			{
				codes: ['3', '4', '5'],
				message:
					'Leitura Labial, Intérprete Libras e Guia Intérprete não podem ser ativados todos juntos.',
			},
			{
				codes: ['6', '9'],
				message:
					'Prova em Braile não pode ser ativado junto com Prova Ampliada.',
			},
		];

		conflicts.forEach(conflict => {
			const selectedConflictResources = conflict.codes.filter(code =>
				resources.includes(code),
			);
			if (selectedConflictResources.length > 1) {
				message.error(conflict.message);
				setSelectedResourcesRecursoAvaliacao(prev =>
					prev.filter(code => !conflict.codes.includes(code)),
				);
			}
		});
	};

	const checkResourceConflicts = (
		newResources: string[],
		conflictCodes: string[],
		selectedResources: string[],
		currentNeed: string,
		errorMessage: string,
	) => {
		if (conflictCodes.some(code => newResources.includes(code))) {
			if (selectedResources.includes(currentNeed)) {
				message.error(errorMessage);
				return true;
			}
		}
		return false;
	};

	const handleResourceAlunoPossuiNecessidades = (code: string) => {
		setSelectedResourcesAlunoPossuiNecessidade(prev => {
			const newResources = prev.includes(code)
				? prev.filter(c => c !== code)
				: [...prev, code];
			validateResourceConflictsNecessidades(newResources);
			return newResources;
		});
	};

	const handleResourceRecursoAvaliacao = (code: string) => {
		setSelectedResourcesRecursoAvaliacao(prev => {
			const newResources = prev.includes(code)
				? prev.filter(c => c !== code)
				: [...prev, code];

			if (selectedResourcesAlunoPossuiNecessidade.includes('1')) {
				dataRecursosAvancados.forEach(item => {
					formik.setFieldValue(item.nameFormik, false);
				});

				newResources.forEach(resource => {
					dataRecursosAvancados.forEach(item => {
						if (resource === item.key) {
							formik.setFieldValue(item.nameFormik, true);
						}
					});
				});

				return newResources;
			}

			validateResourceConflictsAvaliacao(newResources);

			dataRecursosAvancados.map(item => {
				formik.setFieldValue(item.nameFormik, false);
			});

			newResources.map(reseorce => {
				dataRecursosAvancados.map(item => {
					if (reseorce === item.key) {
						formik.setFieldValue(item.nameFormik, true);
					}
				});
			});
			if (
				checkResourceConflicts(
					newResources,
					['3', '4', '5', '6', '7', '13', '14'],
					selectedResourcesAlunoPossuiNecessidade,
					'2',
					'Cegueira não permite: Nenhum, Guia intérprete, intérprete libras, leitura labial, nenhum, prova ampliada, prova vídeo libras e prova em língua portuguesa.',
				)
			)
				return prev;

			if (
				checkResourceConflicts(
					newResources,
					['3', '4', '5', '9'],
					selectedResourcesAlunoPossuiNecessidade,
					'3',
					'Baixa visão não permite: Guia intérprete, intérprete libras, leitura labial e prova em braile.',
				)
			)
				return prev;

			if (
				checkResourceConflicts(
					newResources,
					['3', '6', '9', '2', '1', '12'],
					selectedResourcesAlunoPossuiNecessidade,
					'4',
					'Surdez severa ou profunda e surdez leve ou moderada não permitem: Guia intérprete, prova ampliada, prova em braile, auxílio transcrição, auxílio leitor e CD audio deiciência visual.',
				)
			)
				return prev;

			if (
				checkResourceConflicts(
					newResources,
					['7', '12', '13', '14'],
					selectedResourcesAlunoPossuiNecessidade,
					'6',
					'Surdocegueira não permite: Nenhum, prova vídeo libras, prova língua portuguesa e CD audio deficiência visual.',
				)
			)
				return prev;

			if (
				newResources.includes('3') ||
				newResources.includes('4') ||
				newResources.includes('5') ||
				newResources.includes('6') ||
				newResources.includes('9')
			) {
				if (
					selectedResourcesAlunoPossuiNecessidade.includes('7') ||
					selectedResourcesAlunoPossuiNecessidade.includes('8') ||
					selectedResourcesAlunoPossuiNecessidade.includes('9') ||
					selectedResourcesAlunoPossuiNecessidade.includes('10') ||
					selectedResourcesAlunoPossuiNecessidade.includes('11') ||
					selectedResourcesAlunoPossuiNecessidade.includes('20') ||
					selectedResourcesAlunoPossuiNecessidade.includes('21') ||
					selectedResourcesAlunoPossuiNecessidade.includes('22') ||
					selectedResourcesAlunoPossuiNecessidade.includes('23')
				) {
					message.error(
						'Esta necessidade não permite: Guia intérprete, intérprete libras, leitura labial, prova ampliada e prova em braile.',
					);
					return prev;
				}
			}

			if (
				checkResourceConflicts(
					newResources,
					['1', '2', '3', '4', '5', '6', '9'],
					selectedResourcesAlunoPossuiNecessidade,
					'30',
					'Altas habilidades / superdotação não permite: Guia intérprete, intérprete libras, leitura labial, prova ampliada, prova em braile, auxílio transcrição e auxílio leitor.',
				)
			)
				return prev;

			return newResources;
		});
	};

	const handleResourceProfissionalApoio = (code: string) => {
		setSelectedResourcesProfissionalApoio(prev => {
			let newResources = [...prev];

			const isAtividadeDiaria = code === '1';
			const isAtividadeEscolar = code === '2';

			const requiresAtividadeDiaria = ['3', '4', '5', '6', '9'];

			if (!isAtividadeDiaria && isAtividadeEscolar) {
				formik.setFieldValue(
					'deficiencia.profissionalApoioEscolarVO.atividadeEscolar',
					false,
				);
				formik.setFieldValue(
					'deficiencia.profissionalApoioEscolarVO.dataInicioVigenciaAtividadeEscolar',
					'',
				);
				formik.setFieldValue(
					'deficiencia.profissionalApoioEscolarVO.dataFimVigenciaAtividadeEscolar',
					'',
				);
			}

			if (isAtividadeDiaria) {
				if (newResources.includes(code)) {
					if (newResources.includes('2')) {
						newResources = ['2'];
						formik.setFieldValue(
							'deficiencia.profissionalApoioEscolarVO.atividadeDiaria',
							false,
						);
						formik.setFieldValue(
							'deficiencia.profissionalApoioEscolarVO.dataInicioVigenciaAtividadeDiaria',
							'',
						);
						formik.setFieldValue(
							'deficiencia.profissionalApoioEscolarVO.dataFimVigenciaAtividadeDiaria',
							'',
						);
					} else {
						newResources = [''];
					}
				} else {
					newResources.push(code);
				}
			} else {
				if (requiresAtividadeDiaria.includes(code)) {
					const isAtividadeDiariaSelected = prev.includes('1');

					if (!isAtividadeDiariaSelected) {
						message.error(
							'Para selecionar Apoio Higiene, Apoio Locomoção, Apoio Alimentação, Apoio Banheiro e Apoio Medicamento, a Atividade Diária deve estar selecionada.',
							10,
						);
						return prev;
					}
				}

				newResources = newResources.includes(code)
					? newResources.filter(c => c !== code)
					: [...newResources, code];
			}

			dataProfissionalApoioEscolar.map(item => {
				formik.setFieldValue(item.nameFormik, false);
			});

			newResources.forEach(resource => {
				dataProfissionalApoioEscolar.forEach(item => {
					if (resource === item.key) {
						formik.setFieldValue(item.nameFormik, true);
					}
				});
			});

			return newResources;
		});
	};

	const handleTypeNivelSuporte = (e: string) => {
		formik.setFieldValue('deficiencia.codigoNivelSuporte', e);
		setCodigoNivelSuporte(e);
	};

	useEffect(() => {
		const { possuiNecessidades } = formik.values.deficiencia;
		if (possuiNecessidades === false) {
			formik.setFieldValue(
				'deficiencia.profissionalApoioEscolarVO.profissionalApoioEscolar',
				false,
			);
		}
	}, [formik.values.deficiencia.possuiNecessidades]);

	const clearApoioEscolar = () => {
		const { profissionalApoioEscolar } =
			formik.values.deficiencia.profissionalApoioEscolarVO;

		if (profissionalApoioEscolar) {
			formik.setFieldValue(
				'deficiencia.profissionalApoioEscolarVO.profissionalApoioEscolar',
				false,
			);

			dataProfissionalApoioEscolar.forEach(option => {
				formik.setFieldValue(`resource_${option.key}`, false);
			});

			setSelectedResourcesProfissionalApoio([]);

			formik.setFieldValue(
				'deficiencia.profissionalApoioEscolarVO.atividadeDiaria',
				false,
			);
			formik.setFieldValue(
				'deficiencia.profissionalApoioEscolarVO.apoioHigiene',
				false,
			);
			formik.setFieldValue(
				'deficiencia.profissionalApoioEscolarVO.apoioLocomocao',
				false,
			);
			formik.setFieldValue(
				'deficiencia.profissionalApoioEscolarVO.apoioAlimentacao',
				false,
			);
			formik.setFieldValue(
				'deficiencia.profissionalApoioEscolarVO.apoioBanheiro',
				false,
			);
			formik.setFieldValue(
				'deficiencia.profissionalApoioEscolarVO.apoioMedicamento',
				false,
			);
			formik.setFieldValue(
				'deficiencia.profissionalApoioEscolarVO.atividadeEscolar',
				false,
			);
			formik.setFieldValue(
				'deficiencia.profissionalApoioEscolarVO.dataInicioVigenciaAtividadeDiaria',
				'',
			);
			formik.setFieldValue(
				'deficiencia.profissionalApoioEscolarVO.dataFimVigenciaAtividadeDiaria',
				'',
			);
			formik.setFieldValue(
				'deficiencia.profissionalApoioEscolarVO.dataInicioVigenciaAtividadeEscolar',
				'',
			);
			formik.setFieldValue(
				'deficiencia.profissionalApoioEscolarVO.dataFimVigenciaAtividadeEscolar',
				'',
			);
		} else {
			formik.setFieldValue(
				'deficiencia.profissionalApoioEscolarVO.profissionalApoioEscolar',
				true,
			);
		}
	};

	const clearMobilidadeReduzida = () => {
		formik.setFieldValue('deficiencia.mobilidadeReduzida', false);
		formik.setFieldValue('deficiencia.tipoMobilidadeReduzida', '');
		formik.setFieldValue('deficiencia.dataInicioMobilidadeReduzida', '');
		formik.setFieldValue('deficiencia.dataFimMobilidadeReduzida', '');
		formik.setFieldValue('deficiencia.codigoNivelSuporte', '');
	};

	const clearAllForms = () => {
		const { possuiNecessidades } = formik.values.deficiencia;

		if (possuiNecessidades) {
			formik.setFieldValue('deficiencia.possuiNecessidades', false);

			dataRecursosAvancados.forEach(item => {
				formik.setFieldValue(item.nameFormik, false);
			});

			dataProfissionalApoioEscolar.forEach(item => {
				formik.setFieldValue(item.nameFormik, false);
			});

			setSelectedResourcesAlunoPossuiNecessidade([]);
			setSelectedResourcesRecursoAvaliacao([]);
			setCodigoNivelSuporte('');
			formik.setFieldValue('deficiencia.codigoNecessidade', '');
			formik.setFieldValue('recursoAvaliacao.tamanhoFonte', '');

			clearMobilidadeReduzida();

			clearApoioEscolar();
		} else {
			formik.setFieldValue('deficiencia.possuiNecessidades', true);
		}
	};

	const handleCheckboxPossuiNecessidades = () => {
		clearAllForms();
	};

	const handleCheckboxProfissionalApoioEscolar = () => {
		clearApoioEscolar();
	};

	const handleCheckboxMobilidadeReduzida = (
		event: ChangeEvent<HTMLInputElement>,
	) => {
		const { checked } = event.target;
		formik.setFieldValue('deficiencia.mobilidadeReduzida', checked);

		if (!checked) {
			formik.setFieldValue('deficiencia.tipoMobilidadeReduzida', '');
			formik.setFieldValue('deficiencia.dataInicioMobilidadeReduzida', '');
			formik.setFieldValue('deficiencia.dataFimMobilidadeReduzida', '');
		}
	};

	useEffect(() => {
		if (
			formik.values.deficiencia.codigoNecessidade !== undefined &&
			formik.values.deficiencia.codigoNecessidade !== ''
		) {
			formik.setFieldValue('deficiencia.possuiNecessidades', true);
		} else {
			formik.setFieldValue('deficiencia.possuiNecessidades', false);
		}
	}, [formik.values.deficiencia.codigoNecessidade]);

	useEffect(() => {
		const necessidadeEspeciais = selectedResourcesAlunoPossuiNecessidade.map(
			(index: string) => {
				return index;
			},
		);

		formik.setFieldValue(
			'deficiencia.codigoNecessidade',
			necessidadeEspeciais.join(';'),
		);
	}, [selectedResourcesAlunoPossuiNecessidade]);

	return (
		<>
			<FormBox title="Deficiência">
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Field
							as={CheckBox}
							subtitle="Aluno Possui Necessidade Educacional Especial"
							name="deficiencia.possuiNecessidades"
							checked={formik.values.deficiencia.possuiNecessidades}
							error={hasError(
								formik.errors,
								'deficiencia.validacaoPossuiNecessidades',
							)}
							onChange={handleCheckboxPossuiNecessidades}
						/>
					</Col>
				</Row>
				{formik.values.deficiencia.possuiNecessidades && (
					<>
						<Section title="Necessidades Especiais Disponíveis">
							{dataNecessidadeEspeciais.map(option => (
								<div key={option.key}>
									<Field name={`resource_${option.key}`}>
										{({ field }: any) => (
											<Checkbox
												{...field}
												checked={selectedResourcesAlunoPossuiNecessidade.includes(
													option.key,
												)}
												onChange={() =>
													handleResourceAlunoPossuiNecessidades(option.key)
												}
											>
												{option.description}
											</Checkbox>
										)}
									</Field>
								</div>
							))}
						</Section>
						<Section title="Recurso Avaliação">
							{dataRecursosAvancados.map(option => (
								<div key={option.key}>
									{option.key === '6' &&
									selectedResourcesRecursoAvaliacao.includes('6') ? (
										<Row>
											<Col>
												<Field name={`resource_${option.key}`}>
													{({ field }: any) => (
														<Checkbox
															{...field}
															checked={selectedResourcesRecursoAvaliacao.includes(
																option.key,
															)}
															onChange={() =>
																handleResourceRecursoAvaliacao(option.key)
															}
														>
															{option.description}
														</Checkbox>
													)}
												</Field>
											</Col>
											<Col span={8}>
												<Field
													as={Select}
													title=""
													name="recursoAvaliacao.tamanhoFonte"
													size={70}
													options={dataEnum.tamanhoFonte}
													onChange={(v: string) =>
														formik.setFieldValue(
															'recursoAvaliacao.tamanhoFonte',
															v,
														)
													}
													error={hasError(
														formik.errors,
														'recursoAvaliacao.tamanhoFonte',
													)}
												/>
											</Col>
										</Row>
									) : (
										<Field name={`resource_${option.key}`}>
											{({ field }: any) => (
												<Checkbox
													{...field}
													checked={selectedResourcesRecursoAvaliacao.includes(
														option.key,
													)}
													onChange={() =>
														handleResourceRecursoAvaliacao(option.key)
													}
												>
													{option.description}
												</Checkbox>
											)}
										</Field>
									)}
								</div>
							))}
						</Section>
						<Content>
							<Row gutter={[0, 10]}>
								<Col span={24}>
									<Field
										as={CheckBox}
										subtitle="Profissional Apoio Escolar"
										name="deficiencia.profissionalApoioEscolarVO.profissionalApoioEscolar"
										checked={
											formik.values.deficiencia.profissionalApoioEscolarVO
												.profissionalApoioEscolar
										}
										error={hasError(
											formik.errors,
											'deficiencia.profissionalApoioEscolarVO.profissionalApoioEscolar',
										)}
										onChange={handleCheckboxProfissionalApoioEscolar}
									/>
								</Col>
							</Row>
						</Content>
						{formik.values.deficiencia.profissionalApoioEscolarVO
							.profissionalApoioEscolar && (
							<Section title="Profissional Apoio Escolar Disponíveis">
								{dataProfissionalApoioEscolar.map(option => (
									<div key={option.key}>
										{option.key === '1' &&
										selectedResourcesProfissionalApoio.includes('1') ? (
											<Row>
												<Col>
													<Field name={`resource_${option.key}`}>
														{({ field }: any) => (
															<Checkbox
																{...field}
																checked={selectedResourcesProfissionalApoio.includes(
																	option.key,
																)}
																onChange={() =>
																	handleResourceProfissionalApoio(option.key)
																}
															>
																{option.description}
															</Checkbox>
														)}
													</Field>
												</Col>
												<Col span={7} offset={1}>
													<Field
														title="Data Inicío da Vigência"
														name="deficiencia.profissionalApoioEscolarVO.dataInicioVigenciaAtividadeDiaria"
														type="text"
														mask="99/99/9999"
														as={ValidDataInput}
														titleSize={130}
														error={
															hasError(
																formik.errors,
																'deficiencia.profissionalApoioEscolarVO.dataInicioVigenciaAtividadeDiaria',
															) ||
															hasError(
																formik.errors,
																'deficiencia.profissionalApoioEscolarVO.validaDatasVigenciaAtividadeDiaria',
															)
														}
														formik={formik}
													/>
												</Col>
												<Col span={7} offset={1}>
													<Field
														title="Data Fim da Vigência"
														name="deficiencia.profissionalApoioEscolarVO.dataFimVigenciaAtividadeDiaria"
														type="text"
														mask="99/99/9999"
														as={ValidDataInput}
														error={
															hasError(
																formik.errors,
																'deficiencia.profissionalApoioEscolarVO.dataFimVigenciaAtividadeDiaria',
															) ||
															hasError(
																formik.errors,
																'deficiencia.profissionalApoioEscolarVO.validaDatasVigenciaAtividadeDiaria',
															)
														}
														formik={formik}
													/>
												</Col>
											</Row>
										) : option.key === '2' &&
										  selectedResourcesProfissionalApoio.includes('2') ? (
											<Row>
												<Col>
													<Field name={`resource_${option.key}`}>
														{({ field }: any) => (
															<Checkbox
																{...field}
																checked={selectedResourcesProfissionalApoio.includes(
																	option.key,
																)}
																onChange={() =>
																	handleResourceProfissionalApoio(option.key)
																}
															>
																{option.description}
															</Checkbox>
														)}
													</Field>
												</Col>
												<Col span={7} offset={1}>
													<Field
														title="Data Inicío da Vigência"
														name="deficiencia.profissionalApoioEscolarVO.dataInicioVigenciaAtividadeEscolar"
														type="text"
														mask="99/99/9999"
														titleSize={130}
														as={ValidDataInput}
														error={hasError(
															formik.errors,
															'deficiencia.profissionalApoioEscolarVO.dataInicioVigenciaAtividadeEscolar',
														)}
														formik={formik}
													/>
												</Col>
												<Col span={7} offset={1}>
													<Field
														title="Data Fim da Vigência"
														name="deficiencia.profissionalApoioEscolarVO.dataFimVigenciaAtividadeEscolar"
														type="text"
														mask="99/99/9999"
														as={ValidDataInput}
														error={hasError(
															formik.errors,
															'deficiencia.profissionalApoioEscolarVO.dataFimVigenciaAtividadeEscolar',
														)}
														formik={formik}
													/>
												</Col>
											</Row>
										) : (
											<Field name={`resource_${option.key}`}>
												{({ field }: any) => (
													<CheckboxApoioEscolar
														{...field}
														checked={selectedResourcesProfissionalApoio.includes(
															option.key,
														)}
														onChange={() =>
															handleResourceProfissionalApoio(option.key)
														}
														disabledCheckbox={
															option.key &&
															option.key !== '1' &&
															option.key !== '2' &&
															!formik.values.deficiencia
																.profissionalApoioEscolarVO.atividadeDiaria
														}
													>
														{option.description}
													</CheckboxApoioEscolar>
												)}
											</Field>
										)}
									</div>
								))}
							</Section>
						)}
					</>
				)}
				<Row gutter={[0, 10]}>
					<Col span={7} offset={1}>
						<Field
							as={CheckBox}
							subtitle="Mobilidade Reduzida"
							name="deficiencia.mobilidadeReduzida"
							checked={!!formik.values.deficiencia.mobilidadeReduzida}
							error={hasError(formik.errors, 'deficiencia.mobilidadeReduzida')}
							onChange={handleCheckboxMobilidadeReduzida}
						/>
						{formik.values.deficiencia?.mobilidadeReduzida && (
							<Field
								as={Select}
								title=""
								name="deficiencia.tipoMobilidadeReduzida"
								options={dataEnum.acompanhamentoDeficiente}
								onChange={(v: string) =>
									formik.setFieldValue('deficiencia.tipoMobilidadeReduzida', v)
								}
								error={hasError(
									formik.errors,
									'deficiencia.tipoMobilidadeReduzida',
								)}
							/>
						)}

						{formik.values.deficiencia?.tipoMobilidadeReduzida === 'T' && (
							<Row>
								<Col>
									<Field
										title=""
										placeholder="Data Inicío"
										name="deficiencia.dataInicioMobilidadeReduzida"
										type="text"
										mask="99/99/9999"
										as={ValidDataInput}
										error={hasError(
											formik.errors,
											'deficiencia.dataInicioMobilidadeReduzida',
										)}
										formik={formik}
										onChange={(v: string) =>
											formik.setFieldValue(
												'deficiencia.dataInicioMobilidadeReduzida',
												v,
											)
										}
									/>
								</Col>
								<Col>
									<Field
										title=""
										placeholder="Data Fim"
										name="deficiencia.dataFimMobilidadeReduzida"
										type="text"
										mask="99/99/9999"
										as={ValidDataInput}
										error={hasError(
											formik.errors,
											'deficiencia.dataFimMobilidadeReduzida',
										)}
										formik={formik}
										onChange={(v: string) =>
											formik.setFieldValue(
												'deficiencia.dataFimMobilidadeReduzida',
												v,
											)
										}
									/>
								</Col>
							</Row>
						)}
						{formik.values.deficiencia?.tipoMobilidadeReduzida === 'P' && (
							<Row>
								<Col>
									<Field
										title=""
										placeholder="Data Inicío"
										name="deficiencia.dataInicioMobilidadeReduzida"
										type="text"
										mask="99/99/9999"
										as={ValidDataInput}
										error={hasError(
											formik.errors,
											'deficiencia.dataInicioMobilidadeReduzida',
										)}
										formik={formik}
										onChange={(v: string) =>
											formik.setFieldValue(
												'deficiencia.dataInicioMobilidadeReduzida',
												v,
											)
										}
									/>
								</Col>
							</Row>
						)}
					</Col>

					<Col span={7}>
						<Field
							as={CheckBox}
							subtitle="Código Nível Suporte* (Obrigatório)"
							name="deficiencia.nivelSuporte"
							checked={formik.values.deficiencia.possuiNecessidades}
							disabled
							error={hasError(formik.errors, 'deficiencia.nivelSuporte')}
						/>
						{(formik.values.deficiencia?.nivelSuporte ||
							formik.values.deficiencia.possuiNecessidades) && (
							<Col span={8}>
								<Field
									as={Radio}
									title=""
									tooltipWithIcon={enumNivelSuporte}
									name="deficiencia.codigoNivelSuporte"
									options={enumNivelSuporte}
									defaultValue={codigoNivelSuporte}
									titleQuestion
									display="vertical"
									onChange={(e: RadioChangeEvent) =>
										handleTypeNivelSuporte(e.target.value)
									}
									// directionRow
									titleSize="auto"
									error={{}}
								/>
							</Col>
						)}
					</Col>
				</Row>
			</FormBox>
		</>
	);
};

export default Deficiencia;
