import React, { useEffect } from 'react';

import { Field, FormikProps } from 'formik';
import { Row, Col } from 'antd';

import data from 'store/modules/api/iirgd/enum';

import FormBox from 'components/Common/Form/FormBox';
import Select from 'components/Common/Form/Select';
import InputMask from 'components/Common/Form/Input/InputMask';
import ValidDataInput from 'components/Common/Form/Input/ValidData';

import hasError from 'utils/getFormErrors';

import { useDispatch, useSelector } from 'react-redux';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { ApplicationState } from 'store';
import { formatDate } from 'utils/genericFunctions';
import { possuiUf, possuiDataExpedicao } from '../../PrimeiraVia/Menor/form';

interface DocProps {
	formik: FormikProps<any>;
}

const DocumentoResponsavel: React.FC<DocProps> = ({ formik }) => {
	const dispatch = useDispatch();
	const { setFieldValue } = formik;

	const getDocMasks = (tipoDoc: string) => {
		switch (tipoDoc) {
			case 'RG':
				if (formik.values.ufDocumentoResponsavel === 'SP') {
					return '99.999.999-**';
				}
				return '***************';
			case 'CNH':
				return '99999999999';
			case 'CTPS':
				return '99999999999999';
			case 'CRNM':
				return '********';
			case 'Série':
				return '9999';
			default:
				return '******************';
		}
	};

	const { alterarObservacoes, consultaPesquisaObservacao, cadastroRg } =
		useSelector((state: ApplicationState) => state.api.iirgd);

	useEffect(() => {
		if (
			alterarObservacoes.temp &&
			alterarObservacoes.temp.dataExpedicaoDocumentoResponsavel
		) {
			setFieldValue(
				'dataExpedicaoDocumentoResponsavel',
				formatDate(alterarObservacoes.temp.dataExpedicaoDocumentoResponsavel),
			);
		} else if (
			consultaPesquisaObservacao &&
			consultaPesquisaObservacao.result?.dataExpedicaoDocumentoResponsavel
		) {
			setFieldValue(
				'dataExpedicaoDocumentoResponsavel',
				formatDate(
					consultaPesquisaObservacao.result?.dataExpedicaoDocumentoResponsavel,
				),
			);
		}
	}, [alterarObservacoes.temp, consultaPesquisaObservacao, setFieldValue]);

	useEffect(() => {
		if (
			alterarObservacoes.temp &&
			alterarObservacoes.temp.tipoDocumentoResponsavel === 'CTPS' &&
			alterarObservacoes.temp.numeroDocumentoResponsavel
		) {
			const { numeroDocumentoResponsavel } = alterarObservacoes.temp;

			setFieldValue(
				'numeroDocumentoResponsavel',
				numeroDocumentoResponsavel.substring(
					0,
					numeroDocumentoResponsavel.length - 4,
				),
			);

			setFieldValue(
				'serie',
				numeroDocumentoResponsavel.substring(
					numeroDocumentoResponsavel.length - 4,
					numeroDocumentoResponsavel.length,
				),
			);
		} else if (
			consultaPesquisaObservacao &&
			consultaPesquisaObservacao.result?.tipoDocumentoResponsavel === 'CTPS' &&
			consultaPesquisaObservacao.result?.numeroDocumentoResponsavel
		) {
			const { numeroDocumentoResponsavel } = consultaPesquisaObservacao.result;

			setFieldValue(
				'numeroDocumentoResponsavel',
				numeroDocumentoResponsavel.substring(
					0,
					numeroDocumentoResponsavel.length - 4,
				),
			);
			setFieldValue(
				'serie',
				numeroDocumentoResponsavel.substring(
					numeroDocumentoResponsavel.length - 4,
					numeroDocumentoResponsavel.length,
				),
			);
		}
	}, [alterarObservacoes, consultaPesquisaObservacao, setFieldValue]);

	useEffect(() => {
		if (cadastroRg.form.principal?.cpfResponsavel) {
			setFieldValue('tipoDocumentoResponsavel', 'CPF');
			setFieldValue(
				'numeroDocumentoResponsavel',
				cadastroRg.form.principal.cpfResponsavel,
			);
		}
	}, [cadastroRg, setFieldValue, formik.values]);

	return (
		<FormBox title="Documento do Responsável">
			<Row>
				<Col span={9}>
					<Field
						as={Select}
						title="Tipo Doc."
						name="tipoDocumentoResponsavel"
						id="tipoDocumentoResponsavel"
						options={data.tipoDocs}
						onChange={(value: string) => {
							dispatch(clearNotifications());
							formik.setErrors({});
							formik.setFieldValue('tipoDocumentoResponsavel', value);
							formik.setFieldValue('numeroDocumentoResponsavel', '');
							formik.setFieldValue('dataExpedicaoDocumentoResponsavel', '');
							formik.setFieldValue('ufDocumentoResponsavel', '');
							formik.setFieldValue('serie', '');
						}}
						required
						error={hasError(formik.errors, 'docResponsavel')}
						disabled={!!cadastroRg.form.principal?.cpfResponsavel}
					/>
				</Col>

				{possuiUf(formik.values.tipoDocumentoResponsavel) &&
					possuiDataExpedicao(formik.values.tipoDocumentoResponsavel) &&
					formik.values.tipoDocumentoResponsavel && (
						<Col span={7}>
							<Field
								as={Select}
								title="UF"
								name="ufDocumentoResponsavel"
								id="ufDocumentoResponsavel"
								options={data.uf}
								onChange={(uf: string) => {
									formik.setFieldValue('ufDocumentoResponsavel', uf);
								}}
								required={
									(formik.values.tipoDocumentoResponsavel &&
										possuiUf(formik.values.tipoDocumentoResponsavel),
									possuiDataExpedicao(formik.values.tipoDocumentoResponsavel))
								}
								error={hasError(formik.errors, 'ufRespon')}
							/>
						</Col>
					)}
			</Row>

			<Row>
				{formik.values.tipoDocumentoResponsavel && (
					<Col span={9}>
						<Field
							as={InputMask}
							title="Nº Documento"
							name="numeroDocumentoResponsavel"
							maskChar=""
							mask={getDocMasks(formik.values.tipoDocumentoResponsavel)}
							disabled={
								!formik.values.tipoDocumentoResponsavel ||
								!!cadastroRg.form.principal?.cpfResponsavel
							}
							error={hasError(formik.errors, 'numDocRespon')}
						/>
					</Col>
				)}

				{formik.values.tipoDocumentoResponsavel === 'CTPS' && (
					<Col span={7}>
						<Field
							as={InputMask}
							title="Série"
							type="text"
							maskChar=""
							name="serie"
							mask="9999"
							formik={formik}
							required
							error={hasError(formik.errors, 'serieResponsavel')}
						/>
					</Col>
				)}
				{formik.values.tipoDocumentoResponsavel &&
					possuiDataExpedicao(formik.values.tipoDocumentoResponsavel) && (
						<Col span={7}>
							<Field
								titleAlign="end"
								as={ValidDataInput}
								title="Data de Expedição"
								name="dataExpedicaoDocumentoResponsavel"
								type="text"
								mask="99/99/9999"
								formik={formik}
								onChange={(v: string) =>
									formik.setFieldValue('dataExpedicaoDocumentoResponsavel', v)
								}
								required={possuiDataExpedicao(
									formik.values.tipoDocumentoResponsavel,
								)}
								error={hasError(formik.errors, 'dataExpRespon')}
							/>
						</Col>
					)}
			</Row>
		</FormBox>
	);
};

export default DocumentoResponsavel;
