import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { PATH_EDUCACAO_SEDUC_RESPONSAVEIS_API } from 'services/rotasp/path';
import { ApiResponse, getApi } from 'services/rotasp/api';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, ConsultarResponsaveisRequest } from './types';

// ACTIONS
import {
	listarResponsaveisSuccess,
	listarResponsaveisFailure,
} from './actions';

function* listarResponsaveis(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: ConsultarResponsaveisRequest } = request;
	const statusToValidate = [200, 204];

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_EDUCACAO_SEDUC_RESPONSAVEIS_API,
		`v2/responsaveis`,
		payload,
		undefined,
		undefined,
		undefined,
		undefined,
		statusToValidate,
	);

	if (
		response.status === 200 ||
		response.status === 201 ||
		response.status === 204
	) {
		yield put(listarResponsaveisSuccess({ response, request: payload }));
	} else {
		yield put(listarResponsaveisFailure({ response }));
	}
}

export default all([
	takeLatest(Types.LISTAR_RESPONSAVEIS_REQUEST, listarResponsaveis),
]);
