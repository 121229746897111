import React, { useCallback, useContext, useEffect, useState } from 'react';

// ANTD
import { Row, Col, Modal } from 'antd';

// ROUTES
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTE_IIRGD } from 'routes/paths';
import {
	ROUTE_IIRGD_PESQUISAR_RG,
	ROUTE_IIRGD_INCLUSAO_OBSERVACOES,
} from 'pages/iirgd/RgFicDigital/routes/paths';

// REDUX
import data from 'store/modules/api/iirgd/enum';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	addNotifications,
	clearNotifications,
} from 'store/modules/app/notifications/actions';
import { consultarCpfClear } from 'store/modules/api/rfb/consultarCpf/actions';
import { consultaPesquisaCriminalClear } from 'store/modules/api/iirgd/consultaPesquisaCriminal/actions';
import {
	cadastroRgOriginal,
	cadastrarRgRequest,
	atualizarRgRequest,
	cadastroRgClear,
	cadastroRgConfirmacaoAtendimento,
	cadastrarRgSuccess,
	atualizarRgSuccess,
} from 'store/modules/api/iirgd/cadastroRg/actions';
import { consultaPesquisaCidadaoClear } from 'store/modules/api/iirgd/consultaPesquisaCidadao/actions';
import {
	consultaPesquisaObservacaoRequest,
	consultaPesquisaObservacaoClear,
} from 'store/modules/api/iirgd/consultaPesquisaObservacao/actions';
import { disponibilzarDareClear } from 'store/modules/api/potencial/disponibilzarDare/actions';
import {
	dareKitRequest,
	gerarDareClear,
} from 'store/modules/api/gerarDare/actions';
import { setBreadcrumb } from 'store/modules/app/breadcrumb/actions';
import { geraSenhaClear } from 'store/modules/api/relatorios/geraSenha/actions';

// COMPONENTS COMMON
import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// UTILS
import { getFormValidationsErrors } from 'utils/getValidationsErrors';
import {
	calcularIdade,
	completaZeroEsquerda,
	formatDate,
	limparMascara,
	unformatDate,
} from 'utils/genericFunctions';
import { letrasRemoveApostrofoAcentoGraveAgudoDuploEspacoCedilhaAcentos } from 'utils/stringFunctions';
import { scrollToTopByClassName } from 'utils/scrollFunctions';

// COMPONENTS
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import { OptionProps } from 'components/Common/Form/Select';
import { Form, Formik } from 'formik';
import {
	HABILITA_CIN,
	HABILITA_MULTIFILIACAO,
} from 'store/modules/api/sgu/loginUnico/constants';
import { IParametrosLocalidade } from 'store/modules/api/sgu/loginUnico/types';
import { ConsultarProcesso } from 'store/modules/api/iirgd/integrador/consultarProcesso/types';
import {
	consultarProcessoClear,
	consultarProcessoRequest,
} from 'store/modules/api/iirgd/integrador/consultarProcesso/actions';
import { estatisticaIncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/actions';
import { IncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/types';
import {
	consultarCpfCinClear,
	consultarCpfCinRequest,
} from 'store/modules/api/iirgd/integrador/consultarCin/actions';
import { CHANNEL } from 'services/_path';
import { getApi } from 'services/_sgu';
import { capturarBiometriaClearLeitura } from 'store/modules/api/biometria/capturarBiometria/actions';
import { completarCPF } from 'utils/numberFunctions';
import Principal from './Sections/Principal';
import DocumentoApresentado from './Sections/DocumentoApresentado';
import DadosAdicionaisCidadao from './Sections/DadosAdicionais/Cidadao';
import DadosAdicionaisTrabalho from './Sections/DadosAdicionais/Trabalho';
import DadosAdicionaisContato from './Sections/DadosAdicionais/Contato';
import DadosAdicionaisRG from './Sections/DadosAdicionais/RG';
import InfosAdicionais from './Sections/InfosAdicionais';
import EntregaRG from './Sections/EntregaRG';

// FORM
import {
	initialValues as initialValuesForm,
	schema,
	IFormCadastroRg,
} from './form';

// MODAL
import SolicitarInscricaoCPF from './Modal/SolicitarInscricaoCPF';
import ConfirmacaoAtendimento from './Modal/ConfirmacaoAtendimento';

const CadastroRG: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();

	const { token } = useContext<IAuthContext>(AuthContext);

	const [formInitialValues, setFormInitialValues] =
		useState<IFormCadastroRg>(initialValuesForm);

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const {
		cadastroRg,
		consultaPesquisaCirg,
		consultaPesquisaCidadao,
		consultaPesquisaObservacao,
	} = useSelector((state: ApplicationState) => state.api.iirgd);

	const { primeiraVia, reusoParcial, reusoTotal, segundaVia } = useSelector(
		(state: ApplicationState) => state.api.iirgd.cadastroRg.tipo,
	);

	const { notifications } = useSelector((state: ApplicationState) => state.app);

	const cpfStatus = useSelector(
		(state: ApplicationState) => state.api.iirgd.integrador.consultarCin,
	);

	const { consultarCin, consultarProcesso } = useSelector(
		(state: ApplicationState) => state.api.iirgd.integrador,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const [checkResponsavel, setCheckResponsavel] = useState<boolean>(false);

	const [caller1, setCaller1] = useState<number>(0);
	const [caller2, setCaller2] = useState<number>(0);

	const [hasSubmit, setHasSubmit] = useState<boolean>(false);
	const [hasLoaded, setHasLoaded] = useState<boolean>(false);
	const [appTitle, setAppTitle] = useState<string>('');

	const [formValues, setFormValues] = useState<IFormCadastroRg | null>(null);

	const [modalInscricaoCpf, setModalInscricaoCpf] = useState<boolean>(false);
	const [modalAtendimento, setModalAtendimento] = useState<boolean>(false);
	const [erroCIN, setErroCIN] = useState<boolean>(false);

	const [bloqueioPortaria606, setBloqueioPortaria606] =
		useState<boolean>(false);

	const verifyLocationIsAllowedToCIN = useCallback(() => {
		let isAllowed = false;
		loginUnico.login.user.parametrosLocalidade.forEach(
			(item: IParametrosLocalidade) => {
				if (
					item.sigla.toLowerCase() === HABILITA_CIN.toLowerCase() &&
					item.valor.toLowerCase() === 'true'
				) {
					isAllowed = true;
				}
			},
		);
		return isAllowed;
	}, [loginUnico.login.user.parametrosLocalidade]);

	useEffect(() => {
		setBloqueioPortaria606(
			cadastroRg.config?.novo === false &&
				cadastroRg.config?.acervo === false &&
				cadastroRg.portaria606 === true,
		);
	}, [cadastroRg, consultaPesquisaCidadao]);

	useEffect(() => {
		dispatch(geraSenhaClear());
	}, [dispatch]);

	// Reseta o "sucesso" no envio do cadastro/atualização do RG.
	// Isso força a reenviar os dados para o serviço.
	useEffect(() => {
		if (!hasSubmit) {
			dispatch(cadastrarRgSuccess(false));
			dispatch(atualizarRgSuccess(false));

			dispatch(consultaPesquisaObservacaoClear());
			dispatch(consultarCpfCinClear());
		}
	}, [hasSubmit, dispatch]);

	useEffect(() => {
		scrollToTopByClassName('ant-breadcrumb');
	}, [dispatch]);

	// Redirecionamento para Inclusão de Observações.
	useEffect(() => {
		if (hasSubmit && cadastroRg.cadastrarRgSuccess) {
			dispatch(consultarCpfClear());
			if (
				cadastroRg.form.principal &&
				typeof cadastroRg.form.principal.entrega === 'number'
			) {
				dispatch(
					dareKitRequest({
						quantidade: cadastroRg.form.principal.entrega,
					}),
				);
			}
			history.push(ROUTE_IIRGD_INCLUSAO_OBSERVACOES);
		}
	}, [
		cadastroRg,
		dispatch,
		hasSubmit,
		history,
		login.user.cpf,
		login.user.idLocal,
		login.user.idOrgao,
		login.user.posto,
	]);

	// Pesquisa de Observação (realizada ao atualizar o RG - Segunda via).
	useEffect(() => {
		if (hasSubmit && formValues && cadastroRg.atualizarRgSuccess)
			dispatch(
				consultaPesquisaObservacaoRequest({
					numeroRg: formValues.numeroRg,
					usuario: login.mainframe.idUsuario,
					senha: login.mainframe.senhaMainFrame,
				}),
			);
	}, [
		hasSubmit,
		cadastroRg.atualizarRgSuccess,
		dispatch,
		formValues,
		login.mainframe,
	]);

	useEffect(() => {
		if (consultaPesquisaObservacao.result && cadastroRg.atualizarRgSuccess) {
			if (
				cadastroRg.form.principal &&
				typeof cadastroRg.form.principal.entrega === 'number'
			) {
				dispatch(
					dareKitRequest({ quantidade: cadastroRg.form.principal.entrega }),
				);
			}
			history.push(ROUTE_IIRGD_INCLUSAO_OBSERVACOES);
		}
	}, [
		cadastroRg.atualizarRgSuccess,
		cadastroRg.form.principal,
		consultaPesquisaObservacao,
		dispatch,
		history,
	]);

	const handleClearTypeDocumentNC = useCallback((values: IFormCadastroRg) => {
		if (values.dbas === 'NC') {
			values.dbas = '';
			values.comarca = '';
			values.cartorio = '';
			values.livro = '';
			values.folha = '';
			values.numero = '';
			values.dataDocumento = '';
		}
	}, []);

	const verifyLocationIsAllowedMultifiliacao = useCallback(() => {
		let isAllowed = false;
		loginUnico.user.parametrosSistema.forEach((item: IParametrosLocalidade) => {
			if (
				item.sigla.toLowerCase() === HABILITA_MULTIFILIACAO.toLowerCase() &&
				item.valor.toLowerCase() === 'true'
			) {
				isAllowed = true;
			}
		});
		return isAllowed;
	}, [loginUnico.user.parametrosSistema]);

	// Preenchimento do formulário (Primeira via / Segunda via).
	useEffect(() => {
		if (!hasLoaded) {
			if (cadastroRg.config?.novo) {
				setAppTitle(`Cadastrar Carteira de Identidade`);
			} else if (cadastroRg.config?.acervo) {
				setAppTitle('Cadastrar Carteira de Identidade Acervo');
			} else if (cadastroRg.config?.edicao) {
				setAppTitle('Editar Cadastro');
			} else {
				history.push(ROUTE_IIRGD);
			}

			// Primeira via do RG (Novo).
			if (
				cadastroRg.config?.novo &&
				cadastroRg.tipo.primeiraVia &&
				consultaPesquisaCidadao.form
			) {
				const {
					numeroRg,
					digitoRg,
					nome,
					nomePai,
					nomeMae,
					dataNascimento,
					codigoNaturalidade,
					municipioNaturalidade,
					naturalidadeEstrangeira,
					filiacaoTres,
					filiacaoQuatro,
					filiacaoCinco,
					filiacaoSeis,
				} = consultaPesquisaCidadao.form;
				const body: IFormCadastroRg = initialValuesForm;
				// Principal
				if (cadastroRg.config?.acervo) {
					body.numeroRg = `${numeroRg}-${digitoRg}`;
				}
				body.numeroRg = '';
				body.pidt = `${login.user.posto}-${login.user.digito_posto}`;
				body.nome = nome;
				body.nomePai = nomePai;
				body.nomeMae = nomeMae;
				body.dataNascimento = dataNascimento;
				body.codigoNaturalidade = codigoNaturalidade;
				body.municipioNaturalidade = municipioNaturalidade;
				body.multifiliacao =
					verifyLocationIsAllowedMultifiliacao() &&
					(!!filiacaoTres ||
						!!filiacaoQuatro ||
						!!filiacaoCinco ||
						!!filiacaoSeis);
				body.filiacaoTres = filiacaoTres || '';
				body.filiacaoQuatro = filiacaoQuatro || '';
				body.filiacaoCinco = filiacaoCinco || '';
				body.filiacaoSeis = filiacaoSeis || '';
				// Documento apresentado
				body.naturalidadeEstrangeira = naturalidadeEstrangeira;
				setFormInitialValues(body);
			}

			// Segunda via do RG (Acervo).
			if (
				cadastroRg.config?.acervo &&
				cadastroRg.tipo.segundaVia &&
				consultaPesquisaCidadao.form
			) {
				const {
					nome,
					nomePai,
					nomeMae,
					dataNascimento,
					codigoNaturalidade,
					municipioNaturalidade,
					naturalidadeEstrangeira,
					numeroRg,
					digitoRg,
					filiacaoTres,
					filiacaoQuatro,
					filiacaoCinco,
					filiacaoSeis,
				} = consultaPesquisaCidadao.form;
				const body: IFormCadastroRg = initialValuesForm;
				// Principal
				body.pidt = `${login.user.posto}-${login.user.digito_posto}`;
				body.nome = nome;
				body.nomePai = nomePai;
				body.nomeMae = nomeMae;
				body.dataNascimento = dataNascimento;
				body.codigoNaturalidade = codigoNaturalidade;
				body.municipioNaturalidade = municipioNaturalidade;
				body.numeroRg = `${numeroRg}-${digitoRg}`;
				body.multifiliacao =
					verifyLocationIsAllowedMultifiliacao() &&
					(!!filiacaoTres ||
						!!filiacaoQuatro ||
						!!filiacaoCinco ||
						!!filiacaoSeis);
				body.filiacaoTres = filiacaoTres || '';
				body.filiacaoQuatro = filiacaoQuatro || '';
				body.filiacaoCinco = filiacaoCinco || '';
				body.filiacaoSeis = filiacaoSeis || '';

				// Documento apresentado
				body.naturalidadeEstrangeira = naturalidadeEstrangeira;
				setFormInitialValues(body);

				if (cadastroRg.original === null) {
					const original = {
						...body,
					};
					dispatch(cadastroRgOriginal(original));
				}
			}

			// Primeira via ou Segunda via do RG (Edição).
			if (
				((cadastroRg.config?.edicao && cadastroRg.tipo.primeiraVia) ||
					cadastroRg.tipo.segundaVia) &&
				consultaPesquisaCirg.data &&
				consultaPesquisaCidadao.selecionado
			) {
				const body: IFormCadastroRg = {} as IFormCadastroRg;
				// Principal
				body.numeroRg = consultaPesquisaCirg.data.numeroRg;
				body.pidt = `${login.user.posto}-${login.user.digito_posto}`;
				body.nome = consultaPesquisaCirg.data.nome;
				body.nomeSocial = consultaPesquisaCirg.data.nomeSocial;
				body.nomePai = consultaPesquisaCirg.data.nomePai;
				body.nomeMae = consultaPesquisaCirg.data.nomeMae;
				body.sexo = consultaPesquisaCirg.data.sexo;
				body.dataNascimento = consultaPesquisaCirg.data.dataNascimento
					? formatDate(consultaPesquisaCirg.data.dataNascimento)
					: '';
				body.codigoNaturalidade = consultaPesquisaCirg.data.codigoNaturalidade;
				body.municipioNaturalidade =
					consultaPesquisaCirg.data.municipioNaturalidade;
				body.multifiliacao =
					verifyLocationIsAllowedMultifiliacao() &&
					(!!consultaPesquisaCirg.data.filiacaoTres ||
						!!consultaPesquisaCirg.data.filiacaoQuatro ||
						!!consultaPesquisaCirg.data.filiacaoCinco ||
						!!consultaPesquisaCirg.data.filiacaoSeis);
				body.filiacaoTres = consultaPesquisaCirg.data.filiacaoTres;
				body.filiacaoQuatro = consultaPesquisaCirg.data.filiacaoQuatro;
				body.filiacaoCinco = consultaPesquisaCirg.data.filiacaoCinco;
				body.filiacaoSeis = consultaPesquisaCirg.data.filiacaoSeis;
				body.cpfResponsavel = consultaPesquisaCirg.data.cpfResponsavel
					? completarCPF(consultaPesquisaCirg.data.cpfResponsavel)
					: '';
				body.tipoVinculo =
					consultaPesquisaCirg.data.tipoVinculo === '0'
						? ''
						: consultaPesquisaCirg.data.tipoVinculo;
				body.dataValidadeCNH = consultaPesquisaCirg.data.dataValidadeCNH
					? formatDate(consultaPesquisaCirg.data.dataValidadeCNH)
					: '';

				// Documento apresentado
				handleClearTypeDocumentNC(body);

				// TRATAMENTO FEITO PARA QUANDO O TIPO DOCUMENTO NÃO ESTIVER NOS ENUMS
				// ELE IRÁ DEIXAR EM BRANCO PARA QUE O OPERADOR SELECIONE OS DADOS CORRETOS
				if (
					!data.tipoDocumento.filter(
						(item: OptionProps) =>
							consultaPesquisaCirg !== null &&
							consultaPesquisaCirg.data &&
							item.value === consultaPesquisaCirg.data.dbas,
					)
				) {
					body.dbas = '';
					body.comarca = '';
					body.cartorio = '';
					body.livro = '';
					body.folha = '';
					body.numero = '';
					body.dataDocumento = '';
					body.motivoFaltaAssinatura = '1';
					body.naturalidadeEstrangeira = false;
					body.pMinLei1 = '';
					body.pMinLei2 = '';
				} else {
					body.dbas = consultaPesquisaCirg.data.dbas;
					body.comarca = consultaPesquisaCirg.data.comarca;
					body.cartorio = consultaPesquisaCirg.data.cartorio;
					body.livro = consultaPesquisaCirg.data.livro;
					body.folha = consultaPesquisaCirg.data.folha;
					body.numero = consultaPesquisaCirg.data.numero;
					body.dataDocumento = consultaPesquisaCirg.data.dataDocumento
						? formatDate(consultaPesquisaCirg.data.dataDocumento)
						: '';
					body.motivoFaltaAssinatura = consultaPesquisaCirg.data
						.motivoFaltaAssinatura
						? consultaPesquisaCirg.data.motivoFaltaAssinatura
						: '1';
					body.naturalidadeEstrangeira = !!(
						consultaPesquisaCirg.data.pMinLei1 ||
						consultaPesquisaCirg.data.pMinLei2
					);
					body.pMinLei1 = consultaPesquisaCirg.data.pMinLei1;
					body.pMinLei2 = consultaPesquisaCirg.data.pMinLei2;
				}

				// Dados adicionais - Cidadão
				body.estadoCivil = consultaPesquisaCirg.data.estadoCivil;
				body.cutis = consultaPesquisaCirg.data.cutis;
				body.cabelos = consultaPesquisaCirg.data.cabelos;
				body.olhos = consultaPesquisaCirg.data.olhos;
				body.altura = `${consultaPesquisaCirg.data.alturaM},${consultaPesquisaCirg.data.alturaCm}`;
				body.grauInstrucao = consultaPesquisaCirg.data.grauInstrucao;
				body.profissao = consultaPesquisaCirg.data.profissao;
				body.cepResidencia = consultaPesquisaCirg.data.cepResidencia;
				body.enderecoResidencia = consultaPesquisaCirg.data.enderecoResidencia;
				body.numeroResidencia = consultaPesquisaCirg.data.numeroResidencia;
				body.complementoResidencia =
					consultaPesquisaCirg.data.complementoResidencia;
				body.bairroResidencia = consultaPesquisaCirg.data.bairroResidencia;
				body.codigoMunicipioResidencia =
					consultaPesquisaCirg.data.codigoMunicipioResidencia;
				body.descMunicipioResidencia =
					consultaPesquisaCirg.data.descMunicipioResidencia;
				body.ufResidencia = consultaPesquisaCirg.data.ufResidencia;

				// Dados adicionais - Trabalho
				body.cepTrabalho = consultaPesquisaCirg.data.cepTrabalho;
				body.enderecoTrabalho = consultaPesquisaCirg.data.enderecoTrabalho;
				body.numeroTrabalho = consultaPesquisaCirg.data.numeroTrabalho;
				body.complementoTrabalho =
					consultaPesquisaCirg.data.complementoTrabalho;
				body.bairroTrabalho = consultaPesquisaCirg.data.bairroTrabalho;
				body.codigoMunicipioTrabalho =
					consultaPesquisaCirg.data.codigoMunicipioTrabalho;
				body.descMunicipioTrabalho =
					consultaPesquisaCirg.data.descMunicipioTrabalho;

				// Dados adicionais - Contato
				body.solicitacao = '1';
				body.foneResidencia = consultaPesquisaCirg.data.foneResidencia;
				body.foneComercial = consultaPesquisaCirg.data.foneComercial;
				body.celular = consultaPesquisaCirg.data.celular;
				body.operadora = consultaPesquisaCirg.data.operadora;
				body.email = consultaPesquisaCirg.data.email;

				// Informações adicionais
				body.identificadoAssina =
					consultaPesquisaCirg.data.identificadoAssina === 'S';
				body.carimboMaior65 = consultaPesquisaCirg.data.carimboMaior65 === '2';
				body.tipoSanguineo = consultaPesquisaCirg.data.tipoSanguineo;
				body.cid1 = consultaPesquisaCirg.data.cid1;
				body.cid2 = consultaPesquisaCirg.data.cid2;
				body.cid3 = consultaPesquisaCirg.data.cid3;
				body.cid4 = consultaPesquisaCirg.data.cid4;

				// Dados adicionais - RG
				body.cpf = consultaPesquisaCirg.data.cpf;
				body.naoPossuiCpf = false;
				body.pis = consultaPesquisaCirg.data.pis;
				body.pasep = consultaPesquisaCirg.data.pasep;
				let dni: string = '10977';
				const dniFromConsultaPesquisaCirg = consultaPesquisaCirg.data.dni;
				if (
					verifyLocationIsAllowedToCIN() &&
					dniFromConsultaPesquisaCirg &&
					!cadastroRg.tipo.segundaVia
				) {
					dni = dniFromConsultaPesquisaCirg;
				} else if (
					dniFromConsultaPesquisaCirg === '' &&
					!cadastroRg.tipo.segundaVia
				) {
					dni = '10977';
				} else if (cadastroRg.tipo.segundaVia) {
					dni = '';
				}
				body.dni = dni;
				body.tituloEleitor = consultaPesquisaCirg.data.tituloEleitor;
				body.ctpsNum = consultaPesquisaCirg.data.ctpsNum;
				body.ctpsSerie = consultaPesquisaCirg.data.ctpsSerie;
				body.ctpsSerieUf = consultaPesquisaCirg.data.ctpsSerieUf;
				body.certidaoMilitar =
					consultaPesquisaCirg.data.certidaoMilitar?.substring(0, 12);
				body.cnh = consultaPesquisaCirg.data.cnh;
				body.categoriaCnh = consultaPesquisaCirg.data.categoriaCnh;
				body.doador =
					calcularIdade(formatDate(consultaPesquisaCirg.data.dataNascimento)) <
					18
						? false
						: consultaPesquisaCirg.data.doador;
				body.nascSaude = consultaPesquisaCirg.data.nascSaude;
				body.identProf1 = consultaPesquisaCirg.data.identProf1;
				body.orgaoEmissor1 = consultaPesquisaCirg.data.orgaoEmissor1;
				body.identProf2 = consultaPesquisaCirg.data.identProf2;
				body.orgaoEmissor2 = consultaPesquisaCirg.data.orgaoEmissor2;
				body.identProf3 = consultaPesquisaCirg.data.identProf3;
				body.orgaoEmissor3 = consultaPesquisaCirg.data.orgaoEmissor3;

				setFormInitialValues(body);

				if (cadastroRg.original === null) {
					const original = {
						...body,
					};
					dispatch(cadastroRgOriginal(original));
				}
			}

			// Quando já existe um formulário em andamento.
			if (cadastroRg.form.principal) {
				const formData = cadastroRg.form.principal;

				const body: IFormCadastroRg = {} as IFormCadastroRg;

				// Principal
				body.numeroRg = formData.numeroRg;
				body.pidt = formData.pidt;
				body.nome = formData.nome;
				body.nomeSocial = formData.nomeSocial;
				body.nomePai = formData.nomePai;
				body.nomeMae = formData.nomeMae;
				body.sexo = formData.sexo;
				body.dataNascimento = formData.dataNascimento;
				body.codigoNaturalidade = formData.codigoNaturalidade;
				body.municipioNaturalidade = formData.municipioNaturalidade;
				body.multifiliacao =
					verifyLocationIsAllowedMultifiliacao() && formData.multifiliacao;
				body.filiacaoTres = formData.filiacaoTres;
				body.filiacaoQuatro = formData.filiacaoQuatro;
				body.filiacaoCinco = formData.filiacaoCinco;
				body.filiacaoSeis = formData.filiacaoSeis;
				body.cpfResponsavel = formData.cpfResponsavel;
				body.tipoVinculo =
					formData.tipoVinculo === '0' ? '' : formData.tipoVinculo;
				body.dataValidadeCNH = formData.dataValidadeCNH;

				// Documento apresentado
				handleClearTypeDocumentNC(body);

				body.dbas = formData.dbas;
				body.comarca = formData.comarca;
				body.cartorio = formData.cartorio;
				body.livro = formData.livro;
				body.folha = formData.folha;
				body.numero = formData.numero;
				body.dataDocumento = formData.dataDocumento;
				body.naturalidadeEstrangeira = formData.naturalidadeEstrangeira;
				body.pMinLei1 = formData.pMinLei1;
				body.pMinLei2 = formData.pMinLei2;

				// Dados adicionais - Cidadão
				body.estadoCivil = formData.estadoCivil;
				body.cutis = formData.cutis;
				body.cabelos = formData.cabelos;
				body.olhos = formData.olhos;
				body.altura = formData.altura;
				body.grauInstrucao = formData.grauInstrucao;
				body.profissao = formData.profissao;
				body.cepResidencia = formData.cepResidencia;
				body.enderecoResidencia = formData.enderecoResidencia;
				body.numeroResidencia = formData.numeroResidencia;
				body.complementoResidencia = formData.complementoResidencia;
				body.bairroResidencia = formData.bairroResidencia;
				body.codigoMunicipioResidencia = formData.codigoMunicipioResidencia;
				body.descMunicipioResidencia = formData.descMunicipioResidencia;
				body.ufResidencia = formData.ufResidencia;

				// Dados adicionais - Trabalho
				body.cepTrabalho = formData.cepTrabalho;
				body.enderecoTrabalho = formData.enderecoTrabalho;
				body.numeroTrabalho = formData.numeroTrabalho;
				body.complementoTrabalho = formData.complementoTrabalho;
				body.bairroTrabalho = formData.bairroTrabalho;
				body.codigoMunicipioTrabalho = formData.codigoMunicipioTrabalho;
				body.descMunicipioTrabalho = formData.descMunicipioTrabalho;

				// Dados adicionais - Contato
				body.solicitacao = formData.solicitacao;
				body.foneResidencia = formData.foneResidencia;
				body.foneComercial = formData.foneComercial;
				body.celular = formData.celular;
				body.operadora = formData.operadora;
				body.email = formData.email;

				// Informações adicionais
				body.identificadoAssina = formData.identificadoAssina;
				body.carimboMaior65 = formData.carimboMaior65;
				body.tipoSanguineo = formData.tipoSanguineo;
				body.cid1 = formData.cid1;
				body.cid2 = formData.cid2;
				body.cid3 = formData.cid3;
				body.cid4 = formData.cid4;
				body.motivoFaltaAssinatura = formData?.motivoFaltaAssinatura
					? formData?.motivoFaltaAssinatura
					: '1';

				// Dados adicionais - RG
				body.inscricaoCpf = formData.inscricaoCpf;
				body.cpf = formData.cpf;
				body.naoPossuiCpf = false;
				body.pis = formData.pis;
				body.pasep = formData.pasep;
				body.dni = formData.dni;
				body.tituloEleitor = formData.tituloEleitor;
				body.ctpsNum = formData.ctpsNum;
				body.ctpsSerie = formData.ctpsSerie;
				body.ctpsSerieUf = formData.ctpsSerieUf;
				body.certidaoMilitar = formData.certidaoMilitar;
				body.cnh = formData.cnh;
				body.categoriaCnh = formData.categoriaCnh;
				body.doador = formData.doador;
				body.nascSaude = formData.nascSaude;
				body.identProf1 = formData.identProf1;
				body.orgaoEmissor1 = formData.orgaoEmissor1;
				body.identProf2 = formData.identProf2;
				body.orgaoEmissor2 = formData.orgaoEmissor2;
				body.identProf3 = formData.identProf3;
				body.orgaoEmissor3 = formData.orgaoEmissor3;

				// Entrega RG
				body.entrega =
					typeof formData.entrega === 'number' ? 'KC' : formData.entrega;
				body.qtdKitFamilia =
					typeof formData.entrega === 'number' ? formData.entrega : '';
				body.data = formData.data;
				body.hora = formData.hora;
				setFormInitialValues(body);
			}

			setHasLoaded(true);
		}
	}, [
		verifyLocationIsAllowedMultifiliacao,
		hasLoaded,
		dispatch,
		history,
		login,
		consultaPesquisaCirg,
		consultaPesquisaCidadao,
		cadastroRg.config,
		cadastroRg.tipo,
		cadastroRg.original,
		cadastroRg.form.principal,
		setHasLoaded,
		handleClearTypeDocumentNC,
		verifyLocationIsAllowedToCIN,
	]);

	const handleValidate = useCallback(
		values => {
			const hasFormErrors =
				notifications.errors.length > 0 &&
				notifications.notificationOrigin === 'FORM_VALIDATE';

			if (notifications.notificationOrigin !== 'SERVICE_SGU') {
				return getFormValidationsErrors(
					values,
					schema,
					undefined,
					hasFormErrors,
				);
			}
		},
		[notifications.errors.length, notifications.notificationOrigin],
	);

	const situacaoCin = (codigoSituacao: ConsultarProcesso[]) => {
		let situacao = false;
		codigoSituacao.forEach(item => {
			if (item.codigoSituacao === 3) {
				situacao = true;
			}
		});

		return situacao;
	};

	const segundaViaCinSp = (ufEstado: ConsultarProcesso[]) => {
		let ufSp = false;
		ufEstado.forEach(item => {
			if (item.ufEmissao !== 'SP') {
				ufSp = true;
			}
		});

		return !ufSp;
	};

	const validaCin = useCallback(
		(values: IFormCadastroRg) => {
			if (values.dni === '10977' && values.naoPossuiCpf) {
				const payload: IncluirRequest = {
					descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
					tipoDocumento: 'CIN',
					numeroDocumento: formValues?.numeroRg ?? '',
					codigoLocalidade: Number(login.user.posto),
					idOrgao: login.user.idOrgao,
					cpf: login.user.cpf,
					descricaoServico: 'ATENDIMENTO_CIN',
					flagConfronto: 'N',
					idMotivo: 16,
					numeroProcesso: atendimento.salvarAtendimento.data?.idAtendimento,
					observacao: `Cidadão informmou não possui CPF`,
				};
				dispatch(estatisticaIncluirRequest(payload));
				addNotifications({
					errors: [
						'Para a solicitação de emissão da CIN é necessário informar o CPF do cidadão. Caso não informe o CPF somente será possível a emissão de um RE Estadual (RGE).',
					],
					notificationOrigin: 'SERVICE_SGU',
				});
			} else if (values.dni === '10977' && (!values.cpf || values.cpf === '')) {
				const payload: IncluirRequest = {
					descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
					tipoDocumento: 'CIN',
					numeroDocumento: formValues?.numeroRg ?? '',
					codigoLocalidade: Number(login.user.posto),
					idOrgao: login.user.idOrgao,
					cpf: login.user.cpf,
					descricaoServico: 'ATENDIMENTO_CIN',
					flagConfronto: 'N',
					idMotivo: 16,
					numeroProcesso: atendimento.salvarAtendimento.data?.idAtendimento,
					observacao: `Cidadão não informou o CPF para emitir CIN`,
				};
				dispatch(estatisticaIncluirRequest(payload));
				addNotifications({
					errors: ['O campo CPF é obrigatório para a CIN.'],
					notificationOrigin: 'SERVICE_SGU',
				});
			} else if (values.dni === '10977') {
				const payload: IncluirRequest = {
					descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
					tipoDocumento: 'CIN',
					numeroDocumento: values.cpf || values.numeroRg,
					codigoLocalidade: Number(login.user.posto),
					idOrgao: login.user.idOrgao,
					cpf: login.user.cpf,
					descricaoServico: 'ATENDIMENTO_CIN',
					flagConfronto: 'N',
					idMotivo: 16,
					numeroProcesso: atendimento.salvarAtendimento.data?.idAtendimento,
					observacao: `CIN com CPF informado: ${values.cpf}`,
				};
				dispatch(estatisticaIncluirRequest(payload));

				dispatch(consultarProcessoClear());

				dispatch(
					consultarCpfCinRequest(
						token,
						values.cpf.replace('-', '').replaceAll('.', ''),
					),
				);
			}
		},
		[
			atendimento.salvarAtendimento.data,
			dispatch,
			formValues,
			login.user,
			token,
		],
	);

	const getDescricaoSituation = (codSitCad: string) => {
		switch (codSitCad) {
			case '2':
				return 'Suspensa';
			case '3':
				return 'Titular Falecido';
			case '5':
				return 'Cancelada por Multiplicidade';
			case '8':
				return 'Nula';
			case '9':
				return 'Cancelada de Ofício';
			default:
				return 'Descrição desconhecida';
		}
	};

	const submitForm = useCallback(
		async (values: IFormCadastroRg) => {
			values.multifiliacao =
				verifyLocationIsAllowedMultifiliacao() && values.multifiliacao;
			values.cnh = completaZeroEsquerda(values.cnh, 11);
			values.tituloEleitor = completaZeroEsquerda(values.tituloEleitor, 12);
			values.filiacaoTres = verifyLocationIsAllowedMultifiliacao()
				? values.filiacaoTres.toUpperCase()
				: '';
			values.filiacaoQuatro = verifyLocationIsAllowedMultifiliacao()
				? values.filiacaoQuatro.toUpperCase()
				: '';
			values.filiacaoCinco = verifyLocationIsAllowedMultifiliacao()
				? values.filiacaoCinco.toUpperCase()
				: '';
			values.filiacaoSeis = verifyLocationIsAllowedMultifiliacao()
				? values.filiacaoSeis.toUpperCase()
				: '';
			values.doador = values.doador === true;

			if (
				values.cpfResponsavel &&
				values.cpfResponsavel !== '' &&
				!checkResponsavel
			) {
				const cpfResponsavel = limparMascara(values.cpfResponsavel);
				dispatch(consultarCpfCinRequest(token, cpfResponsavel, true));
				setCheckResponsavel(true);
				return;
			}

			if (
				(cadastroRg.config?.edicao &&
					cadastroRg.original &&
					!cadastroRg.config?.acervo) ||
				(values.numeroRg.length > 1 && !cadastroRg.config?.acervo)
			) {
				if (values.entrega === 'KC') {
					dispatch(
						atualizarRgRequest(
							cadastroRg.original,
							{ ...values, entrega: Number(values.qtdKitFamilia) },
							login,
							cadastroRg.tipo.primeiraVia,
							consultaPesquisaCidadao.selecionado,
						),
					);
					setHasSubmit(true);
					return;
				}
				dispatch(
					atualizarRgRequest(
						cadastroRg.original,
						values,
						login,
						cadastroRg.tipo.primeiraVia,
						consultaPesquisaCidadao.selecionado,
					),
				);
				setHasSubmit(true);
				return;
			}

			values.cpf = limparMascara(values.cpf);
			values.tituloEleitor = completaZeroEsquerda(values.tituloEleitor, 12);

			// Montar lógica para sinalizar quando o RG for Acervo.
			if (cadastroRg.config?.acervo) {
				values.argumento = 'acervo';
			}
			if (values.entrega === 'KC') {
				if (
					cadastroRg.cadastrarRgFailure &&
					cadastroRg.config?.acervo &&
					cadastroRg.numRg
				) {
					dispatch(
						atualizarRgRequest(
							cadastroRg.original,
							values,
							login,
							cadastroRg.tipo.primeiraVia,
							consultaPesquisaCidadao.selecionado,
						),
					);
					setHasSubmit(true);
					return;
				}
				dispatch(
					cadastrarRgRequest(
						{ ...values, entrega: Number(values.qtdKitFamilia) },
						login,
					),
				);
				setHasSubmit(true);
				return;
			}
			if (
				cadastroRg.cadastrarRgFailure &&
				cadastroRg.config?.acervo &&
				cadastroRg.numRg
			) {
				dispatch(
					atualizarRgRequest(
						cadastroRg.original,
						values,
						login,
						cadastroRg.tipo.primeiraVia,
						consultaPesquisaCidadao.selecionado,
					),
				);
				setHasSubmit(true);
				return;
			}
			dispatch(cadastrarRgRequest(values, login));
			setHasSubmit(true);
		},
		[
			verifyLocationIsAllowedMultifiliacao,
			cadastroRg.config,
			cadastroRg.original,
			cadastroRg.cadastrarRgFailure,
			cadastroRg.numRg,
			cadastroRg.tipo.primeiraVia,
			dispatch,
			login,
			consultaPesquisaCidadao.selecionado,
			token,
			checkResponsavel,
		],
	);

	useEffect(() => {
		if (checkResponsavel && cpfStatus.data?.codSitCad) {
			const codSitCad = cpfStatus.data?.codSitCad || '';

			if (codSitCad !== '0' && codSitCad !== '4') {
				const descricaoSituacao = getDescricaoSituation(codSitCad);

				addNotifications({
					errors: [
						`A inclusão do CPF do Responsável e vínculo do responsável somente será permitida se a situação do CPF do responsável na RFB for igual a “0-REGULAR” e “4-PENDENTE DE REGULARIZAÇÃO”. A situação do CPF informado é: ${codSitCad} - ${descricaoSituacao}`,
					],
				});
				setCheckResponsavel(false);
			} else {
				formValues && submitForm(formValues);
				setCheckResponsavel(false);
			}
		}
	}, [checkResponsavel, cpfStatus.data, formValues, submitForm]);

	useEffect(() => {
		if (
			consultarProcesso.status === 200 &&
			consultarProcesso.data &&
			!situacaoCin(consultarProcesso.data) &&
			segundaViaCinSp(consultarProcesso.data) &&
			formValues
		) {
			submitForm(formValues);
		} else if (
			consultarProcesso.status === 200 &&
			consultarProcesso.data &&
			situacaoCin(consultarProcesso.data) &&
			segundaViaCinSp(consultarProcesso.data) &&
			formValues
		) {
			submitForm(formValues);
		} else if (
			consultarProcesso.status === 200 &&
			consultarProcesso.data &&
			!segundaViaCinSp(consultarProcesso.data)
		) {
			addNotifications({
				errors: [
					'Emissão da CIN não poderá ser realizada neste momento no estado de São Paulo. CPF está em atendimento CIN em outro estado da federação - UF. Para prosseguir, selecione a opção RG Estadual no campo DOCUMENTO A EMITIR.',
				],
				notificationOrigin: 'SERVICE_SGU',
			});
			setErroCIN(true);
		} else if (
			consultarProcesso.status !== 0 &&
			consultarProcesso.status !== 200
		) {
			if (caller1 > 3) {
				addNotifications({
					errors: [
						'Problemas na conexão com Receita Federal. Tente Novamente.',
					],
					notificationOrigin: 'SERVICE_SGU',
				});
			} else {
				setCaller1(caller1 + 1);
				dispatch(
					consultarProcessoRequest(token, {
						cpfCidadao:
							formValues?.cpf.replace('-', '').replaceAll('.', '') || '',
					}),
				);
			}
		} else if (
			consultarProcesso.status !== 0 &&
			cpfStatus.data &&
			cpfStatus.data.cpfId === limparMascara(formValues?.cpf)
		) {
			setErroCIN(true);
			addNotifications({
				// errors: [
				// 	'Emissão da CIN não poderá ser realizada neste momento no estado de São Paulo. Dados informados divergem dos dados cadastrados na Receita Federal. Para prosseguir, selecione a opção RG Estadual no campo DOCUMENTO A EMITIR.',
				// ],
				errors: [
					'Emissão da CIN não poderá ser realizada neste momento no estado de São Paulo. Dados informados divergem dos dados cadastrados na Receita Federal.',
				],
				notificationOrigin: 'SERVICE_SGU',
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [consultarProcesso.data, consultarProcesso.status]);

	useEffect(() => {
		if (
			consultarProcesso.status !== 200 &&
			consultarCin.status === 200 &&
			consultarCin.data &&
			formValues &&
			formValues.dni === '10977' &&
			consultarCin.data.cpfId === limparMascara(formValues.cpf)
		) {
			const nameValidation =
				letrasRemoveApostrofoAcentoGraveAgudoDuploEspacoCedilhaAcentos(
					consultarCin.data.nomeContribuinte,
				)
					.toUpperCase()
					.trim() ===
				letrasRemoveApostrofoAcentoGraveAgudoDuploEspacoCedilhaAcentos(
					formValues?.nome || '',
				)
					.toUpperCase()
					.trim();

			let maeCIN =
				letrasRemoveApostrofoAcentoGraveAgudoDuploEspacoCedilhaAcentos(
					consultarCin.data.nomeMae,
				)
					.toUpperCase()
					.trim();

			if (maeCIN === 'MAE DESCONHECIDA' || maeCIN === 'NC' || maeCIN === '') {
				maeCIN = 'NC';
			}

			const motherNameValidation =
				maeCIN ===
				letrasRemoveApostrofoAcentoGraveAgudoDuploEspacoCedilhaAcentos(
					formValues?.nomeMae || '',
				)
					.toUpperCase()
					.trim();

			const birthDayValidation =
				`${consultarCin.data.dtNasc.substring(
					0,
					4,
				)}-${consultarCin.data.dtNasc.substring(
					4,
					6,
				)}-${consultarCin.data.dtNasc.substring(6, 8)}`.replaceAll(
					/\s\s+/g,
					' ',
				) === unformatDate(formValues?.dataNascimento).trim();

			if (
				nameValidation &&
				motherNameValidation &&
				birthDayValidation &&
				(consultarCin.data.codSitCad === '0' ||
					consultarCin.data.codSitCad === '4')
			) {
				dispatch(
					consultarProcessoRequest(token, {
						cpfCidadao:
							formValues?.cpf.replace('-', '').replaceAll('.', '') || '',
					}),
				);
			} else if (
				consultarCin.data.codSitCad !== '0' &&
				consultarCin.data.codSitCad !== '4' &&
				!consultarCin.cpfResponsavel
			) {
				const descricaoSituacao = getDescricaoSituation(
					consultarCin.data.codSitCad,
				);

				addNotifications({
					errors: [
						`A solicitação da CIN somente será permitida se a situação do CPF do cidadão na RFB for igual a “0-REGULAR” e “4-PENDENTE DE REGULARIZAÇÃO”. A situação do CPF informado é: ${consultarCin.data.codSitCad} - ${descricaoSituacao}`,
					],
				});
			} else {
				const payload: IncluirRequest = {
					descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
					tipoDocumento: 'RG',
					numeroDocumento: formValues?.numeroRg ?? '',
					codigoLocalidade: Number(login.user.posto),
					idOrgao: login.user.idOrgao,
					cpf: login.user.cpf,
					descricaoServico: 'ATENDIMENTO_CIN',
					flagConfronto: 'N',
					idMotivo: 16,
					numeroProcesso: atendimento.salvarAtendimento.data?.idAtendimento,
					observacao: `Nome cidadão: ${
						nameValidation
							? 'OK'
							: `NOK - RFB: ${letrasRemoveApostrofoAcentoGraveAgudoDuploEspacoCedilhaAcentos(
									consultarCin.data.nomeContribuinte,
							  )
									.toUpperCase()
									.trim()} Estadual: ${letrasRemoveApostrofoAcentoGraveAgudoDuploEspacoCedilhaAcentos(
									formValues?.nome || '',
							  )
									.toUpperCase()
									.trim()}`
					}, Filiação 2: ${
						motherNameValidation
							? 'OK'
							: `NOK - RFB: ${letrasRemoveApostrofoAcentoGraveAgudoDuploEspacoCedilhaAcentos(
									consultarCin.data.nomeMae,
							  )
									.toUpperCase()
									.trim()} Estadual: ${letrasRemoveApostrofoAcentoGraveAgudoDuploEspacoCedilhaAcentos(
									formValues?.nomeMae || '',
							  )
									.toUpperCase()
									.trim()}`
					}, Data nascimento: ${
						birthDayValidation
							? 'OK'
							: `NOK - RFB: ${consultarCin.data.dtNasc.substring(
									0,
									4,
							  )}-${consultarCin.data.dtNasc.substring(
									4,
									6,
							  )}-${consultarCin.data.dtNasc
									.substring(6, 8)
									.trim()} Estadual: ${unformatDate(
									formValues?.dataNascimento || '',
							  ).trim()}`
					}, Situação cadastral: ${consultarCin.data.codSitCad}`,
				};
				dispatch(estatisticaIncluirRequest(payload));
				// setErroCIN(true);
				const message = `Constam divergências com a base da Receita Federal: Nome cidadão: ${
					nameValidation
						? 'OK'
						: `NOK - RFB: ${letrasRemoveApostrofoAcentoGraveAgudoDuploEspacoCedilhaAcentos(
								consultarCin.data.nomeContribuinte,
						  )
								.toUpperCase()
								.trim()} Estadual: ${letrasRemoveApostrofoAcentoGraveAgudoDuploEspacoCedilhaAcentos(
								formValues?.nome || '',
						  )
								.toUpperCase()
								.trim()}`
				}, Filiação 2: ${
					motherNameValidation
						? 'OK'
						: `NOK - RFB: ${letrasRemoveApostrofoAcentoGraveAgudoDuploEspacoCedilhaAcentos(
								consultarCin.data.nomeMae,
						  )
								.toUpperCase()
								.trim()} Estadual: ${letrasRemoveApostrofoAcentoGraveAgudoDuploEspacoCedilhaAcentos(
								formValues?.nomeMae || '',
						  )
								.toUpperCase()
								.trim()}`
				}, Data nascimento: ${
					birthDayValidation
						? 'OK'
						: `NOK - RFB: ${consultarCin.data.dtNasc.substring(
								0,
								4,
						  )}-${consultarCin.data.dtNasc.substring(
								4,
								6,
						  )}-${consultarCin.data.dtNasc
								.substring(6, 8)
								.trim()} Estadual: ${unformatDate(
								formValues?.dataNascimento || '',
						  ).trim()}`
				}. Confira os dados com a certidão! Deseja continuar o atendimento?`;

				const dadosRfb = `Nome: ${letrasRemoveApostrofoAcentoGraveAgudoDuploEspacoCedilhaAcentos(
					consultarCin.data.nomeContribuinte,
				)
					.toUpperCase()
					.trim()} - Data nascimento: ${consultarCin.data.dtNasc.substring(
					6,
					8,
				)}
					/${consultarCin.data.dtNasc.substring(4, 6)}/${consultarCin.data.dtNasc
					.substring(0, 4)
					.trim()} Filiação 2: ${letrasRemoveApostrofoAcentoGraveAgudoDuploEspacoCedilhaAcentos(
					consultarCin.data.nomeMae,
				)
					.toUpperCase()
					.trim()}`;

				Modal.confirm({
					title: 'Atenção',
					content: message,
					cancelText: 'Não',
					okText: 'Sim',
					onOk() {
						formValues.dadosRfb = dadosRfb;
						formValues.divergenciaRfb = true;
						formValues.dadosRfb = dadosRfb;
						formValues.divergenciaRfb = true;
						submitForm(formValues);
					},
					onCancel() {},
				});
			}
		} else if (consultarCin.status === 204 && !consultarCin.cpfResponsavel) {
			const cpfValue =
				formValues?.cpf.replace('-', '').replaceAll('.', '') || '';
			addNotifications({
				errors: [`CPF ${cpfValue} não cadastrado`],
				notificationOrigin: 'SERVICE_SGU',
			});
		} else if (consultarCin.status === 204 && consultarCin.cpfResponsavel) {
			setCheckResponsavel(false);
			const cpfValue =
				formValues?.cpfResponsavel.replace('-', '').replaceAll('.', '') || '';
			addNotifications({
				errors: [`CPF Responsável ${cpfValue} não cadastrado`],
				notificationOrigin: 'SERVICE_SGU',
			});
		} else if (consultarCin.status === 400 && !consultarCin.cpfResponsavel) {
			if (caller2 > 3) {
				const payload: IncluirRequest = {
					descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
					tipoDocumento: 'RG',
					numeroDocumento: formValues?.numeroRg ?? '',
					codigoLocalidade: Number(login.user.posto),
					idOrgao: login.user.idOrgao,
					cpf: login.user.cpf,
					descricaoServico: 'ATENDIMENTO_CIN',
					flagConfronto: 'N',
					idMotivo: 16,
					numeroProcesso: atendimento.salvarAtendimento.data?.idAtendimento,
					observacao: `${
						consultarCin.dataError?.mensagem || 'Erro no servico consultarCin'
					}`,
				};
				dispatch(estatisticaIncluirRequest(payload));
				addNotifications({
					errors: [
						'Problemas na conexão com Receita Federal. Tente Novamente.',
					],
					notificationOrigin: 'SERVICE_SGU',
				});
			} else if (formValues?.cpf) {
				setCaller2(caller2 + 1);
				dispatch(
					consultarCpfCinRequest(
						token,
						formValues.cpf.replace('-', '').replaceAll('.', ''),
					),
				);
			}
		} else if (
			consultarCin.status !== 0 &&
			consultarCin.data &&
			formValues &&
			consultarCin.data.cpfId === limparMascara(formValues.cpf)
		) {
			const payload: IncluirRequest = {
				descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
				tipoDocumento: 'RG',
				numeroDocumento: formValues?.numeroRg ?? '',
				codigoLocalidade: Number(login.user.posto),
				idOrgao: login.user.idOrgao,
				cpf: login.user.cpf,
				descricaoServico: 'ATENDIMENTO_CIN',
				flagConfronto: 'N',
				idMotivo: 16,
				numeroProcesso: atendimento.salvarAtendimento.data?.idAtendimento,
				observacao: `Erro no servico consultarCin`,
			};
			dispatch(estatisticaIncluirRequest(payload));
			setErroCIN(true);
			addNotifications({
				errors: [
					'Emissão da CIN não poderá ser realizada neste momento no Estado de São Paulo. Constam divergências com a base da Receita Federal. Para prosseguir, selecione a opção RG Estadual no campo DOCUMENTO A EMITIR.',
				],
				notificationOrigin: 'SERVICE_SGU',
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		consultarCin.data,
		consultarCin.dataError,
		consultarCin.status,
		dispatch,
	]);

	const handleConfirmacoesModal = useCallback(
		(values: IFormCadastroRg) => {
			const inscricaoCpf = login.user.parametrosLocalidade.find(
				parametro => parametro.sigla === 'INSCRICAO_CPF',
			);

			const inscricaoCpfSegundaVia = login.user.parametrosLocalidade.find(
				parametro => parametro.sigla === 'INSCRICAO_CPF_SEG_VIA',
			);

			if (
				((primeiraVia && inscricaoCpf && inscricaoCpf.valor === 'TRUE') ||
					(segundaVia &&
						inscricaoCpfSegundaVia &&
						inscricaoCpfSegundaVia.valor === 'TRUE')) &&
				values.inscricaoCpf === '' &&
				values.cpf.length === 0
			) {
				setModalInscricaoCpf(true);
				return;
			}

			if (
				cadastroRg.tipo.segundaVia &&
				!cadastroRg.config?.acervo &&
				consultaPesquisaCidadao.selecionado?.afis === '*' &&
				values.dni !== '10977'
			) {
				setModalAtendimento(true);
				return;
			}

			values.dni === '10977' ? validaCin(values) : submitForm(values);
		},
		[
			cadastroRg.config,
			cadastroRg.tipo.segundaVia,
			consultaPesquisaCidadao.selecionado,
			login.user.parametrosLocalidade,
			primeiraVia,
			segundaVia,
			submitForm,
			validaCin,
		],
	);

	const buscarMunicipioTrabalho = useCallback(
		async (values: IFormCadastroRg) => {
			const id = limparMascara(values.codigoMunicipioTrabalho).slice(0, 5);
			if (id !== '') {
				const response: any = await getApi(
					`domain-service/${CHANNEL}/naturalidade`,
					{
						id,
					},
				);

				if (response.status === 200) {
					const naturalidade = response.data[0];

					if (
						!naturalidade.municipioIBGE ||
						naturalidade.municipioIBGE.id === 0
					) {
						addNotifications({
							errors: [
								'Município de trabalho do cidadão Inválido - Município não registrado no IBGE. Consulte o Supervisor!',
							],
						});

						return;
					}
					setFormValues(values);
					handleConfirmacoesModal(values);
				} else {
					addNotifications({
						errors: [
							'Serviço indisponível ou erro de conexão. Entre em contato com o suporte.',
						],
					});
				}
			}
		},
		[handleConfirmacoesModal],
	);

	const buscarMunicipioResidencia = useCallback(
		async (values: IFormCadastroRg) => {
			const id = limparMascara(values.codigoMunicipioResidencia).slice(0, 5);
			if (id !== '') {
				const response: any = await getApi(
					`domain-service/${CHANNEL}/naturalidade`,
					{
						id,
					},
				);

				if (response.status === 200) {
					const naturalidade = response.data[0];

					if (
						!naturalidade.municipioIBGE ||
						naturalidade.municipioIBGE.id === 0
					) {
						addNotifications({
							errors: [
								'Município de residência do cidadão Inválido - Município não registrado no IBGE. Consulte o Supervisor!',
							],
						});

						return;
					}
					if (values.codigoMunicipioTrabalho !== '') {
						buscarMunicipioTrabalho(values);
					} else {
						setFormValues(values);
						handleConfirmacoesModal(values);
					}
				} else {
					addNotifications({
						errors: [
							'Serviço indisponível ou erro de conexão. Entre em contato com o suporte.',
						],
					});
				}
			}
		},
		[buscarMunicipioTrabalho, handleConfirmacoesModal],
	);

	const buscarNaturalidade = useCallback(
		async (values: IFormCadastroRg) => {
			const id = limparMascara(values.codigoNaturalidade).slice(0, 5);
			if (id !== '') {
				const response: any = await getApi(
					`domain-service/${CHANNEL}/naturalidade`,
					{
						id,
					},
				);

				if (response.status === 200) {
					const naturalidade = response.data[0];

					if (
						(values.codigoNaturalidade.slice(0, 7) !== '99000-2' ||
							values.codigoNaturalidade.slice(0, 5) !== '99000' ||
							values.dni === '10977') &&
						(!naturalidade.municipioIBGE ||
							naturalidade.municipioIBGE.id === 0) &&
						!(
							values.dbas === 'NL' ||
							values.dbas === 'NZ' ||
							values.dbas === 'TI' ||
							values.dbas === 'TN' ||
							(values.dbas === 'CN' &&
								values.livro[0] &&
								values.livro[0].toUpperCase() === 'K') ||
							(values.dbas === 'CN' &&
								values.livro[0] &&
								values.livro[0].toUpperCase() === 'Y') ||
							(values.dbas === 'CN' &&
								values.livro[0] &&
								values.livro[0].toUpperCase() === 'X') ||
							(values.dbas === 'CN' &&
								values.livro[0] &&
								values.livro[0].toUpperCase() === 'E') ||
							(values.dbas === 'CC' &&
								values.livro[0] &&
								values.livro[0].toUpperCase() === 'T') ||
							(values.dbas === 'CC' &&
								values.livro[0] &&
								values.livro[0].toUpperCase() === 'W')
						) &&
						values.dni === '10977'
					) {
						addNotifications({
							errors: [
								'Naturalidade do cidadão Inválida - Município não registrado no IBGE. Consulte o Supervisor!',
							],
						});

						return;
					}
					buscarMunicipioResidencia(values);
				} else {
					addNotifications({
						errors: [
							'Serviço indisponível ou erro de conexão. Entre em contato com o suporte.',
						],
					});
				}
			}
		},
		[buscarMunicipioResidencia],
	);

	const handleSubmit = useCallback(
		(values: IFormCadastroRg) => {
			if (values.nomeMae === values.nome) {
				Modal.confirm({
					title:
						'A filiação 2 foi cadastrada idêntica ao nome do(a) cidadão(ã). Confirma que o cadastro está correto?',
					okText: 'Sim',
					cancelText: 'Não',
					onOk() {
						buscarNaturalidade(values);
					},
					onCancel() {},
				});
			} else if (values.nomePai === values.nome) {
				Modal.confirm({
					title:
						'A filiação 1 foi cadastrada idêntica ao nome do(a) cidadão(ã). Confirma que o cadastro está correto?',
					okText: 'Sim',
					cancelText: 'Não',
					onOk() {
						buscarNaturalidade(values);
					},
					onCancel() {},
				});
			} else {
				buscarNaturalidade(values);
			}
		},
		[buscarNaturalidade],
	);

	const handleButtonVoltar = useCallback(() => {
		dispatch(clearNotifications());
		dispatch(cadastroRgClear());
		dispatch(consultarCpfCinClear());
		dispatch(capturarBiometriaClearLeitura());
		// dispatch(consultaPesquisaCirgClear());
		dispatch(consultaPesquisaCidadaoClear());
		dispatch(consultaPesquisaCriminalClear());
		dispatch(consultaPesquisaObservacaoClear());
		dispatch(gerarDareClear());
		dispatch(disponibilzarDareClear());
		dispatch(consultarCpfClear());

		history.push(ROUTE_IIRGD_PESQUISAR_RG);
	}, [dispatch, history]);

	useEffect(() => {
		setTimeout(() => {
			if (location.pathname === '/iirgd/rg-fic-digital/cadastro') {
				if (
					primeiraVia ||
					(!primeiraVia && !reusoParcial && !reusoTotal && !segundaVia)
				) {
					dispatch(
						setBreadcrumb(
							[
								{ name: 'IIRGD', route: '/iirgd' },
								{ name: 'Cadastrar Carteira de Identidade 1ª via OBS' },
							],
							true,
						),
					);
				} else {
					dispatch(
						setBreadcrumb(
							[
								{ name: 'IIRGD', route: '/iirgd' },
								{ name: 'Cadastrar Carteira de Identidade' },
							],
							true,
						),
					);
				}
			}
		}, 100);
	}, [
		dispatch,
		location.pathname,
		primeiraVia,
		reusoParcial,
		reusoTotal,
		segundaVia,
	]);

	return (
		<>
			<Formik
				validateOnChange={false}
				validateOnBlur={notifications.errors.length > 0}
				initialValues={formInitialValues}
				validate={handleValidate}
				onSubmit={handleSubmit}
				enableReinitialize
			>
				{formik => (
					<Form autoComplete="off">
						{hasLoaded && (
							<>
								<Section title={appTitle}>
									<Principal
										formik={formik}
										bloqueioPortaria606={bloqueioPortaria606}
										erroCIN={erroCIN}
									/>

									<DocumentoApresentado
										formik={formik}
										bloqueioPortaria606={bloqueioPortaria606}
									/>

									<DadosAdicionaisCidadao
										formik={formik}
										bloqueioPortaria606={bloqueioPortaria606}
									/>

									<DadosAdicionaisTrabalho formik={formik} />

									<DadosAdicionaisContato formik={formik} />

									<InfosAdicionais formik={formik} />

									{/* <ConsultarCpf formikParent={formik} /> */}

									<DadosAdicionaisRG formik={formik} />

									<EntregaRG formik={formik} />

									<Row gutter={[0, 10]} justify="center">
										<Col>
											<ButtonImage
												type="submit"
												src="avancar"
												onClick={() => {
													dispatch(clearNotifications());
												}}
												disabled={
													cadastroRg.atualizarRgSuccess ||
													cadastroRg.cadastrarRgSuccess
												}
											/>
										</Col>
									</Row>
								</Section>

								<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
							</>
						)}
					</Form>
				)}
			</Formik>

			<SolicitarInscricaoCPF
				showModal={modalInscricaoCpf}
				onOk={() => {
					setModalInscricaoCpf(false);

					if (formValues !== null) {
						const returnValue = {
							...formValues,
							inscricaoCpf: 'false',
						};
						returnValue.dni === '10977'
							? validaCin(returnValue)
							: submitForm(returnValue);

						return;
					}

					if (!cadastroRg.config?.acervo) {
						setModalAtendimento(true);
					}
				}}
				onCancel={() => {
					setModalInscricaoCpf(false);
				}}
			/>

			<ConfirmacaoAtendimento
				showModal={modalAtendimento}
				response={res => {
					dispatch(cadastroRgConfirmacaoAtendimento(res));
					setModalAtendimento(false);

					if (formValues) {
						formValues.dni === '10977'
							? validaCin(formValues)
							: submitForm(formValues);
					}
				}}
			/>
		</>
	);
};

export default CadastroRG;
