export enum Types {
	CONSULTAR_USUARIO_REQUEST = '@SGU/CONSULTAR_USUARIO_REQUEST',
	CONSULTAR_USUARIO_SUCCESS = '@SGU/CONSULTAR_USUARIO_SUCCESS',
	CONSULTAR_USUARIO_FAILURE = '@SGU/CONSULTAR_USUARIO_FAILURE',
	CONSULTAR_USUARIO_CLEAR = '@SGU/CONSULTAR_USUARIO_CLEAR',
}

export interface ConsultarUsuario {
	status: number;
	data?: ConsultarUsuarioResponse | null;
}

interface Usuario {
	id: number;
	nome: string;
	cpf: string;
	status: {
		codigo: number;
		descricao: string;
	};
	perfil: {
		codigo: number;
		descricao: string;
	};
}

export interface ConsultarUsuarioRequest {
	id: number;
}

export interface ConsultarUsuarioResponse {
	id: number;
	nome: string;
	email: string;
	endereco: string;
	cpf: string;
	rg: string;
	digitoRg: string;
	uf: string;
	dddCelular: number;
	telCelular: string;
	dddComercial: number;
	telComercial: string;
	dddResidencial: number;
	telResidencial: string;
	isPossuiUsuarioMainframe: true;
	status: {
		id: number;
		descricao: string;
	};
	perfil: {
		id: number;
		descricao: string;
		// codigoStatus: number;
		status: {
			id: number;
			descricao: string;
		};
		acessaSgu: true;
	};
	// ativo: true;
	localidadesUsuario: [
		{
			idLocalidadeUsuario: number;
			dataCadastro: string;
			idUsuarioCadastro: number;
			localidade: {
				id: number;
				codigo: number;
				digito: number;
				nome: string;
				tipo: string;
				orgao: { id: number; descricao: string };
				usuariosMainframe?: {
					dataCadastro: string;
					idUsuarioMainframe: string;
				}[];
			};
		},
	];
}
