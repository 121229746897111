import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, ConsultarResponsaveisRequest } from './types';

export function listarResponsaveisRequest(
	token: string,
	data: ConsultarResponsaveisRequest,
): ReducerActionRotaSP {
	return {
		type: Types.LISTAR_RESPONSAVEIS_REQUEST,
		token,
		payload: data,
	};
}

export function listarResponsaveisSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.LISTAR_RESPONSAVEIS_SUCCESS,
		payload,
	};
}

export function listarResponsaveisFailure(data: any): ReducerActionRotaSP {
	return {
		type: Types.LISTAR_RESPONSAVEIS_FAILURE,
		payload: data,
	};
}

export function listarResponsaveisClean() {
	return {
		type: Types.LISTAR_RESPONSAVEIS_CLEAN,
		payload: null,
	};
}
