import { ReducerActionRotaSP } from 'store/modules/types';

import { ConsultarNaturalidadeRequest, Types } from './types';

export function consultarNaturalidadeRequest(
	token: string,
	payload: ConsultarNaturalidadeRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_NATURALIDADE_REQUEST,
		token,
		payload,
	};
}
export function consultarNaturalidadeSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_NATURALIDADE_SUCCESS,
		payload,
	};
}
export function consultarNaturalidadeFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_NATURALIDADE_FAILURE,
		payload: null,
	};
}
export function consultarNaturalidadeClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_NATURALIDADE_CLEAR,
		payload: null,
	};
}
