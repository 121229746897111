export enum Types {
	ATUALIZAR_USUARIO_REQUEST = '@SGU/ATUALIZAR_USUARIO_REQUEST',
	ATUALIZAR_USUARIO_SUCCESS = '@SGU/ATUALIZAR_USUARIO_SUCCESS',
	ATUALIZAR_USUARIO_FAILURE = '@SGU/ATUALIZAR_USUARIO_FAILURE',
	ATUALIZAR_USUARIO_CLEAR = '@SGU/ATUALIZAR_USUARIO_CLEAR',
}

export interface AtualizarUsuario {
	status: number;
	data: null | UsuarioData;
}

interface UsuarioData {
	codigo: string;
	mensagem: string;
}

export interface AtualizarUsuarioRequest {
	id?: number | string;
	nome: string;
	email: string;
	endereco: string;
	cpf: string;
	rg: string;
	digitoRg: string;
	ufRg: string | null;
	dddCelular: number | null;
	telCelular: string;
	dddComercial: number | null;
	telComercial: string;
	dddResidencial: number | null;
	telResidencial: string;
	perfil: number;
	status: number;
	localidades: number[] | null;
}
