export enum Types {
	CLEAR_APP = '@CLEAR_APP',
}
export interface EventoContabilizacao {
	id_atendimento: string;
	id_cidadao: string;
	ip: string;
	cpf: string;
	identificacao: string | number;
	idAtendimento?: string;
	idCidadao?: string;
	canal?: {
		id: number;
		desc: string;
		localidade: {
			id: string | number;
			desc: string;
		};
	};
}
export interface ReducerAction {
	type: string;
	payload: any;
}

export interface ReducerActionRotaSP {
	type: string;
	token?: string;
	payload: any;
	variavelBoolean?: boolean;
}
export interface ReducerActionNoPayload {
	type: string;
}
