// ENTITIES
import { IFormPesquisaRg } from 'pages/iirgd/RgFicDigital/PesquisaRg/Formulario/form';

export enum Types {
	CONSULTA_PESQUISA_CIDADAO_REQUEST = '@iirgd/CONSULTA_PESQUISA_CIDADAO_REQUEST',
	CONSULTA_PESQUISA_CIDADAO_SUCCESS = '@iirgd/CONSULTA_PESQUISA_CIDADAO_SUCCESS',
	CONSULTA_PESQUISA_CIDADAO_FAILURE = '@iirgd/CONSULTA_PESQUISA_CIDADAO_FAILURE',
	CONSULTA_PESQUISA_CIDADAO_FORM_PESQUISA_MAE_REQUEST = '@iirgd/CONSULTA_PESQUISA_CIDADAO_FORM_PESQUISA_MAE_REQUEST',

	CONSULTA_PESQUISA_CIDADAO_SELECIONADO = '@iirgd/CONSULTA_PESQUISA_CIDADAO_SELECIONADO',
	CONSULTA_PESQUISA_CIDADAO_SELECIONADO_CLEAR = '@iirgd/CONSULTA_PESQUISA_CIDADAO_SELECIONADO_CLEAR',

	CONSULTA_PESQUISA_CIDADAO_CLEAR = '@iirgd/CONSULTA_PESQUISA_CIDADAO_CLEAR',
}

export interface IConsultaPesquisaCidadaoState {
	status: number;
	data: ConsultaPesquisaCidadao[];
	form: IFormPesquisaRg | null;
	selecionado: IConsultaPesquisaCidadaoSelecionado | null;
	ocorrencias: {
		cirg: number;
		cino: number;
		cinn: number;
	};
}

export interface ConsultaPesquisaCidadao {
	numeroRg: string;
	digitoRg: string;
	dataInclusao: string;
	statusEmissao: string;
	nome: string;
	nomePai: string;
	nomeMae: string;
	sexo: string;
	dataNascimento: string;
	numeroNaturalidade: string;
	descricaoNaturalidade: string;
	mensagem: string;
	numeroPosto: string;
	documento: string;
	lote: string;
	digitoLote: string;
	cpf: string;
	numeroPis: string;
	numeroPasep: string;
	comarca: string;
	cartorio: string;
	livroCartorio: string;
	folhaCartorio: string;
	numeroCartorio: string;
	dataDocumento: string;
	cirg: boolean;
	cinn: boolean;
	cino: boolean;
	tlod: boolean;
	exrg: boolean;
	pesq: boolean;
	status: string;
	afis: string;
	statusCriminal: string;
	tipoSanguineo: string;
	pMinLei1: string;
	pMinLei3: string;
	pMinLei2: string;
	motivoFaltaAssinatura: string;
	cpfResponsavel: string;
	tipoVinculo: string;
	dataValidadeCNH: string;
}

export interface IConsultaPesquisaCidadaoSelecionado {
	numeroRg: string;
	digitoRg: string;
	nome: string;
	nomePai: string;
	nomeMae: string;
	dataNascimento: string;
	codigoNaturalidade: string;
	municipioNaturalidade: string;
	afis?: string;
	statusEmissao?: string;
}
