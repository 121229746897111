import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, deleteApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, ExcluirTiposRequest } from './types';

// ACTIONS
import { excluirTiposSuccess, excluirTiposFailure } from './actions';

function* excluirTipos(request: ReducerActionRotaSP) {
	const { token, payload }: { token?: string; payload: ExcluirTiposRequest } =
		request;

	const response: ApiResponse = yield call(
		deleteApi,
		token || '',
		PATH_SGU_API,
		`tipos-localidade/${payload.codigo}`,
	);

	if (response.status === 200) {
		yield put(excluirTiposSuccess(response));
	} else {
		yield put(excluirTiposFailure());
	}
}

export default all([takeLatest(Types.EXCLUIR_TIPOS, excluirTipos)]);
