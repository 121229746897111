import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Form, Formik } from 'formik';
import { Col, Row, Modal } from 'antd';

import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import ButtonImage from 'components/Common/ButtonImage';
import Button from 'components/Common/Button';
import { ContentButton } from 'pages/iirgd/Aac/Numeral/Consultar/styled';

import getValidationsErrors from 'utils/getValidationsErrors';
import { limparMascara } from 'utils/genericFunctions';
import { treatRequestEventsContabilizacaoPPT } from 'utils/functions/treatEventosPPTRequest';
import { treatRequestSguEducacaoResponseEvents } from 'pages/DetranCnh/utils/functions/sguStatistics/treatServiceRequest';

import { ROUTE_SECRETARIA_EDUCACAO_CADASTRO_RESPONSAVEIS_PESQUISA } from 'pages/EducacaoUpdate/routes/paths';
import {
	CADASTRO_RESPONSAVEL_SOLICITACAO_CONCLUSAO,
	CADASTRO_RESPONSAVEL_SOLICITACAO_CONCLUSAO_ID_SERVICO,
	CADASTRO_RESPONSAVEL_SOLICITACAO_CONCLUSAO_DESCRICAO,
	EDITAR_RESPONSAVEL_SOLICITACAO_CONCLUSAO,
	EDITAR_RESPONSAVEL_SOLICITACAO_CONCLUSAO_ID_SERVICO,
	EDITAR_RESPONSAVEL_SOLICITACAO_CONCLUSAO_DESCRICAO,
	EXCLUIR_RESPONSAVEL_SOLICITACAO_CONCLUSAO,
	EXCLUIR_RESPONSAVEL_SOLICITACAO_CONCLUSAO_ID_SERVICO,
	EXCLUIR_RESPONSAVEL_SOLICITACAO_CONCLUSAO_DESCRICAO,
	ID_ORGAO_EDUCACAO,
} from 'pages/EducacaoUpdate/tiposEventos';

import { Responsavel } from 'store/modules/api/educacaoUpdate/responsavel/cadastroResponsavel/types';
import {
	cadastrarResponsavelRequest,
	limpaPreCadastroResponsavelRequest,
	cadastrarResponsavelClear,
	latLngSelecionada,
} from 'store/modules/api/educacaoUpdate/responsavel/cadastroResponsavel/actions';
import {
	removerResponsavelRequest,
	removerResponsavelClear,
} from 'store/modules/api/educacaoUpdate/responsavel/removerResponsavel/actions';
import { dadosBasicosRequest } from 'store/modules/api/educacaoUpdate/combos/dadosBasicos/actions';
import { comboUfRequest } from 'store/modules/api/educacaoUpdate/combos/uf/actions';
import { eventosPPTContabilizacaoRequest } from 'store/modules/api/utils/eventosPPT/contabilizacao/actions';
import { estatisticaIncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/actions';

// CONTEXT
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import DadosPessoais from './DadosPessoais';
import EnderecoResidencial from '../../components/EnderecoResidencial';
import Telefones from '../../components/Telefones';

import {
	initialValues,
	schema,
	formatValuesCreate,
	formatValuesUpdate,
	formatValuesRemove,
	treatInitialValuesResponsavel,
} from './form';

const FichaResponsavel: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location: any = useLocation();
	const { token } = useContext<IAuthContext>(AuthContext);
	const { isEditar, canDelete } = location.state;
	const [cpfResponsavel, setCpfResponsavel] = useState('');
	const [formikInitialValues, setFormikInitialValues] = useState<Responsavel>({
		...initialValues,
	});

	const { listarResponsaveis } = useSelector(
		(state: ApplicationState) => state.api.educacaoUpdate.responsavel,
	);

	const { cadastrarResponsavel, removerResponsavel } = useSelector(
		(state: ApplicationState) => state.api.educacaoUpdate.responsavel,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { eventosPPTContabilizacao } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const { data: dataSalvarAtendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento.salvarAtendimento,
	);

	const { estatisticasIncluir } = useSelector(
		(state: ApplicationState) => state.api.sguService,
	);

	const handleDadosAluno = useCallback(() => {
		const { consultaData } = listarResponsaveis;
		const aluno = {
			numeroRA: consultaData?.numeroRA || '',
			digitoRA: consultaData?.digitoRA || '',
			siglaUFRA: consultaData?.siglaUFRa || '',
		};
		return aluno;
	}, [listarResponsaveis]);

	const handleSubmit = useCallback(
		(values: Responsavel) => {
			dispatch(cadastrarResponsavelClear());
			dispatch(removerResponsavelClear());
			const aluno = handleDadosAluno();
			dispatch(
				latLngSelecionada({
					lat: Number(values.enderecoResidencial.latitude),
					lng: Number(values.enderecoResidencial.longitude),
				}),
			);
			setCpfResponsavel(limparMascara(values.cpf));
			if (isEditar) {
				dispatch(
					cadastrarResponsavelRequest(token, formatValuesUpdate(aluno, values)),
				);
			} else {
				dispatch(
					cadastrarResponsavelRequest(token, formatValuesCreate(aluno, values)),
				);
			}
		},
		[dispatch, handleDadosAluno, isEditar, token],
	);

	const handleDeleteModal = useCallback(
		(values: Responsavel) => {
			const aluno = handleDadosAluno();
			if (!canDelete) {
				Modal.error({
					content:
						'O aluno tem apenas um responsável cadastrado, a exclusão não pode ser efetuada.',
					okText: 'OK',
					okType: 'danger',
				});
			} else {
				Modal.confirm({
					content: 'Tem certeza que deseja excluir esse responsável?',
					okText: 'Excluir',
					okType: 'danger',
					cancelText: 'Cancelar',
					onOk() {
						dispatch(
							removerResponsavelRequest(
								token,
								formatValuesRemove(aluno, values),
							),
						);
						Modal.destroyAll();
					},
					onCancel() {},
				});
			}
		},
		[canDelete, dispatch, handleDadosAluno, token],
	);

	const handleModal = useCallback(
		(isSuccess: boolean | 'remover' | 'cadastrar') => {
			if (isSuccess) {
				Modal.success({
					content:
						isSuccess === 'remover'
							? 'Remoção de cadastro realizado com sucesso!'
							: 'Solicitação efetuada com sucesso!',
					okText: 'Finalizar atendimento',
					centered: true,
					onOk() {
						history.push(
							ROUTE_SECRETARIA_EDUCACAO_CADASTRO_RESPONSAVEIS_PESQUISA,
						);
					},
				});
			}
		},
		[history],
	);

	useEffect(() => {
		if (removerResponsavel.status === 200) {
			if (eventosPPTContabilizacao.status === 0) {
				const treatedEventsPPTRequest = treatRequestEventsContabilizacaoPPT(
					atendimento,
					loginUnico,
					cpfResponsavel,
					'EDUCAÇÃO',
					ID_ORGAO_EDUCACAO,
					EXCLUIR_RESPONSAVEL_SOLICITACAO_CONCLUSAO_DESCRICAO,
					Number(EXCLUIR_RESPONSAVEL_SOLICITACAO_CONCLUSAO_ID_SERVICO),
					EXCLUIR_RESPONSAVEL_SOLICITACAO_CONCLUSAO,
				);
				dispatch(
					eventosPPTContabilizacaoRequest(token, treatedEventsPPTRequest),
				);
			}
		} else if (removerResponsavel.status === 400) {
			handleModal(false);
		}
	}, [
		dispatch,
		handleModal,
		removerResponsavel.status,
		loginUnico,
		atendimento,
		token,
		eventosPPTContabilizacao,
		cpfResponsavel,
	]);

	useEffect(() => {
		if (cadastrarResponsavel.status === 200) {
			handleModal('cadastrar');
		}
	}, [cadastrarResponsavel.status, handleModal]);

	useEffect(() => {
		if (removerResponsavel.status === 200) {
			handleModal('remover');
		}
	}, [removerResponsavel.status, handleModal]);

	useEffect(() => {
		if (cadastrarResponsavel.status === 200) {
			if (eventosPPTContabilizacao.status === 0) {
				const treatedEventsPPTRequest = treatRequestEventsContabilizacaoPPT(
					atendimento,
					loginUnico,
					cpfResponsavel,
					'EDUCAÇÃO',
					ID_ORGAO_EDUCACAO,
					isEditar
						? EDITAR_RESPONSAVEL_SOLICITACAO_CONCLUSAO_DESCRICAO
						: CADASTRO_RESPONSAVEL_SOLICITACAO_CONCLUSAO_DESCRICAO,
					isEditar
						? Number(EDITAR_RESPONSAVEL_SOLICITACAO_CONCLUSAO_ID_SERVICO)
						: Number(CADASTRO_RESPONSAVEL_SOLICITACAO_CONCLUSAO_ID_SERVICO),
					isEditar
						? EDITAR_RESPONSAVEL_SOLICITACAO_CONCLUSAO
						: CADASTRO_RESPONSAVEL_SOLICITACAO_CONCLUSAO,
				);
				dispatch(
					eventosPPTContabilizacaoRequest(token, treatedEventsPPTRequest),
				);
			}
		} else if (cadastrarResponsavel.status === 400) {
			handleModal(false);
		}
	}, [
		cadastrarResponsavel,
		handleModal,
		loginUnico,
		atendimento,
		token,
		dispatch,
		isEditar,
		eventosPPTContabilizacao,
		cpfResponsavel,
	]);

	useEffect(() => {
		if (
			eventosPPTContabilizacao.status !== 0 &&
			eventosPPTContabilizacao.status !== 100
		) {
			if (estatisticasIncluir.status === 0) {
				const payload = treatRequestSguEducacaoResponseEvents(
					loginUnico,
					atendimento,
					dataSalvarAtendimento?.cpf || '',
					eventosPPTContabilizacao.status === 200,
					isEditar
						? EDITAR_RESPONSAVEL_SOLICITACAO_CONCLUSAO
						: CADASTRO_RESPONSAVEL_SOLICITACAO_CONCLUSAO,
					isEditar
						? Number(EDITAR_RESPONSAVEL_SOLICITACAO_CONCLUSAO_ID_SERVICO)
						: Number(CADASTRO_RESPONSAVEL_SOLICITACAO_CONCLUSAO_ID_SERVICO),
					isEditar
						? EDITAR_RESPONSAVEL_SOLICITACAO_CONCLUSAO_DESCRICAO
						: CADASTRO_RESPONSAVEL_SOLICITACAO_CONCLUSAO_DESCRICAO,
				);
				dispatch(estatisticaIncluirRequest(payload));
			}
		}
	}, [
		eventosPPTContabilizacao.status,
		estatisticasIncluir.status,
		isEditar,
		dataSalvarAtendimento,
		dispatch,
		atendimento,
		loginUnico,
	]);

	useEffect(() => {
		if (cadastrarResponsavel.status === 999 && cadastrarResponsavel.data) {
			const treatedValues = treatInitialValuesResponsavel(
				cadastrarResponsavel.data.responsavel,
			);

			setFormikInitialValues(treatedValues);
		}
	}, [cadastrarResponsavel, handleModal]);

	useEffect(() => {
		dispatch(dadosBasicosRequest(token));
		dispatch(comboUfRequest(token));
	}, [dispatch, token]);

	return (
		<>
			<Formik
				enableReinitialize
				validateOnChange={false}
				validateOnBlur={false}
				initialValues={formikInitialValues}
				validate={values => getValidationsErrors(values, schema)}
				onSubmit={handleSubmit}
			>
				{formik => (
					<>
						<Form autoComplete="off">
							<Section title="Ficha Responsável">
								<Col span={24}>
									<Row gutter={[0, 20]}>
										<Col span={24}>
											<DadosPessoais formik={formik} setCpf={() => null} />
										</Col>
									</Row>
									<Row gutter={[0, 20]}>
										<Col span={24}>
											<EnderecoResidencial
												formik={formik}
												dadosAluno={handleDadosAluno()}
												isResponsavel
											/>
										</Col>
									</Row>
									<Row gutter={[0, 20]}>
										<Col span={24}>
											<Telefones formik={formik} interfaceNova />
										</Col>
									</Row>
								</Col>
								{isEditar ? (
									<ContentButton justify="center">
										<Button
											className="addButton"
											onClick={() => {
												handleDeleteModal(formik.values);
											}}
										>
											Excluir
										</Button>
										<ButtonImage type="submit" src="atualizar" />
									</ContentButton>
								) : (
									<ContentButton justify="center">
										<ButtonImage type="submit" src="avancar" />
									</ContentButton>
								)}
							</Section>
							<ButtonVoltar
								navigate={false}
								onClick={() => {
									// dispatch(cadastrarResponsavelClear());
									dispatch(limpaPreCadastroResponsavelRequest());
									// dispatch(listarResponsaveisClean());
									history.push(
										ROUTE_SECRETARIA_EDUCACAO_CADASTRO_RESPONSAVEIS_PESQUISA,
									);
								}}
							/>
						</Form>
					</>
				)}
			</Formik>
		</>
	);
};

export default FichaResponsavel;
