import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

// ANTD
import { Row, Col } from 'antd';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { detranCnhClear } from 'store/modules/api/detranCnh/actions';
import { relatorioAgendamentoMedicoPsicologicoClear } from 'store/modules/api/detranCnh/agendamentoMedicoPsicologico/relatorio/actions';
import { preCadastroCidadaoClear } from 'store/modules/api/sguService/preCadastroCidadao/actions';
import { geraSenhaClear } from 'store/modules/api/relatorios/geraSenha/actions';
import { consultarDigitaisClear } from 'store/modules/api/utils/consultarDigitais/actions';
import { enderecosClear } from 'store/modules/api/utils/enderecos/actions';
import { logonRestClear } from 'store/modules/api/detranCnh/eportal/logonRest/actions';

// TYPES
import { IParametrosLocalidade } from 'store/modules/api/sgu/loginUnico/types';

// COMPONENTS
import ServiceList from 'components/Common/ServiceList';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// import ReimpressaoRenach from './Reimpressao/Renach';

interface ServiceProps {
	name: string;
	path: string;
	descricaoServico?: string;
	recuperaAtendimento?: boolean;
}

interface ServiceCombo {
	title: string;
	services: ServiceProps[];
}

const { REACT_APP_ENVIRONMENT } = process.env;

const MenuDetranCnh: React.FC = () => {
	const history = useHistory();

	const dispatch = useDispatch();

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	let itensService: ServiceCombo[] = [
		{
			title: '',
			services: [
				{
					name: 'Renovação de CNH',
					path: 'renovacao/pesquisa',
					descricaoServico: 'RENOVACAO',
				},
				{
					name: 'Segunda Via de CNH ou de Permissão',
					path: 'segunda-via/pesquisa',
				},
				// {
				// 	name: 'CNH Digital',
				// 	path: 'ecnhDigital/pesquisa',
				// },
				{
					name: 'CNH Definitiva',
					path: 'cnh-definitiva/pesquisa',
				},
				{
					name: 'Primeira Habilitação',
					path: 'primeira-habilitacao/pesquisa',
					descricaoServico: 'PRIMEIRA HABILITACAO',
				},
				{
					name: 'Nova Habilitação - Permissionário Pontuado',
					path: 'permissionario-pontuado/pesquisa',
				},
				// {
				// 	name: 'Adição/Mudança de Categoria',
				// 	path: 'adicao-mudanca-categoria/pesquisa',
				// 	descricaoServico: 'ADICAO MUDANCA CATEGORIA',
				// },
				// {
				// 	name: 'Permissão Internacional Automatizado para Dirigir - PID',
				// 	path: 'permissao-internacional-automatizado/pesquisa',
				// },
				// {
				// 	name: 'Consulta Pontuação CNH',
				// 	path: 'consulta-pontuacao-cnh/pesquisa',
				// },
				// {
				// 	name: 'Registro de CNH Estrangeira',
				// 	path: 'cnh-estrangeiro/pesquisa',
				// },
				// {
				// 	name: 'Reabilitação - Cassação / Crime',
				// 	path: 'cnh-cassada-reabilitacao/pesquisa',
				// },
				// {
				// 	name: 'Solicitar Alteração de Endereço',
				// 	path: 'solicitar-alteracao-endereco/pesquisa',
				// },
			],
		},
	];

	const newsItensService: ServiceCombo[] = [
		{
			title: '',
			services: [],
		},
	];

	newsItensService.filter(item => {
		if (item.title === '') {
			const parametroNomeSocial = user.parametrosSistema.find(
				(param: IParametrosLocalidade) =>
					param.sigla.includes('CNH_CONSULTA_NOME_SOCIAL'),
			);

			if (
				parametroNomeSocial?.valor === 'TRUE' &&
				user?.perfil.nome === 'ADMINISTRADOR DO SISTEMA'
			) {
				item.services.push({
					name: 'Consulta Nome Social',
					path: 'consulta-nome-social/pesquisa',
					descricaoServico: 'CONSULTA NOME SOCIAL',
				});
			}

			const parametroCancelarSolicitacaoEmissaoPID =
				user.parametrosSistema.find((parametroSistema: IParametrosLocalidade) =>
					parametroSistema.sigla.includes(
						'CNH_CANCELAR_SOLICITACAO_EMISSAO_PID',
					),
				);

			if (
				parametroCancelarSolicitacaoEmissaoPID?.valor === 'TRUE' &&
				user?.permisaoUsuario?.isTemAcessoADM
			) {
				item.services.push({
					name: 'Cancelar Solicitação Emissão PID',
					path: 'cancelar-solicitacao-emissao-pid/pesquisa',
					descricaoServico: 'CANCELAR SOLICITAÇÃO EMISSÃO PID',
					recuperaAtendimento: true,
				});
			}

			return item.services;
		}

		return null;
	});

	if (
		REACT_APP_ENVIRONMENT === 'DEV' ||
		REACT_APP_ENVIRONMENT === 'HOMOLOG' ||
		REACT_APP_ENVIRONMENT === 'TEST' ||
		window.location.href ===
			'http://homologa.poupatempo4g.sp.gov.br/detran-cnh' ||
		window.location.href ===
			'https://homologa.poupatempo4g.sp.gov.br/detran-cnh'
	) {
		itensService = [
			{
				title: '',
				services: [
					{
						name: 'Renovação de CNH',
						path: 'renovacao/pesquisa',
						descricaoServico: 'RENOVACAO',
					},
					{
						name: 'Segunda Via de CNH ou de Permissão',
						path: 'segunda-via/pesquisa',
					},
					// {
					// 	name: 'CNH Digital',
					// 	path: 'ecnhDigital/pesquisa',
					// },
					{
						name: 'CNH Definitiva',
						path: 'cnh-definitiva/pesquisa',
					},
					{
						name: 'Primeira Habilitação',
						path: 'primeira-habilitacao/pesquisa',
						descricaoServico: 'PRIMEIRA HABILITACAO',
					},
					{
						name: 'Nova Habilitação - Permissionário Pontuado',
						path: 'permissionario-pontuado/pesquisa',
					},
					// {
					// 	name: 'Adição/Mudança de Categoria',
					// 	path: 'adicao-mudanca-categoria/pesquisa',
					// 	descricaoServico: 'ADICAO MUDANCA CATEGORIA',
					// },
					// {
					// 	name: 'Permissão Internacional Automatizado para Dirigir - PID',
					// 	path: 'permissao-internacional-automatizado/pesquisa',
					// },
					// {
					// 	name: 'Consulta Pontuação CNH',
					// 	path: 'consulta-pontuacao-cnh/pesquisa',
					// },
					// {
					// 	name: 'Registro de CNH Estrangeira',
					// 	path: 'cnh-estrangeiro/pesquisa',
					// },
					// {
					// 	name: 'Transferência Municipal',
					// 	path: 'transferencia-municipal/pesquisa',
					// },
					// {
					// 	name: 'Reabilitação - Cassação / Crime',
					// 	path: 'cnh-cassada-reabilitacao/pesquisa',
					// },
					// {
					// 	name: 'Solicitar Alteração de Endereço',
					// 	path: 'solicitar-alteracao-endereco/pesquisa',
					// },
				],
			},
		];
	}

	const services = [
		{
			title: '',
			services: [
				{
					name: 'Digitalizar Documentos',
					path: 'menu-scanner',
					recuperaAtendimento: false,
				},
				{
					name: 'Consulta Resumo - Extrato',
					path: 'resumo-condutor/pesquisa',
					descricaoServico: 'CONSULTA RESUMO EXTRATO',
				},
			],
		},
		...itensService,
		...newsItensService,
	];

	useEffect(() => {
		dispatch(detranCnhClear());
		dispatch(enderecosClear());
		dispatch(preCadastroCidadaoClear());
		dispatch(geraSenhaClear());
		dispatch(logonRestClear({}));
		dispatch(relatorioAgendamentoMedicoPsicologicoClear());
		dispatch(consultarDigitaisClear());
	}, [dispatch]);

	return (
		<Row>
			<Col span={24}>
				<Row gutter={[0, 10]}>
					<Col span={24} />
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={24}>
						<h1>Escolha o serviço:</h1>
					</Col>
				</Row>

				<Row>
					<Col span={24}>{services && <ServiceList data={services} />}</Col>
				</Row>

				<Row>
					<Col span={24}>
						<ButtonVoltar
							navigate={false}
							onClick={() => {
								history.push('/menu-orgaos');
							}}
						/>
					</Col>
				</Row>
			</Col>

			{/* <Col span={8} offset={1}>
				<Row gutter={[0, 20]}>
					<Col span={24}>
						<ReimpressaoRenach />
					</Col>
				</Row>
			</Col> */}
		</Row>
	);
};

export default MenuDetranCnh;
