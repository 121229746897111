import React, { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';

// REDUX
import { ApplicationState } from 'store';
import data from 'store/modules/api/iirgd/enum';

// UTILS
import { limparMascara, patternFormat } from 'utils/genericFunctions';

// FORM
import InputMask from 'components/Common/Form/Input/InputMask';
import { IFormCadastroRg } from '../../../CadastroRg/form';

// STYLED
import { Title, Label, Value } from '../../styled';

export const DadosPrincipais: React.FC = () => {
	const [hasLoaded, setHasLoaded] = useState<boolean>(false);

	const [primeiraVia, setPrimeiraVia] = useState<boolean>(true);
	const [cadastroRG, setCadastroRG] = useState<IFormCadastroRg | null>(null);

	const { cadastroRg } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	useEffect(() => {
		const { tipo, form } = cadastroRg;
		const { principal } = form;

		setPrimeiraVia(tipo.primeiraVia);
		setCadastroRG(principal);
		setHasLoaded(true);
	}, [cadastroRg]);

	const formatEndereco = useCallback(
		(
			logradouro: string | undefined,
			numero: string | undefined,
			complemento: string | undefined,
			municipio: string | undefined,
		): string => {
			let endereco = '';

			if (logradouro) endereco = logradouro;
			if (numero) endereco = `${endereco}, ${numero}`;
			if (complemento) endereco = `${endereco}, ${complemento}`;
			if (municipio) endereco = `${endereco}, ${municipio}`;

			return endereco;
		},
		[],
	);

	return (
		<>
			{hasLoaded && cadastroRG && (
				<>
					<Row gutter={[0, 10]}>
						<Col span={24} />
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Title>Dados Principais</Title>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={5}>
							<Label>RGE/CIN:</Label>
						</Col>
						<Col span={4}>
							<Value>
								{limparMascara(cadastroRG.numeroRg).length === 10
									? patternFormat(cadastroRG.numeroRg, '##.###.###-##')
									: patternFormat(cadastroRG.numeroRg, '##.###.###-#')}
							</Value>
						</Col>

						<Col span={4} offset={7}>
							<Label>VIA:</Label>
						</Col>
						<Col span={4}>
							<Value>{primeiraVia ? '1ª' : '2ª'}</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={5}>
							<Label>Nome:</Label>
						</Col>
						<Col span={19}>
							<Value>{cadastroRG.nome}</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={5}>
							<Label>Nome social:</Label>
						</Col>
						<Col span={19}>
							<Value>{cadastroRG.nomeSocial}</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={5}>
							<Label>Filiação 1:</Label>
						</Col>
						<Col span={19}>
							<Value>{cadastroRG.nomePai}</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={5}>
							<Label>Filiação 2:</Label>
						</Col>
						<Col span={19}>
							<Value>{cadastroRG.nomeMae}</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={5}>
							<Label>Naturalidade:</Label>
						</Col>
						<Col span={19}>
							<Value>{cadastroRG.municipioNaturalidade}</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={5}>
							<Label>Sexo:</Label>
						</Col>
						<Col span={3}>
							<Value>
								{data.sexo.find(sexo => sexo.value === cadastroRG.sexo)?.label}
							</Value>
						</Col>

						<Col span={5}>
							<Label>Data de nascimento:</Label>
						</Col>
						<Col span={3}>
							<Value>{cadastroRG.dataNascimento}</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={5}>
							<Label>Documento apresentado:</Label>
						</Col>
						<Col span={11}>
							<Value>
								{
									data.tipoDocumento.find(
										documento => documento.value === cadastroRG.dbas,
									)?.label
								}
							</Value>
						</Col>

						<Col span={4}>
							<Label>Data do Documento:</Label>
						</Col>
						<Col span={4}>
							<Value>{cadastroRG.dataDocumento}</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={5}>
							<Label>Comarca:</Label>
						</Col>
						<Col span={11}>
							<Value>{cadastroRG.comarca}</Value>
						</Col>

						<Col span={4}>
							<Label>Cartório:</Label>
						</Col>
						<Col span={4}>
							<Value>{cadastroRG.cartorio}</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={5}>
							<Label>Livro:</Label>
						</Col>
						<Col span={3}>
							<Value>{cadastroRG.livro}</Value>
						</Col>

						<Col span={5}>
							<Label>Folha:</Label>
						</Col>
						<Col span={3}>
							<Value>{cadastroRG.folha}</Value>
						</Col>

						<Col span={4}>
							<Label>Número:</Label>
						</Col>
						<Col span={4}>
							<Value>{cadastroRG.numero}</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={5}>
							<Label>Estado civil:</Label>
						</Col>
						<Col span={3}>
							<Value>
								{
									data.estadoCivil.find(
										estadoCivil => estadoCivil.value === cadastroRG.estadoCivil,
									)?.label
								}
							</Value>
						</Col>

						<Col span={5}>
							<Label>Grau de instrução:</Label>
						</Col>
						<Col span={3}>
							<Value>
								{
									data.grauEscolaridade.find(
										grauEscolaridade =>
											grauEscolaridade.value === cadastroRG.grauInstrucao,
									)?.label
								}
							</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={5}>
							<Label>Profissão:</Label>
						</Col>
						<Col span={11}>
							<Value>{cadastroRG.profissao}</Value>
						</Col>

						<Col span={4}>
							<Label>Altura:</Label>
						</Col>
						<Col span={4}>
							<Value>{cadastroRG.altura}</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={5}>
							<Label>Cutis:</Label>
						</Col>
						<Col span={3}>
							<Value>
								{
									data.cutis.find(cutis => cutis.value === cadastroRG.cutis)
										?.label
								}
							</Value>
						</Col>

						<Col span={5}>
							<Label>Cabelos:</Label>
						</Col>
						<Col span={3}>
							<Value>
								{
									data.corCabelo.find(
										cabelo => cabelo.value === cadastroRG.cabelos,
									)?.label
								}
							</Value>
						</Col>

						<Col span={4}>
							<Label>Olhos:</Label>
						</Col>
						<Col span={4}>
							<Value>
								{
									data.corOlhos.find(olhos => olhos.value === cadastroRG.olhos)
										?.label
								}
							</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={5}>
							<Label>Endereço residencial:</Label>
						</Col>
						<Col span={19}>
							<Value>
								{formatEndereco(
									cadastroRG.enderecoResidencia,
									cadastroRG.numeroResidencia,
									cadastroRG.complementoResidencia,
									cadastroRG.descMunicipioResidencia,
								)}
							</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={5}>
							<Label>Endereço comercial:</Label>
						</Col>
						<Col span={19}>
							<Value>
								{formatEndereco(
									cadastroRG.enderecoTrabalho,
									cadastroRG.numeroTrabalho,
									cadastroRG.complementoTrabalho,
									cadastroRG.descMunicipioTrabalho,
								)}
							</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={5}>
							<Label>Telefone:</Label>
						</Col>
						<Col span={3}>
							<Value>
								{patternFormat(cadastroRG.foneResidencia, '(##) ####-####')}
							</Value>
						</Col>

						<Col span={5}>
							<Label>Celular:</Label>
						</Col>
						<Col span={3}>
							<Value>
								{patternFormat(cadastroRG.celular, '(##) #####-####')}
							</Value>
						</Col>

						<Col span={4}>
							<Label>Tel. comercial:</Label>
						</Col>
						<Col span={4}>
							<Value>
								{patternFormat(cadastroRG.foneComercial, '(##) ####-####')}
							</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={5}>
							<Label>E-mail:</Label>
						</Col>
						<Col span={19}>
							<Value>{cadastroRG.email}</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]} style={{ alignItems: 'baseline' }}>
						<Col span={5}>
							<Label>CPF:</Label>
						</Col>
						<Col span={3}>
							<InputMask
								name=""
								readOnly
								value={cadastroRG.cpf ?? ''}
								maskChar=""
								mask="999.999.999-99"
							/>
						</Col>

						<Col span={5}>
							<Label>PIS:</Label>
						</Col>
						<Col span={3}>
							<Value>{cadastroRG.pis}</Value>
						</Col>

						<Col span={4}>
							<Label>PASEP:</Label>
						</Col>
						<Col span={4}>
							<Value>{cadastroRG.pasep}</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={5}>
							<Label>CTPS:</Label>
						</Col>
						<Col span={3}>
							<Value>{cadastroRG.ctpsNum}</Value>
						</Col>

						<Col span={5}>
							<Label>Série:</Label>
						</Col>
						<Col span={3}>
							<Value>{cadastroRG.ctpsSerie}</Value>
						</Col>

						<Col span={4}>
							<Label>S.Estado:</Label>
						</Col>
						<Col span={4}>
							<Value>{cadastroRG.ctpsSerieUf}</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={5}>
							<Label>Cert. Militar:</Label>
						</Col>
						<Col span={3}>
							<Value>{cadastroRG.certidaoMilitar}</Value>
						</Col>

						<Col span={5}>
							<Label>CNH:</Label>
						</Col>
						<Col span={3}>
							<Value>{cadastroRG.cnh}</Value>
						</Col>

						<Col span={4}>
							<Label>Categoria CNH:</Label>
						</Col>
						<Col span={4}>
							<Value>{cadastroRG.categoriaCnh}</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={5} />
						<Col span={3} />

						<Col span={5}>
							<Label>Validade da CNH:</Label>
						</Col>
						<Col span={4}>
							<Value>{cadastroRG.dataValidadeCNH}</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={5}>
							<Label>Tipo de Documento a Emitir:</Label>
						</Col>
						<Col span={3}>
							<Value>
								{cadastroRG.dni === '10977' ? 'CIN' : 'RG Estadual'}
							</Value>
						</Col>

						<Col span={5}>
							<Label>CNS:</Label>
						</Col>
						<Col span={3}>
							<Value>{cadastroRG.nascSaude}</Value>
						</Col>

						<Col span={4}>
							<Label>Título eleitor:</Label>
						</Col>
						<Col span={4}>
							<Value>{cadastroRG.tituloEleitor}</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={5}>
							<Label>Identidade profissional:</Label>
						</Col>
						<Col span={3}>
							<Value>{cadastroRG.identProf1}</Value>
						</Col>

						<Col span={5}>
							<Label>Entidade profissional:</Label>
						</Col>
						<Col span={11}>
							<Value>{cadastroRG.orgaoEmissor1}</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={5}>
							<Label>Identidade profissional:</Label>
						</Col>
						<Col span={3}>
							<Value>{cadastroRG.identProf2}</Value>
						</Col>

						<Col span={5}>
							<Label>Entidade profissional:</Label>
						</Col>
						<Col span={11}>
							<Value>{cadastroRG.orgaoEmissor2}</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={5}>
							<Label>Identidade profissional:</Label>
						</Col>
						<Col span={3}>
							<Value>{cadastroRG.identProf3}</Value>
						</Col>

						<Col span={5}>
							<Label>Entidade profissional:</Label>
						</Col>
						<Col span={11}>
							<Value>{cadastroRG.orgaoEmissor3}</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={5}>
							<Label>CID:</Label>
						</Col>
						<Col span={3}>
							<Row>
								<Col>
									<Value>{`1. ${cadastroRG.cid1}`}</Value>
								</Col>
							</Row>

							<Row>
								<Col>
									<Value>{`2. ${cadastroRG.cid2}`}</Value>
								</Col>
							</Row>

							<Row>
								<Col>
									<Value>{`3. ${cadastroRG.cid3}`}</Value>
								</Col>
							</Row>

							<Row>
								<Col>
									<Value>{`4. ${cadastroRG.cid4}`}</Value>
								</Col>
							</Row>
						</Col>

						<Col span={5}>
							<Label>Tipo sanguíneo:</Label>
						</Col>
						<Col span={11}>
							<Value>
								{
									data.tipoSanguineo.find(
										tipoSanguineo =>
											tipoSanguineo.value === cadastroRG.tipoSanguineo,
									)?.label
								}
							</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={24} />
					</Row>
				</>
			)}
		</>
	);
};
