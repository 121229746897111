import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';
import { ReducerActionRotaSP } from 'store/modules/types';
import { cleanEmpty, limparMascara } from 'utils/genericFunctions';
import { ConsultarUsuariosRequest, Types } from './types';
import { consultarUsuariosSuccess, consultarUsuariosFailure } from './actions';

function* consultarUsuariosRequest(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: ConsultarUsuariosRequest } = request;

	let body = { ...payload };

	if (body.tipoDocumento === 'cpf') {
		body.cpf = limparMascara(body.cpf);
		delete body.nome;
	} else {
		delete body.cpf;
	}

	delete body.tipoDocumento;

	delete body.current;

	body = cleanEmpty(body) as ConsultarUsuariosRequest;

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_SGU_API,
		`usuarios`,
		body,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(consultarUsuariosSuccess(response));
	} else if (response.error) {
		yield put(consultarUsuariosFailure(response.message));
	}
}

export default all([
	takeLatest(Types.CONSULTAR_USUARIOS_REQUEST, consultarUsuariosRequest),
]);
