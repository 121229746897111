import React, { useCallback, useState, useEffect, useContext } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Col, Row, Button } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';
import TooltipInfo from 'components/Common/Tooltips/Info';
import InputMask from 'components/Common/Form/Input/InputMask';
import Select from 'components/Common/Form/Select';
import SimpleTable from 'components/Common/Table';
import Alert from 'components/Common/Notifications/Alert';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ApplicationState } from 'store';
import {
	cadastrarResponsavelClear,
	preCadastroResponsavelRequest,
} from 'store/modules/api/educacaoUpdate/responsavel/cadastroResponsavel/actions';
import {
	listarResponsaveisClean,
	listarResponsaveisRequest,
} from 'store/modules/api/educacaoUpdate/responsavel/consultaResponsaveis/actions';
import {
	ConsultarResponsaveisRequest,
	Responsavel,
} from 'store/modules/api/educacaoUpdate/responsavel/consultaResponsaveis/types';
import {
	estatisticaIncluirClear,
	estatisticaIncluirRequest,
} from 'store/modules/api/sguService/estatisticasIncluir/actions';
import {
	eventosPPTContabilizacaoClear,
	eventosPPTContabilizacaoRequest,
} from 'store/modules/api/utils/eventosPPT/contabilizacao/actions';

import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import ufData from 'store/modules/enum';
import {
	addNotifications,
	clearNotifications,
} from 'store/modules/app/notifications/actions';
import { removerResponsavelClear } from 'store/modules/api/educacaoUpdate/responsavel/removerResponsavel/actions';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import { ROUTE_SECRETARIA_EDUCACAO } from 'routes/paths';
import { treatRequestSguEducacaoResponseEvents } from 'pages/DetranCnh/utils/functions/sguStatistics/treatServiceRequest';
import { treatRequestEventsContabilizacaoPPT } from 'utils/functions/treatEventosPPTRequest';
import {
	ID_ORGAO_EDUCACAO,
	CADASTRO_RESPONSAVEL_SOLICITACAO,
	CADASTRO_RESPONSAVEL_SOLICITACAO_DESCRICAO,
	CADASTRO_RESPONSAVEL_SOLICITACAO_ID_SERVICO,
} from 'pages/EducacaoUpdate/tiposEventos';
import { ROUTE_SECRETARIA_EDUCACAO_CADASTRO_RESPONSAVEL_FICHA } from '../../routes/paths';

import {
	initialValues,
	schema,
	formatValuesUpdate,
	formatSubmitValues,
} from './form';
import { Div } from './styled';

const PesquisarResponsaveis: React.FC = () => {
	const { token } = useContext<IAuthContext>(AuthContext);
	const [notificationAlunoNaoEncontrado, setNotificationAlunoNaoEncontrado] =
		useState<boolean>(false);
	const dispatch = useDispatch();
	const history = useHistory();

	const [listaResponsaveis, setListaResponsaveis] = useState<Responsavel[]>([]);
	const [alunoExists, setAlunoExists] = useState(false);
	const [canDelete, setCanDelete] = useState(false);
	const [cpfAluno, setCpfAluno] = useState('');
	const [requestData, setRequestData] = useState(initialValues);

	const { listarResponsaveis } = useSelector(
		(state: ApplicationState) => state.api.educacaoUpdate.responsavel,
	);

	const { cadastrarResponsavel } = useSelector(
		(state: ApplicationState) => state.api.educacaoUpdate.responsavel,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { eventosPPTContabilizacao } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const { data: dataSalvarAtendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento.salvarAtendimento,
	);

	const { estatisticasIncluir } = useSelector(
		(state: ApplicationState) => state.api.sguService,
	);

	useEffect(() => {
		if (
			listarResponsaveis.status === 200 ||
			listarResponsaveis.status === 201 ||
			listarResponsaveis.status === 204
		) {
			const responsaveis = listarResponsaveis.data?.responsaveis;
			if (responsaveis) {
				if (responsaveis.length > 1) {
					setCanDelete(true);
				}
				setListaResponsaveis(responsaveis);
				setAlunoExists(true);
			} else {
				setAlunoExists(false);
				setListaResponsaveis([]);
				setNotificationAlunoNaoEncontrado(true);
			}
			if (listarResponsaveis.data?.mensagemErro) {
				addNotifications({
					errors: [listarResponsaveis.data?.mensagemErro],
				});
			}
		} else if (listarResponsaveis.status === 400) {
			const message: any = listarResponsaveis.data?.messages;
			if (
				message[0] === 'Não encontrados responsáveis para os dados informados.'
			) {
				setAlunoExists(true);
			}
		}
		if (listarResponsaveis.status === 0) setListaResponsaveis([]);
	}, [dispatch, listarResponsaveis]);

	useEffect(() => {
		if (listarResponsaveis.consultaData) {
			const { consultaData } = listarResponsaveis;
			setRequestData({ ...initialValues, ...consultaData });
		} else {
			setRequestData({
				...initialValues,
			});
		}
	}, [listarResponsaveis]);

	const handleEditar = useCallback(
		(values: Responsavel) => {
			dispatch(eventosPPTContabilizacaoClear());
			dispatch(estatisticaIncluirClear());
			dispatch(preCadastroResponsavelRequest(formatValuesUpdate(values)));
			history.push({
				pathname: ROUTE_SECRETARIA_EDUCACAO_CADASTRO_RESPONSAVEL_FICHA,
				state: { isEditar: true, canDelete },
			});
		},
		[dispatch, history, canDelete],
	);

	const handleLimpar = useCallback(
		(formik?: FormikProps<ConsultarResponsaveisRequest>) => {
			if (formik) {
				formik.handleReset();
			}
			dispatch(listarResponsaveisClean());
			setAlunoExists(false);
			setListaResponsaveis([]);
			dispatch(eventosPPTContabilizacaoClear());
			dispatch(estatisticaIncluirClear());
			dispatch(removerResponsavelClear());
			dispatch(cadastrarResponsavelClear());
			dispatch(clearNotifications());
		},
		[dispatch],
	);

	const handleSubmit = useCallback(
		(values: ConsultarResponsaveisRequest) => {
			const requestValues = formatSubmitValues(values);
			// handleLimpar();
			setCpfAluno(requestValues.numeroCpf || '');
			dispatch(listarResponsaveisRequest(token, requestValues));
		},
		[dispatch, token],
	);

	const TooltipContent = (
		<>
			<p>Número, dígito e UF do RA.</p>
		</>
	);

	useEffect(() => {
		if (
			listarResponsaveis.status === 200 ||
			listarResponsaveis.status === 201 ||
			listarResponsaveis.status === 204
		) {
			if (eventosPPTContabilizacao.status === 0) {
				const treatedEventsPPTRequest = treatRequestEventsContabilizacaoPPT(
					atendimento,
					loginUnico,
					cpfAluno,
					'EDUCAÇÃO',
					ID_ORGAO_EDUCACAO,
					CADASTRO_RESPONSAVEL_SOLICITACAO_DESCRICAO,
					Number(CADASTRO_RESPONSAVEL_SOLICITACAO_ID_SERVICO),
					CADASTRO_RESPONSAVEL_SOLICITACAO,
				);
				dispatch(
					eventosPPTContabilizacaoRequest(token, treatedEventsPPTRequest),
				);
			}
		}
	}, [
		listarResponsaveis,
		atendimento,
		loginUnico,
		dispatch,
		token,
		eventosPPTContabilizacao,
		cpfAluno,
	]);

	useEffect(() => {
		if (
			eventosPPTContabilizacao.status !== 0 &&
			eventosPPTContabilizacao.status !== 100
		) {
			if (estatisticasIncluir.status === 0) {
				const payload = treatRequestSguEducacaoResponseEvents(
					loginUnico,
					atendimento,
					dataSalvarAtendimento?.cpf || '',
					eventosPPTContabilizacao.status === 200,
					CADASTRO_RESPONSAVEL_SOLICITACAO,
					Number(CADASTRO_RESPONSAVEL_SOLICITACAO_ID_SERVICO),
					CADASTRO_RESPONSAVEL_SOLICITACAO_DESCRICAO,
				);
				dispatch(estatisticaIncluirRequest(payload));
			}
		}
	}, [
		eventosPPTContabilizacao.status,
		estatisticasIncluir.status,
		dataSalvarAtendimento,
		dispatch,
		atendimento,
		loginUnico,
	]);

	useEffect(() => {
		if (cadastrarResponsavel.form) {
			if (cadastrarResponsavel.form.aluno?.numeroRA !== '') {
				handleLimpar();
			} else if (listarResponsaveis.consultaData) {
				setRequestData({
					numeroCpf: '',
					numeroRg: '',
					numeroRA: listarResponsaveis.consultaData.numeroRA,
					digitoRA: listarResponsaveis.consultaData.digitoRA,
					siglaUFRa: listarResponsaveis.consultaData.siglaUFRa,
					siglaUfRg: '',
				});
			}
		}
	}, [
		cadastrarResponsavel.form,
		dispatch,
		handleLimpar,
		listarResponsaveis.consultaData,
	]);

	const headers = [
		{
			title: 'Nome',
			dataIndex: 'nome',
			align: 'center',
			render: (values: any, responsavel: Responsavel) => {
				if (responsavel.nome) {
					return <b>{responsavel.nome.toUpperCase()}</b>;
				}
				return '';
			},
		},
		{
			title: 'CPF',
			dataIndex: 'cpf',
			align: 'center',
			render: (values: any, responsavel: Responsavel) => {
				return <b>{responsavel.cpf}</b>;
			},
		},
		{
			title: 'Parentesco',
			dataIndex: 'parentescoResponsavel',
			align: 'center',
			render: (values: any, responsavel: Responsavel) => {
				if (responsavel.responsabilidade) {
					return <b>{responsavel.responsabilidade.toUpperCase()}</b>;
				}
				return '';
			},
		},
		{
			title: 'Ação',
			dataIndex: 'acao',
			align: 'center',
			render: (values: any, responsavel: Responsavel) => {
				if (responsavel.nome) {
					return (
						<Button
							shape="circle"
							icon={<SearchOutlined />}
							size="large"
							onClick={() => handleEditar(responsavel)}
						/>
					);
				}
				return '';
			},
		},
	];

	return (
		<>
			<Section title="Pesquisar Aluno" size="sm">
				{notificationAlunoNaoEncontrado && (
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Alert
								type="warning"
								message="Aluno não encontrado!"
								onClose={() => setNotificationAlunoNaoEncontrado(false)}
							/>
						</Col>
					</Row>
				)}
				<Formik
					enableReinitialize
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={requestData}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={handleSubmit}
				>
					{(formik: FormikProps<any>) => {
						const { errors } = formik;
						return (
							<Form>
								<>
									<Row justify="end" gutter={[0, 16]}>
										{alunoExists && (
											<Col span={4}>
												<ButtonImage
													type="button"
													src="novo-cadastro"
													// talvez tenha que meter um default value
													onClick={() => {
														dispatch(eventosPPTContabilizacaoClear());
														dispatch(estatisticaIncluirClear());
														history.push({
															pathname:
																ROUTE_SECRETARIA_EDUCACAO_CADASTRO_RESPONSAVEL_FICHA,
															state: { isEditar: false, canDelete },
														});
													}}
												/>
											</Col>
										)}
									</Row>
									<Row justify="center">
										<Col
											span={16}
											style={{
												display: 'flex',
												alignItems: 'center',
												flex: '0 0 19%',
											}}
										>
											<label style={{ marginRight: '5px' }}>RA:</label>
											<Field
												name="numeroRA"
												as={InputMask}
												maskChar={null}
												mask="999999999999"
												error={hasError(errors, 'numeroRA')}
											/>
										</Col>
										<Col
											style={{
												display: 'flex',
												alignItems: 'center',
												maxWidth: '7%',
											}}
											span={4}
										>
											<label style={{ marginRight: '5px', marginLeft: '5px' }}>
												-
											</label>
											<Field
												name="digitoRA"
												as={InputMask}
												mask="*"
												error={hasError(errors, 'digitoRA')}
											/>
											<label style={{ marginRight: '5px', marginLeft: '5px' }}>
												/
											</label>
										</Col>
										<Col span={3}>
											<Field
												as={Select}
												name="siglaUFRa"
												options={ufData.uf}
												onChange={(v: string) => {
													formik.setFieldValue('siglaUFRa', v);
												}}
												error={hasError(errors, 'siglaUFRa')}
											/>
										</Col>
										<Col
											span={1}
											style={{ maxWidth: '3%', marginLeft: '10px' }}
										>
											<TooltipInfo content={TooltipContent} />
										</Col>
									</Row>

									<Row
										style={{ marginTop: '16px', paddingRight: '2%' }}
										justify="center"
									>
										<Col span={3}>
											<ButtonImage
												type="button"
												src="limpar"
												onClick={() => {
													handleLimpar(formik);
												}}
											/>
										</Col>
										<Col span={1} />
										<Col span={3}>
											<ButtonImage type="submit" src="pesquisar" />
										</Col>
									</Row>
								</>
							</Form>
						);
					}}
				</Formik>
			</Section>
			<Div>
				<>
					<span>Resultados Encontrados:</span>
					<SimpleTable
						size="sm"
						headers={headers}
						body={listaResponsaveis}
						pageSize={12}
						hover={false}
						messageNoResults="Nenhum registro encontrado"
						striped
					/>
				</>
			</Div>
			<ButtonVoltar route={ROUTE_SECRETARIA_EDUCACAO} />
		</>
	);
};

export default PesquisarResponsaveis;
