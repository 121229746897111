export enum Types {
	CONSULTAR_ESTATISTICA_REQUEST = '@relatorios/CONSULTAR_ESTATISTICA_REQUEST',
	CONSULTAR_ESTATISTICA_FAILURE = '@relatorios/CONSULTAR_ESTATISTICA_FAILURE',
	CONSULTAR_ESTATISTICA_SUCCESS = '@relatorios/CONSULTAR_ESTATISTICA_SUCCESS',
	CONSULTAR_ESTATISTICA_CLEAR = '@relatorios/CONSULTAR_ESTATISTICA_CLEAR',
}

export interface IConsultarEstatisticaState {
	status: number;
	data: IConsultarEstatistica[] | null;
}

export interface IConsultarEstatistica {
	id: number;
	descricaoSistemaOrigem: string;
	numeroProcesso: string;
	tipoDocumento: string;
	numeroDocumento: string;
	descricaoLocalidade: string;
	usuario: string;
	descricaoServico: string;
	flagConfronto: string;
	descricaoDedosEnviados: string;
	motivo: string;
	numeroVia: number;
	observacao: string;
	dataCadastro: string;
	totalizador: string;
}

export interface IConsultarEstatisticaRequest {
	registroInicial?: string;
	limite?: string;
	page?: number;
	size?: number;
	dataInicial: string;
	dataFinal: string;
	idOrgao?: number | string;
	idLocalidade?: string | null;
	documento?: string | null;
}
