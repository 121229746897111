import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, ExcluirTiposRequest } from './types';

export function excluirTiposRequest(
	token: string,
	data: ExcluirTiposRequest,
): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_TIPOS,
		token,
		payload: data,
	};
}
export function excluirTiposSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_TIPOS_SUCCESS,
		payload,
	};
}
export function excluirTiposFailure(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_TIPOS_FAILURE,
		payload: null,
	};
}
export function excluirTiposClear(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_TIPOS_CLEAR,
		payload: null,
	};
}
