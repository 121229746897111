import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, ExcluirUsuarioRequest } from './types';

export function excluirUsuarioRequest(
	token: string,
	data: ExcluirUsuarioRequest,
): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_USUARIO,
		token,
		payload: data,
	};
}
export function excluirUsuarioSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_USUARIO_SUCCESS,
		payload,
	};
}
export function excluirUsuarioFailure(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_USUARIO_FAILURE,
		payload: null,
	};
}
export function excluirUsuarioClear(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_USUARIO_CLEAR,
		payload: null,
	};
}
