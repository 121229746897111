export enum Types {
	CONSULTAR_NATURALIDADE_REQUEST = '@SGU/CONSULTAR_NATURALIDADE_REQUEST',
	CONSULTAR_NATURALIDADE_SUCCESS = '@SGU/CONSULTAR_NATURALIDADE_SUCCESS',
	CONSULTAR_NATURALIDADE_FAILURE = '@SGU/CONSULTAR_NATURALIDADE_FAILURE',
	CONSULTAR_NATURALIDADE_CLEAR = '@SGU/CONSULTAR_NATURALIDADE_CLEAR',
}

export interface ConsultarNaturalidade {
	status: number;
	data: Naturalidade[] | null;
}

export interface Naturalidade {
	id: number;
	digito: number;
	descricao: string;
	sigla: string;
	ibge: {
		idNaturalidade: number;
		idIbge: number;
		uf: string;
		descricao: string;
	};
}

export interface ConsultarNaturalidadeRequest {
	id: number;
}
