import { takeLatest, call, put, all, select, Effect } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, putApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { Types, AtualizarUsuarioRequest } from './types';

// ACTIONS
import { atualizarUsuarioSuccess, atualizarUsuarioFailure } from './actions';
import { ApplicationState } from '../../../../../index';

function* atualizarUsuario(
	request: ReducerActionRotaSP,
): Generator<Effect, void, any> {
	const {
		token,
		payload,
	}: { token?: string; payload: AtualizarUsuarioRequest } = request;

	const idUsuarioLogado = yield select(
		(state: ApplicationState) => state.api.sgu.loginUnico.user.idUsuario,
	);

	console.log('idUsuarioLogado>>', idUsuarioLogado);

	const body = { ...payload };

	// const id_usuario = body.id;

	delete body.id;

	const response: ApiResponse = yield call(
		putApi,
		token || '',
		PATH_SGU_API,
		`usuarios/${payload.id}`,
		body,
		{
			idUsuarioAutenticado: idUsuarioLogado,
		},
	);

	if (response.status === 200) {
		yield put(atualizarUsuarioSuccess(response));
	} else {
		yield put(atualizarUsuarioFailure());
	}
}

export default all([
	takeLatest(Types.ATUALIZAR_USUARIO_REQUEST, atualizarUsuario),
]);
