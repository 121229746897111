import { ReducerActionRotaSP } from 'store/modules/types';

import { Types } from './types';

export function consultarTiposRequest(token: string): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_TIPOS,
		token,
		payload: null,
	};
}
export function consultarTiposSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_TIPOS_SUCCESS,
		payload,
	};
}
export function consultarTiposFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_TIPOS_FAILURE,
		payload: null,
	};
}
export function consultarTiposClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_TIPOS_CLEAR,
		payload: null,
	};
}
